import React, { useEffect } from 'react'
// import BotPrice from '../../elements/botPrice/botPrice'
import BotHeader from '../../elements/botHeader/botHeader'
import BotBenefice from '../../elements/botBenefice/botBenefice'
import ManVSbot from '../../elements/manVSbot/manVSbot'
import BotSoundTest from '../../elements/botSoundTest/botSoundTest'
import BotMarketing from '../../elements/botMarketing/botMarketing'
import BotTarifs from '../../elements/botTarifs/botTarifs'
import BotUnderKey from '../../elements/botUnderKey/botUnderKey'
import BotKace from '../../elements/botKace/botKace'
import HomeForm from '../../elements/homeForm/homeForm'
import PayMetods from '../../elements/payMetods/payMetods'
import BotEducation from '../../elements/botEducation/botEducation'
import Support from '../../elements/support/support'
import texts from '../../seo/text'
import './bot.css'



const Bot = () => {
  useEffect(() => {
    let link = window.location.href.split('/')
    link = link[link.length - 1]
    if (link === 'bot') {
      window.scrollTo(0, 0)
    }

  }, [])
  return (
    <>
      <BotHeader />
      <BotBenefice />
      <ManVSbot />
      <BotSoundTest />
      <BotMarketing />
      <PayMetods />
      <BotUnderKey />
      <BotKace />
      <BotTarifs />
      <BotEducation />
      <HomeForm platform='bot' />
      {/* <BotPrice /> */}
      <>
        <div className='homeFAQ' dangerouslySetInnerHTML={{ __html: texts.bot[window.location.hostname] }}></div>        
      </>
    </>
  )
}

export default Bot