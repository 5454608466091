import React, { useState } from 'react'
import metas from '../../seo/meta'
import { Helmet } from 'react-helmet'
import headers from '../../seo/headers'

const BotHeader = () => {
  return (
    <>
      <Helmet>
        <title>
          {metas.bot[window.location.hostname].title}
        </title>
        <meta
          name="description"
          content={metas.bot[window.location.hostname].description}
        />
        <meta
          name="keywords"
          content={metas.bot[window.location.hostname].keywords}
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className='botHeder homeHeader'>
        <div className="botHederFon">
          <img src="/static/img/bot/back1.5.png" alt="" />
        </div>
        <div className="homeHeaderText">
          <h1 className='hiddetH1Seo'>BAKSBO-BOT - голосовой бот для звонков {headers[window.location.hostname]}</h1>
          <h4>
            <i>Голосовой бот</i> <span>на искуственом интелекте{headers[window.location.hostname]} под любые задачи!</span>
            <h3>Разработка под ключ / Обучение по разработке</h3>
          </h4>
          <p>Начните <i>зарабатывать больше с помощью голосового бота,</i> который приведет вам больше клиентов в бизнес, а для фрилансеров возможность разрабатывать бота для бизнеса и зарабатывать на этом{headers[window.location.hostname]}!</p>
          <p>Почему нужно выбирать именно нас?</p>
          <ul>
            <li>Опытная команда разработчиков и маркетологов</li>
            <li>Индивидуальное обучение по разработке</li>
            <li>Озвучка профессиональными дикторами</li>
            <li>Посекундная тарификация = 3,8 руб минута звонка</li>
            <li>Собственная телефония без доп. оплат</li>
            <li>Управление через личный кабинет с любого устройства</li>
          </ul>
          <div className='homeHeaderButtonDiv'>
            <a href="#bot_tarifs">
              <button className='getPresent'>Голосовой бот под ключ</button>
            </a>
            <a href="#bot_education">
              <button className='getPresent'>Обучение по разработке бота</button>
            </a>
          </div>
        </div>
        <div className="homeHeaderImage">
          <img src="/static/img/bot/bot_header_image.png" alt="" />
        </div>
      </div>
    </>
  )
}

export default BotHeader