import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react'
import { UserContext } from '../../../App';
import './profile.css'


const Profile = () => {

  const user = useContext(UserContext)
  const [name, setName] = useState(user['last_name'])
  const [email, setEmail] = useState(user['email'])
  const [number, setNumber] = useState(user['number'])
  const [company, setCompany] = useState(user['company'])
  const [post, setPost] = useState(user['post'])
  const [city, setCity] = useState(user['city'])

  function change() {
    axios.get('/api/change-profile-info/', {
      params: {
          name: name,
          email: email,
          number: number,
          company: company,
          post: post,
          city: city,        
      }
    })
  }

  useEffect(() => {
    axios.get('/api/is_auth')
      .then(response => {
        if (response.data['email']) {
          setName(response.data['last_name'])
          setEmail(response.data['email'])
          setNumber(response.data['number'])
          setCompany(response.data['company'])
          setPost(response.data['post'])
          setCity(response.data['city'])
        }
      })
  }, [])
  
  return (
    <div className='profileDiv'>
      <div className="rofileInfo">
        <fieldset className="profileSection">
          <legend className="profileSectionHeader">Имя</legend>
          <input type="text" value={name} onKeyDown={change} onBlur={change} onChange={event => setName(event.target.value)} />
        </fieldset>
        <fieldset className="profileSection">
          <legend className="profileSectionHeader">Номер</legend>
          <input type="number" value={number} onKeyDown={change} onBlur={change} onChange={event => setNumber(event.target.value)} />
        </fieldset>
        <fieldset className="profileSection">
          <legend className="profileSectionHeader">Email</legend>
          <input type="text" disabled value={email}/>
        </fieldset>


        <fieldset className="profileSection">
          <legend className="profileSectionHeader">Компания</legend>
          <input type="text" placeholder='Не указан' value={company} onKeyDown={change} onBlur={change} onChange={event => setCompany(event.target.value)} />
        </fieldset>
        <fieldset className="profileSection">
          <legend className="profileSectionHeader">Должность</legend>
          <input type="text" placeholder='Не указан' value={post} onKeyDown={change} onBlur={change} onChange={event => setPost(event.target.value)} />
        </fieldset>
        <fieldset className="profileSection">
          <legend className="profileSectionHeader">Город</legend>
          <input type="text" placeholder='Не указан' value={city} onKeyDown={change} onBlur={change} onChange={event => setCity(event.target.value)} />
        </fieldset>
      </div>
    </div>
  )
}

export default Profile