import React from 'react'
import { Link } from 'react-router-dom'
import "./download.css"

const Download = () => {
  return (
    <Link className='downloadCrmButton' to={'/static/media/CRM.xlsx'} target='_blank' download>Скачать</Link>
  )
}

export default Download