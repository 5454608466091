import React, { useState } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/skyblue';
import '@splidejs/react-splide/css/core';
import '@splidejs/react-splide/css/sea-green';
import './case.css'
import headers from '../../seo/headers';

const Case = () => {
    const [review, setReview] = useState([['1', '2'], ['3', '4'], ['5', '6'], ["7", "8"], ["9", "10"]])
    return (
        <>
            <p className='concurentUseHeader'>Конкуренты уже используют и увеличивают доход</p>
            <p className='concurentUseText'>Ваши конкуренты уже активно используют данный вид
                привлечения клиентов в свой бизнес. Рассылка сообщений недорого в Instagram, WhatsApp, Telegram, Viber, ВКонтакте  <i>самые эффективные способы привлечения покупателя и аудитории {headers[window.location.hostname]}. Многие бизнес направления
                снизили стоимость заявки в несколько раз,</i> тем самым смогли реинвестировать сэкономленный бюджет
                в рекламу или перенаправить в другие структуры бизнеса для более динамического развития.</p>
            <div id="slider">
                <Splide aria-label="Review">
                    {review.map(list => {
                        return (
                            <SplideSlide>
                                <div className='sliderDiv'>
                                    {list.map(key => {
                                        return (
                                            <img key={key} src={'/static/img/case/case(' + key + ').png'} alt="" />
                                        )
                                    })
                                    }
                                </div>
                            </SplideSlide>
                        )
                    })}
                </Splide>
            </div>
        </>

    )
}

export default Case