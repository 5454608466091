import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useContext, useEffect, useState, useRef } from 'react';
import { UserContext } from '../../../App'

import './manu.css'


function Manu(args) {
  const user = useContext(UserContext)
  const [header, setHeader] = useState({ img: 'ci_mail', header: '' })
  const [mailingLink, setMailingLink] = useState('mailing')
  const manu = useRef(null)
  const manuButton = useRef(null)
  const manuHeader = useRef(null)
  const dropdown = useRef(null)
  const manuFon = useRef(null)
  const downloadRef = useRef(null)
  const location = useLocation()
  const navigate = useNavigate()
  const mailLink = useRef(null)

  const manuHiddenButton = useRef(null)
  const selfmailingLink = useRef(null)
  const taskLink = useRef(null)
  const trainingLink = useRef(null)
  const botLink = useRef(null)
  const botDevLink = useRef(null)
  const botPayLink = useRef(null)
  const botTrainingLink = useRef(null)
  const referralLink = useRef(null)
  const profileLink = useRef(null)
  const supportLink = useRef(null)
  const logoRef = useRef(null)
  const cabinetHeader = useRef(null)
  const botLinkDiv = useRef(null)

  function hiddenManu() {

    manuHiddenButton.current.classList.toggle('hideManuDivHidden')
    if (manuHiddenButton.current.classList.value == 'hideManuDiv') {
      setTimeout(() => {
        selfmailingLink.current.style.opacity = '1'
        setTimeout(() => {
          logoRef.current.innerHTML = 'BAKSBO.RU'
        }, 220);
        setTimeout(() => {
          logoRef.current.innerHTML = 'BAKSBO.R'
        }, 200);
        setTimeout(() => {
          logoRef.current.innerHTML = 'BAKSBO.'
        }, 180);
        setTimeout(() => {
          logoRef.current.innerHTML = 'BAKSBO'
        }, 160);
        setTimeout(() => {
          logoRef.current.innerHTML = 'BAKSB'
        }, 140);
        setTimeout(() => {
          logoRef.current.innerHTML = 'BAKS'
        }, 120);
        setTimeout(() => {
          logoRef.current.innerHTML = 'BAK'
        }, 100);
        setTimeout(() => {
          logoRef.current.innerHTML = 'BA'
        }, 80);
        setTimeout(() => {
          logoRef.current.innerHTML = 'B'
        }, 60);
      }, 930);
      setTimeout(() => {
        taskLink.current.style.opacity = '1'
        manuHiddenButton.current.disabled = false

      }, 860);
      setTimeout(() => {
        trainingLink.current.style.opacity = '1'
      }, 690);
      setTimeout(() => {
        botLink.current.style.opacity = '1'
      }, 620);
      setTimeout(() => {
        botDevLink.current.style.opacity = '1'
      }, 550);
      setTimeout(() => {
        botPayLink.current.style.opacity = '1'
      }, 480);
      setTimeout(() => {
        botTrainingLink.current.style.opacity = '1'
      }, 410);
      setTimeout(() => {
        mailLink.current.style.opacity = '1'
      }, 340);
      setTimeout(() => {
        referralLink.current.style.opacity = '1'
      }, 270);
      setTimeout(() => {
        profileLink.current.style.opacity = '1'
        supportLink.current.style.opacity = '1'

      }, 200);
      manuHiddenButton.current.disabled = true
      manu.current.style.width = '330px'
      cabinetHeader.current.style.width = 'calc(100% - 330px)'
      manuHiddenButton.current.style.left = 'calc(330px - 25px)'
      localStorage.setItem('manu', 'false')
      args.cabinetSection.current.classList.remove('cabinetSectionHidden')
      manu.current.classList.remove('cabinetManuHidden')
    }
    else {
      // manuHiddenButton.current.classList.remove('hideManuDivHidden')
      selfmailingLink.current.style.opacity = '0'
      setTimeout(() => {
        setTimeout(() => {
          logoRef.current.innerHTML = 'BAKSBO.R'
        }, 50);
        setTimeout(() => {
          logoRef.current.innerHTML = 'BAKSBO.'
        }, 70);
        setTimeout(() => {
          logoRef.current.innerHTML = 'BAKSBO'
        }, 90);
        setTimeout(() => {
          logoRef.current.innerHTML = 'BAKSB'
        }, 110);
        setTimeout(() => {
          logoRef.current.innerHTML = 'BAKS'
        }, 130);
        setTimeout(() => {
          logoRef.current.innerHTML = 'BAK'
        }, 150);
        setTimeout(() => {
          logoRef.current.innerHTML = 'BA'
        }, 170);
        setTimeout(() => {
          logoRef.current.innerHTML = 'B'
        }, 190);
      }, 50);
      setTimeout(() => {
        taskLink.current.style.opacity = '0'
        manuHiddenButton.current.disabled = true

      }, 100);
      setTimeout(() => {
        trainingLink.current.style.opacity = '0'
      }, 200);
      setTimeout(() => {
        botLink.current.style.opacity = '0'
      }, 300);
      setTimeout(() => {
        botDevLink.current.style.opacity = '0'
      }, 400);
      setTimeout(() => {
        botPayLink.current.style.opacity = '0'
      }, 500);
      setTimeout(() => {
        botTrainingLink.current.style.opacity = '0'
      }, 600);
      setTimeout(() => {
        mailLink.current.style.opacity = '0'
      }, 700);
      setTimeout(() => {
        referralLink.current.style.opacity = '0'
      }, 800);
      setTimeout(() => {
        profileLink.current.style.opacity = '0'
        supportLink.current.style.opacity = '0'
      }, 900);
      setTimeout(() => {
        manu.current.style.width = '60px'
        cabinetHeader.current.style.width = 'calc(100% - 60px)'
        manuHiddenButton.current.style.left = '22px'
        args.cabinetSection.current.classList.add('cabinetSectionHidden')
        manu.current.classList.add('cabinetManuHidden')
        localStorage.setItem('manu', 'true')
        manuHiddenButton.current.disabled = false

      }, 950);
    }

  }

  function openBot() {
    botLinkDiv.current.classList.add('cabinetLinkDisabled')
    axios.get('/api/login_bot_user')
      .then(response => {
        if (response.status === 200) {
          window.location.replace(response.data)
        }
        else {
          botLinkDiv.current.classList.remove('cabinetLinkDisabled')
        }
      })
  }


  useEffect(() => {
    headerCheck()
  }, [location])


  function download(params) {
    downloadRef.current.style.opacity = '0.3'
    axios.get('/api/download')
      .then(response => {
        if (response.data === 'success') {
          downloadRef.current.style.opacity = '1'
          navigate('/cabinet/download')
        }
      })
  }

  useEffect(() => {
    headerCheck()
    axios.get('/api/self-mailing/',
      {
        params: {
          platform: 'all',
        },
      })
      .then(response => {
        if (response.data[0]) {
          setMailingLink('mailing/statistic')
        }
      })
    if (localStorage.getItem('manu') === 'true') {
      hiddenManu()
    }

  }, [])


  function toggleManu() {
    manu.current.classList.toggle('cabinetManuActive')
    manuButton.current.classList.toggle('cabinetManuButtonActive')
    manuHeader.current.classList.toggle('cabinetManuHeaderActive')
    manuFon.current.classList.toggle('manuFonActive')
    args.cabinetSection.current.classList.toggle('cabinetSectionActive')
  }

  // change header when needed
  function headerCheck() {
    var link = location.pathname.split('/')[2]
    if (link === 'selfmailing') {
      setHeader({ img: 'ci_mail', header: 'Telegram soft' })
    } else if (location.pathname === '/cabinet') {
      setHeader({ img: 'ci_mail', header: 'Telegram soft' })
    } else if (link === 'mailing') {
      setHeader({ img: 'la_key', header: 'Рассылка под ключ' })
    } else if (link === 'telegram_mailing') {
      setHeader({ img: 'mailing_ico', header: 'Рассылка сообщений' })
    } else if (link === 'telegram_inviting') {
      setHeader({ img: 'invite_ico', header: 'Инвайтинг' })
    } else if (link === 'telegram_parser') {
      setHeader({ img: 'parser_ico', header: 'Парсинг' })
    } else if (link === 'statistic') {
      setHeader({ img: 'akar-icons_statistic-up', header: 'Статистика' })
    } else if (link === 'task') {
      setHeader({ img: 'akar-icons_statistic-up', header: 'Статистика' })
    } else if (link === 'training') {
      setHeader({ img: 'carbon_education', header: 'Обучение' })
    } else if (location.pathname.split('/')[2] + '/' + location.pathname.split('/')[3] === 'bot/development') {
      setHeader({ img: 'free-icon-editing', header: 'Разработка голосового бота' })
    } else if (location.pathname.split('/')[2] + '/' + location.pathname.split('/')[3] === 'bot/pay') {
      setHeader({ img: 'pup_up_bot', header: 'Пополнить баланс' })
    } else if (location.pathname.split('/')[2] + '/' + location.pathname.split('/')[3] === 'bot/learning') {
      setHeader({ img: 'carbon_education', header: 'Обучение' })
    } else if (link === 'referral') {
      setHeader({ img: 'carbon_partnership', header: 'Партнерская программа' })
    } else if (link === 'profile') {
      setHeader({ img: 'carbon_user-avatar-filled-alt', header: 'Профиль' })
    }
  }
  //close menu on routing
  useEffect(() => {
    manu.current.classList.remove('cabinetManuActive')
    manuButton.current.classList.remove('cabinetManuButtonActive')
    manuHeader.current.classList.remove('cabinetManuHeaderActive')
    manuFon.current.classList.remove('manuFonActive')
    args.cabinetSection.current.classList.remove('cabinetSectionActive')

  }, [header])


  // logout
  function logout() {
    axios.get('/api/logout')
      .then(response => {
        if (response.data[0] === 'success') {
          navigate('/login')
          window.location.reload()
        }
        localStorage.setItem('userIsAuth', 'false')
      })
  }

  return (
    <>
      <div ref={manu} className='cabinetManu'>
        <div ref={manuHeader} className='cabinetManuHeader'><a target='_blank' href={'/'}><h2 ref={logoRef}>baksbo.ru</h2></a></div>
        <div className="cabinetManuLinks">
          <Link className='cabinetLink' to='/cabinet/selfmailing'> <img src="/static/img/cabinet/ci_mail.png" alt="" /><div className='cabinetLinkFon'></div><p ref={selfmailingLink}>Telegram soft</p></Link>
          {user['tarif'] ?
            <Link className='cabinetLink' to='/cabinet/task'> <img src="/static/img/cabinet/akar-icons_statistic-up.png" alt="" /><div className='cabinetLinkFon'></div><p ref={taskLink}>Статистика</p></Link>
            :
            <Link className='cabinetLink' to='/cabinet/task'> <img src="/static/img/cabinet/akar-icons_statistic-up.png" alt="" /><div className='cabinetLinkFon'></div><p ref={taskLink}>Статистика (демо)</p></Link>
          }
          <Link className='cabinetLink' to='/cabinet/training'> <img src="/static/img/cabinet/carbon_education.png" alt="" /><div className='cabinetLinkFon'></div><p ref={trainingLink}>Обучение</p></Link>


          <div className="manuLine"></div>
          {user["bot"] ?
            <a ref={botLinkDiv} className='cabinetLink' onClick={openBot}><img src="/static/img/cabinet/bot_call.png" alt="" /><div className='cabinetLinkFon'></div><p ref={botLink}>BAKSBO-BOT | Звонки</p></a>
            :
            <a ref={botLinkDiv}  className='cabinetLink cabinetLinkDisabled'><img src="/static/img/cabinet/bot_call.png" alt="" /><div className='cabinetLinkFon'></div><p ref={botLink}>BAKSBO-BOT | Звонки</p></a>
          }
          <Link className='cabinetLink' to={'/cabinet/bot/development'}><img src="/static/img/cabinet/free-icon-editing.png" alt="" /><div className='cabinetLinkFon'></div><p ref={botDevLink}>Разработка голосового бота</p></Link>
          {user["bot"] ?
          <Link className='cabinetLink' to={'/cabinet/bot/pay'}><img src="/static/img/cabinet/pup_up_bot.png" alt="" /><div className='cabinetLinkFon'></div><p ref={botPayLink}>Пополнить баланс</p></Link>
          :
          <Link className='cabinetLink cabinetLinkDisabled' to={'/cabinet/bot/pay'}><img src="/static/img/cabinet/pup_up_bot.png" alt="" /><div className='cabinetLinkFon'></div><p ref={botPayLink}>Пополнить баланс</p></Link>
        }
          <Link className='cabinetLink' to={'/cabinet/bot/learning'}><img src="/static/img/cabinet/carbon_education.png" alt="" /><div className='cabinetLinkFon'></div><p ref={botTrainingLink}>Обучение</p></Link>
          <div className="manuLine"></div>


          <Link className='cabinetLink' to={'/cabinet/mailing'}><img src="/static/img/cabinet/la_key.png" alt="" /><div className='cabinetLinkFon'></div><p ref={mailLink}>Рассылка под ключ</p></Link>

          <a className='cabinetLink cabinetLinkDisabled' ><img src="/static/img/cabinet/carbon_partnership.png" alt="" /><div className='cabinetLinkFon'></div><p ref={referralLink}>Партнерская программа</p></a>
          <Link className='cabinetLink' to='/cabinet/profile'><img src="/static/img/cabinet/carbon_user-avatar-filled-alt.png" alt="" /><div className='cabinetLinkFon'></div><p ref={profileLink}>Профиль</p></Link>
          <Link className='cabinetLink cabinetLinkMobile' onClick={logout} to='/login'><img src="/static/img/cabinet/logout.png" alt="" /><div className='cabinetLinkFon'></div><p>Выйти</p></Link>

          <button onClick={hiddenManu} ref={manuHiddenButton} className='hideManuDiv'></button>

          <div className='manuSupport'>
            <p ref={supportLink}>Техническая поддержка</p>
            <div>
              <a href="https://t.me/baksbo_ru" target='_blank'>
                <img src="/static/img/icons/tg.png" alt="" />
              </a>
              <a href="https://wa.me/+789209660591" target='_blank'>
                <img src="/static/img/icons/waapp.png" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div onClick={toggleManu} ref={manuFon} className='manuFon'></div>
      <div ref={cabinetHeader} className="cabinetHeader">
        <h2>baksbo.ru</h2>
        <div className='cabinetHeaderInfo'>
          <img src={"/static/img/cabinet/" + header['img'] + ".png"} alt="" />
          <p>{header['header']}</p>
        </div>
        <div className='cabinetHeaderUserInfo'>
          <div onClick={toggleManu} ref={manuButton} className="cabinetManuButton"></div>
          <p>{user['last_name']} <b> | </b><a onClick={logout}>ВЫЙТИ </a><img onClick={logout} src="/static/img/cabinet/logout.svg" alt="" /></p>
          <img src="/static/img/cabinet/profil_photo.png" alt="" />
        </div>

      </div>
    </>
  );
}

export default Manu;
