import "./review.css"
import { Splide, SplideSlide } from '@splidejs/react-splide';
import ChatFon from '../../elements/chatFon/ChatFon'
import HomeForm from '../../elements/homeForm/homeForm'
// import '@splidejs/react-splide/css';
// import '@splidejs/react-splide/css/skyblue';
// import '@splidejs/react-splide/css/core';
import '@splidejs/react-splide/css/sea-green';
import { useEffect, useState } from "react";
import axios from "axios";
import MetaTags from 'react-meta-tags';

export default function Review() {
    const [reviewMobile, setReviewMobile] = useState(["1.png", "2.png", "3.png", "4.png", "4_dvM6N2e.png", "5.png", "6.png", "7.png", "8.png", "9.png", "10.png", "11.png", "12.png", "13.png", "14.png", "15.png", "16_qrd9Rkq.png", "17_cQbCMmt.png", "18.png"])
    const [review, setReview] = useState([["1.png", "2.png", "3.png"], ["4.png", "4_dvM6N2e.png", "5.png"], ["6.png", "7.png", "8.png"], ["9.png", "10.png", "11.png"], ["12.png", "13.png", "14.png"], ["15.png", "16_qrd9Rkq.png", "17_cQbCMmt.png"], ["18.png"]])

    useEffect(() => {
        axios.get('/api/review')
            .then(response => {
                if (response.status == 200) {
                    setReviewMobile(response.data.review.mobile)
                    setReview(response.data.review.pc)
                }
            })
    }, [])

    return (
        <>
            {/* <ChatFon />
             */}

            <MetaTags>
                <title>Отзывы о сервисе массовой рассылке сообщений Baksbo.ru</title>
                <meta name="description" content="В этом разделе нашего сайта, вы можете почитать отзывы клиентов, которые воспользовались нашим сервисом массовой рассылки сообщений по всему миру Baksbo.ru."></meta>
                <meta name="keywords" content="рассылка сообщений отзывы, сервис массовой рассылки сообщений отзывы, baksbo ru отзывы, baksbo отзывы, baksbo․ru отзывы, массовая рассылка сообщений отзывы."></meta>
                <meta property="og:image:secure" content="/static/img/icons/review.png"></meta>
            </MetaTags>
            <div className="chatFon chatfonReview">
            </div>
            <div className='homeHeader homeHeaderReview'>
                <div className="homeHeaderText">
                    <h1>ОТЗЫВЫ О РАССЫЛКЕ</h1>
                    <h2>СООБЩЕНИЙ ОТ BAKSBO.RU</h2>
                    <p>Во время выполнения заказа мы предоставляем фотоотчёт и дополнительные материалы по исполнению работы и на протяжении всей рассылки поддерживаем связь с клиентом. Так вы можете полностью довериться нам, потому что мы ничего не скрываем!</p>                    

                </div>
                <div className="homeHeaderImage">
                </div>
            </div>
            <p className='headerSocialText'>Мы тут есть</p>
            <div id='headerSocialLinks' className="headerSocialLinks">
                <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/baksbo.ru/">
                    <img src="static/img/icons/insta.png" alt="" />
                </a>

                <a target="_blank" rel="noopener noreferrer" href="https://t.me/baksbo_ru">
                    <img src="static/img/icons/tg.png" alt="" />
                </a>

                <a target="_blank" rel="noopener noreferrer" href="https://wa.me/+789209660591">
                    <img src="static/img/icons/waapp.png" alt="" />
                </a>

                <a target="_blank" rel="noopener noreferrer" href="https://vk.com/baksbo_ru">
                    <img src="static/img/icons/vk.png" alt="" />
                </a>
            </div>
            <div id="mobileSlider">
                {reviewMobile.map(image => {
                    return (
                        <img src={'static/review/' + image} alt="" />
                    )
                })}
            </div>
            <div className="reviewBottom">
                <HomeForm />
            </div>
        </>
    )
}