import React, { useEffect, useRef } from 'react'
import './botUnderKey.css'
import headers from '../../seo/headers'


const BotUnderKey = () => {
    const botUnderKey = useRef(null)
    useEffect(() => {
        let link = window.location.href.split('/')
        link = link[link.length - 1]
        if (link === 'bot#bot_under_key') {
            window.scrollTo({ behavior: 'smooth', top: botUnderKey.current.offsetTop })
        }
    }, [])
    return (
        <>
            <div className="waveBotHeaderText">
                <h5 ref={botUnderKey} id='bot_under_key' className="botHeaderText">
                    Голосовой бот под ключ
                </h5>
            </div>
            <br />
            <div className='botUnderKeydiv'>
                <div className="botUnderKeyInfo">
                    <h5 className='botHeaderText'>Опытная команда разработчиков и маркетологов</h5>
                    <p>Вам не нужно заниматься самостоятельной настройкой всех процессов, наша команда сделает все за вас, вам остаётся только получать результат от работы голосового бота {headers[window.location.hostname]} .</p>
                    <ol>
                        <li>Проанализируем вашу нишу и максимально вовлечемся в процесс создания бота.</li>
                        <li>Соберем базу для обзвона под любую сферу бизнеса (если нет своей базы).</li>
                        <li>Структуризируем голосового бота под вашу задачу для максимальной конверсии.</li>
                        <li>Проработаем скрипт для голосового бота, с различными сценариями исхода диалога.</li>
                        <li>Профессиональная дикторская озвучка скрипта, клиент даже не поймет что это голосовой бот.</li>
                        <li>Индивидуальная дополнительная настройка голосового бота для увеличение потока заявок.</li>
                        <li>Настройка интеграции с различными площадками для удобства работы и анализа.</li>
                        <li>Запустим сами бота когда вам необходимо и вышлем все отчеты по работе.</li>
                    </ol>
                </div>
                <div className="botUnderKeyImage">
                    <img src="/static/img/bot/bot2.png" alt="" />
                </div>
            </div>
        </>
    )
}

export default BotUnderKey