import React, { useContext } from 'react'
import './training.css'
import { UserContext } from '../../../App'

const Training = () => {
    const user = useContext(UserContext)

    return (
        <div className="trainingDiv">
            {user['tarif'] ?
                <></> :
                <div className='trainingDivHidden'>
                    <p>Обучение будет доступно после активации лицензии Telegram</p>
                </div>
            }
            <div className="selfMailingItem telegramItem">
                <div className='selfMailingInfo'>
                    <div className="selfMailingInfoText">
                        <p className='selfMailingInfoHeader'>telegram</p>
                    </div>
                    <div className='selfMailingInfoButtons'>
                        <div className='selfMailingInfoTraining'>
                            <p>
                                Пройдите бесплатное обучение прямо сейчас и узнайте больше о том, как правильно настраивать рассылку сообщений, инвайтинг или прасер.
                                <br />
                                Если у вас остались вопросы, то вы всегда можете задать их нашему менеджеру.
                                <br />
                                <br />
                                <i>ВНИМАНИЕ!!!</i>  Лицензия работает только на аккаунтах SESSIONS + JSON

                            </p>
                        </div>
                        <div className='selfMailingInfoButtonDiv'>
                            <section className='selfMailingInfoButtonSection'>
                                <a target={'_blank'} href={'https://youtu.be/-kw66JsuGHE'}>
                                    <section className='selfMailingInfoTraningButton'>
                                        <div>
                                            <img src="/static/img/cabinet/mailing_ico.png" alt="" />
                                            <p>Рассылка <br /> сообщений</p>
                                        </div>
                                        <a target={'_blank'} href={'https://youtu.be/-kw66JsuGHE'}>Обучение</a>
                                    </section>
                                </a>
                                <a target={'_blank'} href={'https://youtu.be/XyCu2ApPAgo'}>
                                    <section className='selfMailingInfoTraningButton'>
                                        <div>
                                            <img src="/static/img/cabinet/invite_ico.png" alt="" />
                                            <p>Инвайтинг</p>
                                        </div>
                                        <a target={'_blank'} href={'https://youtu.be/XyCu2ApPAgo'}>Обучение</a>
                                    </section>
                                </a>
                                <a target={'_blank'} href={'https://youtu.be/r7yoeR0uxH4'}>
                                    <section className='selfMailingInfoTraningButton'>
                                        <div>
                                            <img src="/static/img/cabinet/parser_ico.png" alt="" />
                                            <p>Парсер</p>
                                        </div>
                                        <a target={'_blank'} href={'https://youtu.be/r7yoeR0uxH4'}>Обучение</a>
                                    </section>
                                </a>
                                <a target={'_blank'} href={'https://youtu.be/Cv92xssXUe8'}>
                                    <section className='selfMailingInfoTraningButton'>
                                        <div>
                                            <img src="/static/img/cabinet/user-solid-ico.png" alt="" />
                                            <p>Аккаунты</p>
                                        </div>
                                        <a target={'_blank'} href={'https://youtu.be/Cv92xssXUe8'}>Обучение</a>
                                    </section>
                                </a>
                                <a target={'_blank'} href={'https://youtu.be/icOkZgl8Yb0'}>
                                    <section className='selfMailingInfoTraningButton'>
                                        <div>
                                            <img src="/static/img/cabinet/proxy-ico.png" alt="" />
                                            <p>Прокси</p>
                                        </div>
                                        <a target={'_blank'} href={'https://youtu.be/icOkZgl8Yb0'}>Обучение</a>
                                    </section>
                                </a>
                                <section className='selfMailingInfoTraningButton selfMailingInfoTraningButtonBuyAccount'>
                                    <div>
                                        <p>Где можно<br />покупать<br />аккаунты</p>
                                    </div>
                                    <a href='https://t.me/kvb_baksbo' target="_blank">t.me/baksbo_club_off</a>
                                </section>
                            </section>
                            {/* <section className='selfMailingInfoBuySession'>
                                <p>Где можно покупать аккаунты</p>
                                
                            </section> */}

                        </div>
                    </div>
                </div>
                <div className='selfMailingItemCover'></div>
            </div>
            <div className='comingSoonDiv'>
                <div className="comingSoon">
                    <img src="/static/img/icons/waapp.png" alt="" />
                    <p>WhatsApp</p>
                    <span>Скоро...</span>
                </div>
            </div>
        </div>
    )
}

export default Training