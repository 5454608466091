import React, { useState, useRef, useEffect } from 'react';
import InputMask from 'react-input-mask'
import { NumericFormat } from 'react-number-format'
import axios from 'axios';
import DatePicker from 'react-date-picker'
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import './clientCreator.css';

const ClientCreator = (args) => {
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [contact, setContact] = useState('')
    const [relation, setRelation] = useState('')
    const [newsletter, setNewsletter] = useState('')
    const [volume, setVolume] = useState('')
    const [price, setPrice] = useState('')
    const [comment, setComment] = useState('')
    const [vip, setVip] = useState(false)
    const [partner, setPartner] = useState(false)
    const [manager, setManager] = useState(null)
    const [orderData, setOrderData] = useState(null)

    const [error, setError] = useState(false)
    const button = useRef(null)



    function getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    useEffect(() => {
        axios.get(`/api/user_crm/${phone.replace(/[^\d]/g, '')}`)
            .then(response => {
                if (!name) {
                    setName(response.data.name)
                }
                if (!contact) {
                    setContact(response.data.connect)
                }
            })

    }, [phone])

    const saveClient = () => {
        const csrftoken = getCookie('csrftoken')
        button.current.disabled = true
        axios.post('/api/clients', {
            'name': name,
            'phone': phone.replace(/[^\d]/g, ''),
            'contact': contact,
            'relation': relation,
            'newsletter': newsletter,
            'volume': volume.replace(/[^\d]/g, ''),
            'price': price.replace(/[^\d]/g, ''),
            'comment': comment,
            'vip': vip,
            'manager': manager,
            'partner': partner,
            'date_order': orderData,
        }, {
            headers: {
                'X-CSRFToken': csrftoken,
            },
        }
        ).then(response => {
            if (response.status == 200) {
                button.current.disabled = false
                console.log('okk')
                args.setClientCreator(false)
                setName(null)
                setPhone(null)
                setContact(null)
                setRelation(null)
                setNewsletter(null)
                setVolume(null)
                setPrice(null)
                setComment(null)
                setOrderData(null)
            }
            else if (response.status == 400) {
                setError(true)
                button.current.disabled = false
            }
        })
            .catch(response => {
                setError(true)
                button.current.disabled = false
            })
    }



    return (
        <div className="crmClientCreatorDiv">
            <div onClick={() => args.setClientCreator(false)} className="crmClientCreatorFon"></div>
            <div className="crmClientCreator">
                <img src="/static/img/admin/xmark-solid.svg" alt="" onClick={() => args.setClientCreator(false)} />
                <h4>Создание клента</h4>
                <hr />
                <div className='crmClientCreatorInput'>
                    <InputMask className='crmClientCreatorInputItem' maskChar=" " mask="9 (999) 999-99-99" placeholder='Телефон' value={phone} onChange={(e) => setPhone(e.target.value)} />
                    <input className='crmClientCreatorInputItem' placeholder='Имя' type="text" value={name} onChange={(e) => setName(e.target.value)} />
                    <input className='crmClientCreatorInputItem' placeholder='Telegram' type="text" value={contact} onChange={(e) => setContact(e.target.value?'@'+e.target.value.replace('@',''):e.target.value)} />

                    <div className='crmClientCreatorRelation'>
                        <span>Связ</span>
                        <select id="relation" name="relation" value={relation} onChange={(e) => setRelation(e.target.value)}>
                            <option value="">Выбрать</option>
                            <option value="telegram">Telegram</option>
                            <option value="whatsapp">Whatsapp</option>
                        </select>
                    </div>

                    <div className='crmClientCreatorRelation'>
                        <span>Рассылка</span>
                        <select id="newsletter" name="newsletter" value={newsletter} onChange={(e) => setNewsletter(e.target.value)}>
                            <option value="">Выбрать</option>
                            <option value="instagram">Instagram</option>
                            <option value="telegram">Telegram</option>
                            <option value="whatsApp">WhatsApp</option>
                            <option value="viber">Viber</option>
                            <option value="vk">Вконтакте</option>
                            <option value="sms">СМС</option>
                        </select>
                    </div>

                    <NumericFormat className='crmClientCreatorInputItem' allowLeadingZeros thousandSeparator=" " suffix={' шт.'} placeholder='Объем' type="text" value={volume} onChange={(e) => setVolume(e.target.value)} />
                    <NumericFormat className='crmClientCreatorInputItem' allowLeadingZeros thousandSeparator=" " suffix={' руб.'} placeholder='Стоимость' type="text" value={price} onChange={(e) => setPrice(e.target.value)} />
                    <DatePicker className='crmClientCreatorInputItem' onChange={(value) => setOrderData(value)} value={orderData} />
                    <input className='crmClientCreatorInputItem' placeholder='Ответственный' type="text" value={manager} onChange={(e) => setManager(e.target.value)} />
                    <textarea className='crmClientCreatorInputTextarea' placeholder='Комментарий' type="text" value={comment} onChange={(e) => setComment(e.target.value)}></textarea>
                    <div className='clientCtreatorVipInput'>
                        <span>VIP статус</span>
                        {/* <input className='crmClientCreatorInputItem' type="checkbox"  /> */}
                        <div class="checkbox-wrapper-12">
                            <div class="cbx">
                                <input className='crmClientCreatorInputItem' checked={vip} onChange={(e) => setVip(e.target.checked)} id="cbx-12" type="checkbox" />
                                <label for="cbx-12"></label>
                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none">
                                    <path d="M2 8.36364L6.23077 12L13 2"></path>
                                </svg>
                            </div>

                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                                <defs>
                                    <filter id="goo-12">
                                        <feGaussianBlur in="SourceGraphic" stdDeviation="4" result="blur"></feGaussianBlur>
                                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7" result="goo-12"></feColorMatrix>
                                        <feBlend in="SourceGraphic" in2="goo-12"></feBlend>
                                    </filter>
                                </defs>
                            </svg>
                        </div>
                    </div>
                    <div className='clientCtreatorVipInput'>
                        <span>Партнер</span>
                        {/* <input className='crmClientCreatorInputItem' type="checkbox"  /> */}
                        <div class="checkbox-wrapper-12">
                            <div class="cbx">
                                <input className='crmClientCreatorInputItem' checked={partner} onChange={(e) => setPartner(e.target.checked)} id="cbx-12" type="checkbox" />
                                <label for="cbx-12"></label>
                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none">
                                    <path d="M2 8.36364L6.23077 12L13 2"></path>
                                </svg>
                            </div>

                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                                <defs>
                                    <filter id="goo-12">
                                        <feGaussianBlur in="SourceGraphic" stdDeviation="4" result="blur"></feGaussianBlur>
                                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7" result="goo-12"></feColorMatrix>
                                        <feBlend in="SourceGraphic" in2="goo-12"></feBlend>
                                    </filter>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </div>

                <button ref={button} onClick={saveClient} className='crmClientCreatorSave'>{error ? <>Ошибка</> : <>Сохранить</>}</button>
            </div>
        </div>
    )
}

export default ClientCreator
