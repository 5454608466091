import axios from "axios"
import { useRef, useState, useEffect, useContext } from "react"
import "./mailingForm.css"
import Aos from 'aos'
import 'aos/dist/aos.css'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { UserContext } from "../../App"

export default function MailingForm(args) {
    const user = useContext(UserContext)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [number, setNumber] = useState('')
    const policyRef = useRef(null)
    const nameRef = useRef(null)
    const buttonRef = useRef(null)
    const emailRef = useRef(null)
    const error = useRef('')
    const responce = useRef('')
    const responceFon = useRef('')


    function validateEmail(email) {
        var chrbeforAt = email.substr(0, email.indexOf('@'));
        if (!(email.trim().length > 127)) {
            if (chrbeforAt.length >= 2) {
                var re = /^(([^<>()[\]{}'^?\\.,!|//#%*-+=&;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
                return re.test(email);
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    function pressEnter(e) {
        if (e.keyCode === 13) {
            if (e.target.id === 'name') {
                if (name) {
                    error.current.innerHTML = ''
                    emailRef.current.focus()
                } else {
                    nameRef.current.focus()
                    error.current.innerHTML = 'Некорректное заполнение имени!'

                }
            }
            else if (e.target.id === 'email') {
                if (name) {
                    if (email) {
                        if (validateEmail(email)) {
                            error.current.innerHTML = ''
                            document.getElementById('number').focus()

                        } else {
                            emailRef.current.focus()
                            error.current.innerHTML = 'Некорректное заполнение E-mail!'
                        }
                    } else {
                        emailRef.current.focus()
                        error.current.innerHTML = 'E-mail не заполнен!'
                    }
                } else {
                    nameRef.current.focus()
                    error.current.innerHTML = 'Некорректное заполнение имени!'
                }
            }
            else if (e.target.id === 'number') {
                if (number.length >= 12 && number[0] === '+' || number.length >= 9 && number[0] !== '+') {
                    if (policyRef.current.checked) {
                        submitForm()
                        error.current.innerHTML = ''
                    } else {
                        document.getElementById('number').focus()
                        error.current.innerHTML = 'Вы не приняли политику конфиденциальности!'
                    }
                } else {
                    document.getElementById('number').focus()
                    error.current.innerHTML = 'Поле "Телефон" заполнен не корректно!'
                }
            }
        }
    }

    function formSuccess() {
        responce.current.classList.toggle('formResponceFonActive')
        responceFon.current.classList.toggle('formResponceDivActive')
    }

    function submitForm() {
        if (name) {
            if (email) {
                if (validateEmail(email)) {
                    if (number.length >= 12 && number[0] === '+' || number.length >= 9 && number[0] !== '+') {
                        if (policyRef.current.checked) {
                            buttonRef.current.disabled = true
                            let csrf = ''
                            let token = ''
                            if (document.cookie) {
                                csrf = document.cookie.split('; ')
                                for (let i in csrf) {
                                    if (csrf[i].includes('csrftoken')) {
                                        token = csrf[i].replace("csrftoken=", '')
                                        break
                                    }
                                }
                            }
                            error.current.innerHTML = ''
                            axios.get('/api/create/' + args.platform + '/', {
                                params: {
                                    'name': name,
                                    'email': email,
                                    'number': number,
                                },
                            })
                                .then(response => {
                                    buttonRef.current.disabled = false
                                    setName('')
                                    setNumber('')
                                    setEmail('')
                                    formSuccess()
                                })
                        } else {
                            document.getElementById('number').focus()
                            error.current.innerHTML = 'Вы не приняли политику конфиденциальности!'
                        }
                    } else {
                        document.getElementById('number').focus()
                        error.current.innerHTML = 'Поле "Телефон" заполнен не корректно!'
                    }
                } else {
                    emailRef.current.focus()
                    error.current.innerHTML = 'Некорректное заполнение E-mail!'
                }
            } else {
                emailRef.current.focus()
                error.current.innerHTML = 'E-mail не заполнен!'
            }
        } else {
            nameRef.current.focus()
            error.current.innerHTML = 'Некорректное заполнение имени!'
        }
    }
    function getName() {
        if (user['last_name'] && !name) {
            setName(user['last_name'])
        }
    }
    function getEmail() {
        if (user['email'] && !email) {
            setEmail(user['email'])
        }
    }
    useEffect(() => {
        Aos.init({ duration: 1500 })
    }, [])
    useEffect(() => {
        if (number) {
            document.getElementById('number').style.paddingLeft = '50px'
        }
    }, [number])
    function numberFocus() {
        if (!number) {
            setNumber('+7')
        }
    }
    function numberBlur() {
        if (!number) {
            document.getElementById('number').style.paddingLeft = '5%'
        }
    }
    return (
        <>
            <div id="mailingFomr" className="homeFormDiv">
                <div className="homeFormText socialHomeFormText">
                    {args.platform === 'bot' ?
                        <p ><b>Остались вопросы? Воспользуйтесь бесплатной консультацией</b></p> :
                        <p >Воспользуйтесь преймуществами <b>BAKSBO.RU</b> уже сейчас</p>}
                    <span>
                        Сотни довольных клиентов, которые внедрили рассылку
                        сообщений в свой бизнес и сократили стоимость заявки. Наш менеджер свяжется с
                        вами <i>в течение 30 мин</i>
                    </span>
                   
                </div>
                <div ref={responce} className='formResponceFon '></div>
                <div ref={responceFon} className='formResponceDiv '>
                    <div className='formResponce'>
                        <p>Спасибо! Данные успешно отправлены</p>
                        <p>Вам позвонит первый освободившийся менеджер</p>
                        <p>ГРАФИК РАБОТЫ МЕНЕДЖЕРОВ:</p>
                        <span>пн. - пт. с 8:00 до 21:00 по МСК.</span>
                        <span>сб. - вс. с 11:00 до 21:00 по МСК.</span>
                        <button onClick={formSuccess}>Понятно</button>
                    </div>
                </div>
                <div data-aos="fade-up" className="homeForm">
                    <div className='bellDiv'></div>
                    <div className='supportForm socailForm'>
                        <div className="botFormCloseDiv"></div>
                        <h2>Оформить заказ</h2>
                        <p>Заполните форму и мы с Вами свяжемся для консультации и подтверждения заказа.</p>
                        <div className='form'>
                            <input ref={nameRef} id='name' onKeyDown={pressEnter} onChange={event => setName(event.target.value)} value={name} onFocus={getName} onClick={getName} placeholder='Имя' className='nameInput' type="text" />
                            <input ref={emailRef} id='email' onKeyDown={pressEnter} onChange={event => setEmail(event.target.value)} value={email} onFocus={getEmail} onClick={getEmail} className='emailInput nameInput' placeholder='E-mail' type="email" />
                            <section>
                                <PhoneInput
                                    id='number'
                                    onKeyDown={pressEnter}
                                    onFocus={numberFocus}
                                    onBlur={numberBlur}
                                    placeholder="Телефон"
                                    value={number}
                                    onChange={setNumber} />
                            </section>
                            <div className="formdiv">
                                <input ref={policyRef} type="checkbox" name="polytic" id="polyticChekbox" />
                                <label htmlFor="polyticChekbox">Я согласен с <a target='_blank' href="/static/files/privacy_policy.pdf"> политикой конфиденциальности</a> и <a target='_blank' href="/static/files/oferta.pdf">договором оферты</a></label>
                            </div>
                            <button ref={buttonRef} onClick={submitForm}>Отправить</button>
                        </div>
                        <p ref={error} className='errorText'></p>
                    </div>
                    <div className='messagesDiv'></div>
                </div>
            </div>
        </>
    )
}