import React, { useState } from 'react'
import './botSoundTest.css'
import WaveForm from './waveForm'


const BotSoundTest = () => {
    const [header, setHeader] = useState('Уведомления')
    const [text, setText] = useState('Информируйте об акциях, новостях и доставке заказов автоматически')
    const [li, setLi] = useState(['Автоинформатор информирует о событии: доставке заказа, акции, начале вебинара', 'Клиент совершает действие'])

    function setParams(header,text,list) {
        setHeader(header)
        setText(text)
        setLi(list)
    }
    return (
        <>
            <h5 className='botHeaderText'>
                Прослушайте <i> реальные звонки</i> и <br /> убедитесь в реалистичности бота!
            </h5>
            <div className='botSoundTestDiv'>
                <div className='soundTestDiv'>
                    <div className="soundTest">
                        <span>Холодные продажи</span>
                        <div className='waveFormDiv'>
                            <WaveForm setOnClick={() => setParams(
                                'Холодные продажи',
                                'Формируйте бота любой сложности для продажи вашего товара отвечая на любые вопросы если задаст клиент.',
                                ['Продавайте товары, инфопродукты, услуги.', 'Клиент совершает действия'])
                            } audio='/static/voice/холодные_продажи.mp3' />
                        </div>
                    </div>
                    <div className="soundTest">
                        <span>Приём данных</span>
                        <div className='waveFormDiv'>
                            <WaveForm setOnClick={() => setParams(
                                'Приём данных',
                                'Обрабатываете данные любой сложности, не зависимо от многозадачности сбора.',
                                ['Собирайте данные для трудоустройства или о клиентах.', 'Клиент совершает действие'])
                            } audio='/static/voice/прием_данных.mp3' />
                        </div>
                    </div>
                    <div className="soundTest">
                        <span>Опросы</span>
                        <div className='waveFormDiv'>
                            <WaveForm setOnClick={() => setParams(
                                'Опросы',
                                'Получайте обратную связь от клиентов о проделанной работе сотрудников или усложните логику опроса и собирайте ту информацию которая вам необходима.',
                                ['Улучшайте качество работы свое бизнеса, получайте ту информацию которая вам необходима.', 'Клиент совершает действия'])
                            } audio='/static/voice/опрос.mp3' />
                        </div>
                    </div>
                    <div className="soundTest">
                        <span>Уведомления</span>
                        <div className='waveFormDiv'>
                            <WaveForm setOnClick={() => setParams(
                                'Уведомления',
                                'Информируйте об акциях, новостях и доставке заказов автоматически',
                                ['Автоинформатор информирует о событии: доставке заказа, акции, начале вебинара', 'Клиент совершает действие'])
                            } audio='/static/voice/уведомление.mp3' />
                        </div>
                    </div>
                </div>
                <div className="soundInfo">
                    <p>{header}</p>
                    <span>{text}</span>
                    <ul>
                        {li.map(obj => {
                            return (
                                <li>{obj}</li>
                            )
                        })}
                    </ul>

                </div>
            </div>
            <p className='botSoundTesh6' ><i>*Больше примеров вы можете запросить</i> у нашего менеджера в WhatsApp или Telegram, а также ознакомиться с ними в нашей группе ВК.</p>
        </>
    )
}

export default BotSoundTest