import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { Link } from 'react-router-dom'
import './tasks.css'

const Tasks = () => {
    const dateArrow = useRef('')
    const statusArrow = useRef('')

    const [orderBY, setOrderBY] = useState('id')
    const [tasks, setTasks] = useState('')
    useEffect(() => {
        axios.get('/api/statistic_list/' + orderBY)
            .then(response => {
                setTasks(response.data)
            })
    }, [orderBY])

    function changeOrder(order) {
        if (order === 'date') {
            if (orderBY == 'id') {
                dateArrow.current.style.transform = 'rotate(180deg)'
                setOrderBY('-id')
            }
            else {
                setOrderBY('id')
                dateArrow.current.style.transform = 'rotate(0deg)'
            }
        } else if (order === 'status') {
            if (orderBY == 'status') {
                setOrderBY('-status')
                statusArrow.current.style.transform = 'rotate(180deg)'
            }else{
                setOrderBY('status')
                statusArrow.current.style.transform = 'rotate(0deg)'
            }
        }
    }

    return (
        <div className='tasksDiv'>
            <div className="tasksSettings">
                <div className="taskLogoFilter"></div>
                <div className="taskDescriptionFilter">
                    <p onClick={() => changeOrder('date')}>Дата и время <img ref={dateArrow} src="/static/img/cabinet/up.png" alt="" /></p>
                </div>
                <div className="taskStatusFilter">
                    <p onClick={() => changeOrder('status')}>Статус <img ref={statusArrow} src="/static/img/cabinet/up.png" alt="" /></p>
                </div>
                <div className="taskStatisticFilter"></div>
            </div>
            <div className='tasksSection'>
                
                {
                tasks?
                tasks.map(i => {
                    return (
                        <>
                            <div className='task'>
                                <div className="taskLogo">
                                    <img src="/static/img/cabinet/telegram.png" alt="" />
                                    <p>Telegram</p>
                                </div>
                                <div className="taskDescription">
                                    <p>{i.name}</p>
                                    <h6>{i.createDate}</h6>
                                </div>
                                <div className='taskStatus'>
                                    {i.status == 'setting' ?
                                        <p>Настройка</p>
                                        :
                                        i.status == 'start' ?
                                            <p className='start'>Запущено</p>
                                            :
                                            <p className='finish'>Завершено</p>
                                    }
                                </div>
                                <div className='taskStatistic'>
                                    {i.status == 'setting' ?
                                        <button disabled>Статистика</button>
                                        :
                                        <Link to={'/cabinet/statistic/?id=' + i.id}>
                                            <button>Статистика</button>
                                        </Link>
                                    }
                                </div>
                            </div>
                            <div className='taskMobile'>
                                <div className="taskLogo">
                                    <img src="/static/img/cabinet/telegram.png" alt="" />
                                </div>
                                <div className="taskDescriptionMobile">
                                    <p>{i.name}</p>
                                    <h6>{i.createDate}</h6>
                                    <div className='taskStatus'>
                                        <span>Статус:</span>
                                        {i.status == 'setting' ?
                                        <p>Настройка</p>
                                        :
                                        i.status == 'start' ?
                                            <p className='start'>Запущено</p>
                                            :
                                            <p className='finish'>Завершено</p>
                                    }
                                    </div>
                                    {i.status == 'setting' ?
                                        <button disabled>Статистика</button>
                                        :
                                        <Link to={'/cabinet/statistic/?id=' + i.id}>
                                            <button>Статистика</button>
                                        </Link>
                                    }
                                </div>
                            </div>
                        </>
                    )
                })
                :
                <p className='notfoundText'>Ничего не найдено</p>
            }
            </div>
        </div>
    )
}

export default Tasks