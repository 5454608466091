import './style.css'
import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import Aos from 'aos'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import 'aos/dist/aos.css'

export default function HomeForm(args) {

    const consultation = useRef(null)
    const [name, setName] = useState('')
    const [number, setNumber] = useState()
    const policyRef = useRef(null)
    const nameRef = useRef(null)
    const buttonRef = useRef(null)
    const error = useRef('')
    const responce = useRef('')
    const responceFon = useRef('')

    function formSuccess() {
        responce.current.classList.toggle('formResponceFonActive')
        responceFon.current.classList.toggle('formResponceDivActive')
    }

    function pressEnter(e) {
        if (e.keyCode === 13) {
            if (e.target.id === 'name') {
                if (name) {
                    document.getElementById('number').focus()
                    error.current.innerHTML = ''
                } else {
                    nameRef.current.focus()
                    error.current.innerHTML = 'Некорректное заполнение имени!'
                }
            } else {
                if (number.length < 11) {
                    if (policyRef.current.checked) {
                        submitForm()
                        error.current.innerHTML = ''
                    } else {
                        document.getElementById('number').focus()
                        error.current.innerHTML = 'Вы не приняли политику конфиденциальности!'
                    }
                } else {
                    document.getElementById('number').focus()
                    error.current.innerHTML = 'Поле "Телефон" заполнен не корректно!'
                }
            }
        }
    }


    function submitForm() {

        if (name) {
            if (number.length >= 12 && number[0] === '+' || number.length >= 9 && number[0] !== '+') {
                if (policyRef.current.checked) {
                    buttonRef.current.disabled = true
                    let csrf = ''
                    let token = ''
                    if (document.cookie) {
                        csrf = document.cookie.split('; ')
                        for (let i in csrf) {
                            if (csrf[i].includes('csrftoken')) {
                                token = csrf[i].replace("csrftoken=", '')
                                break
                            }
                        }
                    }
                    error.current.innerHTML = ''
                    axios.get('/api/free_consultation', {
                        params: {
                            'name': name,
                            'number': number,
                        },

                    })
                        .then(response => {
                            buttonRef.current.disabled = false
                            setName('')
                            setNumber('')
                            formSuccess()

                        })
                } else {
                    document.getElementById('number').focus()
                    error.current.innerHTML = 'Вы не приняли политику конфиденциальности!'
                }
            } else {
                document.getElementById('number').focus()
                error.current.innerHTML = 'Поле "Телефон" заполнен не корректно!'
            }
        } else {
            nameRef.current.focus()
            error.current.innerHTML = 'Некорректное заполнение имени!'
        }



    }

    useEffect(() => {
        let link = window.location.href.split('/')
        link = link[link.length - 1]
        if (link === '#freeConsultation') {
            window.scrollTo({ behavior: 'smooth', top: consultation.current.offsetTop })
        }
    }, [])
    useEffect(() => {
        Aos.init({ duration: 2500 })
    }, [])
    useEffect(() => {
        if (number) {
            document.getElementById('number').style.paddingLeft = '50px'
        }
    }, [number])
    function numberFocus() {
        if (!number) {
            setNumber('+7')
        }
    }
    function numberBlur() {
        if (!number) {
            document.getElementById('number').style.paddingLeft = '5%'
        }
    }
    return (
        <>
            <div ref={consultation} id="freeConsultation"></div>
            <div className="homeFormDiv">
                <div className="homeFormText">
                    <p >Остались вопросы?<br />Воспользуйтесь бесплатной консультацией</p>
                    <span>
                        Сотни довольных клиентов, которые внедрили рассылку
                        сообщений в свой бизнес и сократили стоимость заявки. Наш менеджер свяжется с
                        вами <i>в течение 30 мин</i>
                    </span>
                    <a href="tel:89209660591"> <img src="/static/img/bot/phone.png" alt="" />8 (920) 966-05-91</a>
                    <a href="mailto:mail@baksbo.ru@mail.ru"> <img src="/static/img/bot/mail.png" alt="" /> baksbo.ru@mail.ru</a>                    
                </div>

                <div ref={responce} className='formResponceFon '></div>
                <div ref={responceFon} className='formResponceDiv '>
                    <div className='formResponce'>
                        <p>Спасибо! Данные успешно отправлены</p>
                        <p>Вам позвонит первый освободившийся менеджер</p>
                        <p>ГРАФИК РАБОТЫ МЕНЕДЖЕРОВ:</p>
                        <span>пн. - пт. с 8:00 до 21:00 по МСК.</span>
                        <span>сб. - вс. с 11:00 до 21:00 по МСК.</span>
                        <button onClick={formSuccess}>Понятно</button>
                    </div>
                </div>
                <div data-aos="fade-up" className="homeForm">
                    <div className='bellDiv'></div>
                    <div className='supportForm'>
                        <h2>Бесплатная консультация</h2>
                        <p>Заполните форму и мы с Вами свяжемся для консультации и подтверждения заказа.</p>
                        <div className='form'>
                            <input ref={nameRef} id='name' onKeyDown={pressEnter} onChange={event => setName(event.target.value)} value={name} placeholder='Имя' className='nameInput' type="text" />

                            {/* <div className='plusNumberInput formdiv'>
                                <span ref={plus}>+</span>
                                <input ref={numberRef} id='number' onKeyDown={pressEnter} onFocus={numberFocus} onChange={event => setNumber(event.target.value)} value={number} placeholder='Телефон' className='numberInput' type="number" />
                            </div> */}
                            <section>

                                <PhoneInput
                                    id='number'
                                    onKeyDown={pressEnter}
                                    onFocus={numberFocus}
                                    onBlur={numberBlur}
                                    placeholder="Телефон"
                                    value={number}
                                    onChange={setNumber} />
                            </section>
                            <div className='formdiv'>
                                <input ref={policyRef} type="checkbox" name="polytic" id="polyticChekbox" />
                                <label htmlFor="polyticChekbox">Я согласен с <a target='_blank' href="/static/files/privacy_policy.pdf"> политикой конфиденциальности</a></label>
                            </div>
                            <button ref={buttonRef} onClick={submitForm}>Отправить</button>
                        </div>
                        <p ref={error} className='errorText'></p>
                    </div>
                    <div className='messagesDiv'></div>
                </div>
            </div>
        </>
    )
}