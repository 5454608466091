import React from 'react'
import './mailingShadow.css'


const MailingShadow = (args) => {
  return (
    <div style={{left: args.left, right: args.right,top: args.top,bottom: args.bottom,opacity:args.opacity}} className={`mailingShadow ${args.className}`}>
      {args.red !== 0 &&
        <div className='mailingShadow1'></div>}
      {args.blue !== 0 &&
        <div className='mailingShadow2'></div>}
      {args.yellow !== 0 &&
        <div className='mailingShadow3'></div>}
    </div>
  )
}

export default MailingShadow