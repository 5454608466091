import React, { useState } from 'react'
import './helpDiv.css'

const HelpDiv = (args) => {
    const [text, setText] = useState(false)
    function changeText() {
        if (text) {
            setText(false)

            return
        }
        setText(true)


    }
    return (
        <div className='helpDiv'>
            <div onClick={changeText} className='infoPng' style={{marginTop:args.margin.top,marginLeft:args.margin.left}}>
                <img src="/static/img/cabinet/info.png" alt="" />
            </div>
            {text  ?
                <div  onClick={changeText} className='infoText' style={{marginTop:args.margin.top+14,marginLeft:args.margin.left+14}}><p>{args.text}</p></div>
                :
                <></>
            }
        </div>
    )
}

export default HelpDiv