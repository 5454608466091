import axios from 'axios'
import React, { useRef, useState } from 'react'
import './myMailing.css'


const MyMailing = () => {
    const allButton = useRef(null)
    const instagramButton = useRef(null)
    const telegramButton = useRef(null)
    const whatsappButton = useRef(null)
    const viberButton = useRef(null)
    const vkButton = useRef(null)

    const [mailings, setMailings] = useState([[]])


    function getMailings(platform = 'all') {
        if (platform) {
            if (platform === 'instagram') {
                instagramButton.current.classList.add('CabinetMailingFilterDivActive')
                telegramButton.current.classList.remove('CabinetMailingFilterDivActive')
                whatsappButton.current.classList.remove('CabinetMailingFilterDivActive')
                viberButton.current.classList.remove('CabinetMailingFilterDivActive')
                vkButton.current.classList.remove('CabinetMailingFilterDivActive')
                allButton.current.classList.remove('CabinetMailingFilterDivActive')
            }
            else if (platform === 'telegram') {
                telegramButton.current.classList.add('CabinetMailingFilterDivActive')
                instagramButton.current.classList.remove('CabinetMailingFilterDivActive')
                whatsappButton.current.classList.remove('CabinetMailingFilterDivActive')
                viberButton.current.classList.remove('CabinetMailingFilterDivActive')
                vkButton.current.classList.remove('CabinetMailingFilterDivActive')
                allButton.current.classList.remove('CabinetMailingFilterDivActive')
            }
            else if (platform === 'whatsapp') {
                whatsappButton.current.classList.add('CabinetMailingFilterDivActive')
                telegramButton.current.classList.remove('CabinetMailingFilterDivActive')
                instagramButton.current.classList.remove('CabinetMailingFilterDivActive')
                viberButton.current.classList.remove('CabinetMailingFilterDivActive')
                vkButton.current.classList.remove('CabinetMailingFilterDivActive')
                allButton.current.classList.remove('CabinetMailingFilterDivActive')
            }
            else if (platform === 'viber') {
                viberButton.current.classList.add('CabinetMailingFilterDivActive')
                instagramButton.current.classList.remove('CabinetMailingFilterDivActive')
                whatsappButton.current.classList.remove('CabinetMailingFilterDivActive')
                telegramButton.current.classList.remove('CabinetMailingFilterDivActive')
                vkButton.current.classList.remove('CabinetMailingFilterDivActive')
                allButton.current.classList.remove('CabinetMailingFilterDivActive')
            }
            else if (platform === 'vkontakt') {
                vkButton.current.classList.add('CabinetMailingFilterDivActive')
                viberButton.current.classList.remove('CabinetMailingFilterDivActive')
                instagramButton.current.classList.remove('CabinetMailingFilterDivActive')
                whatsappButton.current.classList.remove('CabinetMailingFilterDivActive')
                telegramButton.current.classList.remove('CabinetMailingFilterDivActive')
                allButton.current.classList.remove('CabinetMailingFilterDivActive')
            }
            else if (platform === 'all' && allButton.current) {
                allButton.current.classList.add('CabinetMailingFilterDivActive')
                vkButton.current.classList.remove('CabinetMailingFilterDivActive')
                viberButton.current.classList.remove('CabinetMailingFilterDivActive')
                instagramButton.current.classList.remove('CabinetMailingFilterDivActive')
                whatsappButton.current.classList.remove('CabinetMailingFilterDivActive')
                telegramButton.current.classList.remove('CabinetMailingFilterDivActive')
            }
        }

        // axios.get('/api/self-mailing/',
        //     {
        //         params: {
        //             platform: 'platform',
        //         },
        //     })
        //     .then(response => {
        //         setMailings([response.data])
        //     })
    }

    return (
        <div className="myMailingDiv">
            <div className="CabinetMailingFilterDiv">
                <button className='CabinetMailingFilterDivActive' onClick={() => getMailings('all')} ref={allButton}>Всё</button>
                <button onClick={() => getMailings('instagram')} ref={instagramButton}>Instagram</button>
                <button onClick={() => getMailings('telegram')} ref={telegramButton}>Telegram</button>
                <button onClick={() => getMailings('whatsapp')} ref={whatsappButton}>WhatsApp</button>
                <button onClick={() => getMailings('viber')} ref={viberButton}>Viber</button>
                <button onClick={() => getMailings('vkontakt')} ref={vkButton}>Vkontakte</button>
            </div>
        </div>
    )
}

export default MyMailing