import axios from 'axios'
import React from 'react'
import './payment.css'


const Payment = () => {
  function pay() {
    axios.post('/api/payment', {
      ok: 'ok',
    }).then(response=>{
      if (response.data){
        window.location.reload()
      }
    })
  }
  return (
    <div className='paymentPage'>
      <p onClick={pay}>
        тест: купить +1 месяц
      </p>
    </div>
  )
}

export default Payment