const metrics = {

    "127.0.0.1": {
        "script": "main",
        "yandex": "84b54a561474a428",
        "google": "ZgYgwxV5nY9I6UA2oN__DVtsR2xnUdv2t4SVEP7ylQA",
    },
    "localhost": {
        "script": "main",
        "yandex": "84b54a561474a428",
        "google": "ZgYgwxV5nY9I6UA2oN__DVtsR2xnUdv2t4SVEP7ylQA",
    },
    "baksbo.ru": {
        "script": "main",
        "yandex": "84b54a561474a428",
        "google": "ZgYgwxV5nY9I6UA2oN__DVtsR2xnUdv2t4SVEP7ylQA",
    },
    "msk.baksbo.ru": {
        "script": "msk",
        "yandex": "84b54a561474a428",
        "google": "ZgYgwxV5nY9I6UA2oN__DVtsR2xnUdv2t4SVEP7ylQA.",
    },
    "spb.baksbo.ru": {
        "script": "spb",
        "yandex": "f424e665e6ddf18b",
        "google": "lPwEyK5LVD1nVZtZm6RVd1aQU-5Qvl6Ika5-ZOs4xDw",
    },
    "krd.baksbo.ru": {
        "script": "krd",
        "yandex": "d8e68ab5ecf89115",
        "google": "lPwEyK5LVD1nVZtZm6RVd1aQU-5Qvl6Ika5-ZOs4xDw",
    },
    "kzn.baksbo.ru": {
        "script": "kzn",
        "yandex": "84b54a561474a428",
        "google": "ZgYgwxV5nY9I6UA2oN__DVtsR2xnUdv2t4SVEP7ylQA",
    }
}
export default metrics