import React from 'react'
import BotUnderKey from '../../../../elements/botUnderKey/botUnderKey'
import BotPrice from '../../../../elements/botPrice/botPrice'
import './botDevelopment.css'
import BotTarifs from '../../../../elements/botTarifs/botTarifs'

const BotDevelopment = () => {
  return (
    <div className="botEducationCabinet">
      <div className='waveBotHeaderText'>
        <h5 id='bot_education' className='botHeaderText'>Голосовой бот под ключ</h5>
      </div>
      <p className='botEducationCabinetTetx'>
        Узнать подробнее на сайте.
        <a className='botEducationCabinetHref' target='_blank' href="/bot#bot_under_key">Подробнее</a>
      </p>
    </div>
  )
}

export default BotDevelopment