

import { useEffect, useState } from 'react'
import './style.css'


export default function ChatFon() {
    const [offset, setOffset] = useState(0)
    const setScroll = () => {
        if (window.scrollY < 800) {
            if (window.scrollY >= offset) {
                setOffset(window.scrollY)
            } else {
                setOffset(offset - window.scrollY * 5)
            }
            for (let i = 1; i < 10; i++) {
                let smsRight = document.getElementById('blueSMS_' + i).style.right.replace('%', '')
                document.getElementById('blueSMS_' + i).style.right = smsRight - (offset / 5000) + '%'
            }
            for (let i = 1; i < 10; i++) {
                let smsLeft = document.getElementById('graySMS_' + i).style.left.replace('%', '')
                document.getElementById('graySMS_' + i).style.left = smsLeft - (offset / 5000) + '%'
            }
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", setScroll)
        
        return () => {
            window.removeEventListener("scroll", setScroll)
        }
    }, [offset])

    const blueSMS = [
        { id: 'blueSMS_1', top: '50', right: '25' },
        { id: 'blueSMS_2', top: '16', right: '90' },
        { id: 'blueSMS_3', top: '11.6', right: '7' },
        { id: 'blueSMS_4', top: '36', right: '5' },
        { id: 'blueSMS_5', top: '33.5', right: '40' },
        { id: 'blueSMS_6', top: '48', right: '53' },
        { id: 'blueSMS_7', top: '27', right: '56' },
        { id: 'blueSMS_8', top: '2', right: '80' },
        { id: 'blueSMS_9', top: '13', right: '30' },
    ]
    const graySMS = [
        { id: 'graySMS_1', top: '7.5', left: '50' },
        { id: 'graySMS_2', top: '3', left: '22' },
        { id: 'graySMS_3', top: '23', left: '15' },
        { id: 'graySMS_4', top: '8.5', left: '31' },
        { id: 'graySMS_5', top: '50.5', left: '80' },
        { id: 'graySMS_6', top: '37', left: '1' },
        { id: 'graySMS_7', top: '30', left: '90' },
        { id: 'graySMS_8', top: '52.7', left: '33' },
        { id: 'graySMS_9', top: '3', left: '78' },
    ]

    return (
        <div className="chatFon">
            <p></p>
            

            {blueSMS.map(sms => {
                if (window.screen.width > window.screen.height) {
                    return (
                        <img alt='' key={sms.id} id={sms.id} style={{ top: sms.top + 'vh', right: sms.right + "%" }} className="blueSMS" src="static/img/sms_blue.png" />
                    )
                }
                return (
                    <img alt='' key={sms.id} id={sms.id} style={{ top: +sms.top * 2 + 'vh', right: sms.right + "%" }} className="blueSMS" src="static/img/sms_blue.png" />
                )
            })
            }
            {graySMS.map(sms => {
                if (window.screen.width > window.screen.height) {
                    return (
                        <img alt='' key={sms.id} id={sms.id} style={{ top: sms.top + 'vh', left: sms.left + "%" }} className="graySMS" src="static/img/sms_gray.png" />
                    )
                }
                return (
                    <img alt='' key={sms.id} id={sms.id} style={{ top: +sms.top * 2 + 'vh', left: sms.left + "%" }} className="graySMS" src="static/img/sms_gray.png" />
                )
            })
            }

        </div>
    )
}