import { useEffect, useRef, useState } from 'react'
import './mailing.css'
import axios from 'axios'
import { Link } from 'react-router-dom'

const MailingStatistic = () => {
    const up = useRef('')
    const selfMailingCabinetDiv = useRef('')
    const allButton = useRef(null)
    const instagramButton = useRef(null)
    const telegramButton = useRef(null)
    const whatsappButton = useRef(null)
    const viberButton = useRef(null)
    const vkButton = useRef(null)

    const x = [[
        {
            "platform": "telegram",
            "status": "finish",
            "send": 4545,
            "delivery": '0',
            "error": 'None',
            "read": 'None',
            "transition": 4545,
            "date": "03.10.2022 18:04",
            "description": "много писание много писание много писание много писание много писание много писание"
        },
        {
            "platform": "instagram",
            "status": "setting",
            "send": 4545,
            "delivery": 45,
            "error": 4544,
            "read": 212,
            "transition": 554,
            "date": "03.10.2022 22:28"
        },
        {
            "platform": "vkontakt",
            "status": "start",
            "send": 'None',
            "delivery": 'None',
            "error": 'None',
            "read": 'None',
            "transition": 'None',
            "date": "03.10.2022 21:55",
            "description": "много писание много писание много"
        },
        {
            "platform": "vkontakt",
            "status": "finish",
            "send": 'None',
            "delivery": 'None',
            "error": 'None',
            "read": 'None',
            "transition": 'None',
            "date": "03.10.2022 22:21"
        }
    ]]

    const [mailings, setMailings] = useState([[]])
    // const [mailings, setMailings] = useState(x)

    function sort() {
        up.current.classList.toggle('rotate')
        selfMailingCabinetDiv.current.classList.toggle('reverse')
    }

    useEffect(() => {
        getMailings('all')
    }, []);

    function getMailings(platform = 'all') {
        if (platform) {
            if (platform === 'instagram') {
                instagramButton.current.classList.add('CabinetMailingFilterDivActive')
                telegramButton.current.classList.remove('CabinetMailingFilterDivActive')
                whatsappButton.current.classList.remove('CabinetMailingFilterDivActive')
                viberButton.current.classList.remove('CabinetMailingFilterDivActive')
                vkButton.current.classList.remove('CabinetMailingFilterDivActive')
                allButton.current.classList.remove('CabinetMailingFilterDivActive')
            }
            else if (platform === 'telegram') {
                telegramButton.current.classList.add('CabinetMailingFilterDivActive')
                instagramButton.current.classList.remove('CabinetMailingFilterDivActive')
                whatsappButton.current.classList.remove('CabinetMailingFilterDivActive')
                viberButton.current.classList.remove('CabinetMailingFilterDivActive')
                vkButton.current.classList.remove('CabinetMailingFilterDivActive')
                allButton.current.classList.remove('CabinetMailingFilterDivActive')
            }
            else if (platform === 'whatsapp') {
                whatsappButton.current.classList.add('CabinetMailingFilterDivActive')
                telegramButton.current.classList.remove('CabinetMailingFilterDivActive')
                instagramButton.current.classList.remove('CabinetMailingFilterDivActive')
                viberButton.current.classList.remove('CabinetMailingFilterDivActive')
                vkButton.current.classList.remove('CabinetMailingFilterDivActive')
                allButton.current.classList.remove('CabinetMailingFilterDivActive')
            }
            else if (platform === 'viber') {
                viberButton.current.classList.add('CabinetMailingFilterDivActive')
                instagramButton.current.classList.remove('CabinetMailingFilterDivActive')
                whatsappButton.current.classList.remove('CabinetMailingFilterDivActive')
                telegramButton.current.classList.remove('CabinetMailingFilterDivActive')
                vkButton.current.classList.remove('CabinetMailingFilterDivActive')
                allButton.current.classList.remove('CabinetMailingFilterDivActive')
            }
            else if (platform === 'vkontakt') {
                vkButton.current.classList.add('CabinetMailingFilterDivActive')
                viberButton.current.classList.remove('CabinetMailingFilterDivActive')
                instagramButton.current.classList.remove('CabinetMailingFilterDivActive')
                whatsappButton.current.classList.remove('CabinetMailingFilterDivActive')
                telegramButton.current.classList.remove('CabinetMailingFilterDivActive')
                allButton.current.classList.remove('CabinetMailingFilterDivActive')
            }
            else if (platform === 'all' && allButton.current) {
                allButton.current.classList.add('CabinetMailingFilterDivActive')
                vkButton.current.classList.remove('CabinetMailingFilterDivActive')
                viberButton.current.classList.remove('CabinetMailingFilterDivActive')
                instagramButton.current.classList.remove('CabinetMailingFilterDivActive')
                whatsappButton.current.classList.remove('CabinetMailingFilterDivActive')
                telegramButton.current.classList.remove('CabinetMailingFilterDivActive')
            }
        }

        axios.get('/api/self-mailing/',
            {
                params: {
                    platform: platform,
                },
            })
            .then(response => {
                setMailings([response.data])
            })
    }

    return (
        <>
            <p className='cabinetMessageMailingText'>В данном разделе вы можете наблюдать за статусом и статистикой рассылки под ключ. Обновление страницы происходит в онлайн режиме.</p>
            <Link className='buyMaiiling' to={'/cabinet/mailing'}>Заказать рассылку</Link>
            <>
                <div className="CabinetMailingFilterDiv">
                    <button className='CabinetMailingFilterDivActive' onClick={() => getMailings('all')} ref={allButton}>Всё</button>
                    <button onClick={() => getMailings('instagram')} ref={instagramButton}>Instagram</button>
                    <button onClick={() => getMailings('telegram')} ref={telegramButton}>Telegram</button>
                    <button onClick={() => getMailings('whatsapp')} ref={whatsappButton}>WhatsApp</button>
                    <button onClick={() => getMailings('viber')} ref={viberButton}>Viber</button>
                    <button onClick={() => getMailings('vkontakt')} ref={vkButton}>Vkontakte</button>
                </div>
                <div className='SelfMailingCabinetIcons'>
                    <div className="iconTimeInfoIconDiv"><p>Название/Время <img className='rotate' ref={up} onClick={event => sort(event.target)} src="/static/img/cabinet/up.png" alt="" /></p></div>
                    <div className="statusDivIconDiv"><p>Статус</p></div>
                    <div className="SelfMailingCabinetStatisticIconDiv">
                        <img src="/static/img/cabinet/send.png" alt="" />
                        <img src="/static/img/cabinet/delivery.png" alt="" />
                        <img src="/static/img/cabinet/error.png" alt="" />
                        <img src="/static/img/cabinet/read.png" alt="" />
                        <img src="/static/img/cabinet/transition.png" alt="" />
                    </div>
                </div>
                <div ref={selfMailingCabinetDiv} className="SelfMailingCabinetDiv">
                    {mailings.map(mailing => {
                        return (
                            mailing ?
                                mailing.map(i => {
                                    return (
                                        i['platform'] === 'not-found' ?
                                            <p className='CabinetMailingNotFound'>Нечего не найденно</p>
                                            :
                                            <div className='SelfMailingCabinetElement'>
                                                <div className='iconTimeInfo'>
                                                    <img src={'/static/img/cabinet/' + i['platform'] + '.png'} alt="" />
                                                    <p className=''>{i['platform']}</p>
                                                    <span className=''>{i['date']}</span>
                                                    {i['description'] ? <h6>{i['description']}</h6> : <></>}
                                                </div>
                                                <div className='iconTimeInfoMobile'>
                                                    <img src={'/static/img/cabinet/' + i['platform'] + '.png'} alt="" />
                                                    <div>
                                                        <p className=''>{i['platform']}</p>
                                                        <span >{i['date']}</span>
                                                        {i['description'] ? <h6>{i['description']}</h6> : <></>}
                                                        {i['status'] === 'setting' ?
                                                            <p className='status'><span>Статус:</span> Настройка</p>
                                                            :
                                                            i['status'] === 'start' ?
                                                                <p className='status start'><span>Статус:</span> Запуск</p>
                                                                :
                                                                <p className='status finish'><span>Статус:</span> Завершена</p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='statusDiv'>
                                                    {i['status'] === 'setting' ?
                                                        <p>Настройка</p>
                                                        :
                                                        i['status'] === 'start' ?
                                                            <p className='start'>Запуск</p>
                                                            :
                                                            <p className='finish'>Завершена</p>
                                                    }
                                                </div>
                                                <div className='SelfMailingCabinetStatistic'>
                                                    {i['send'] !== 'None' ?
                                                        <p className='sendMailing'>
                                                            Отправленно
                                                            <b>{i['send']}</b>
                                                            <span>сообщений</span>
                                                        </p>
                                                        :
                                                        <div className='zaglushka'><p>Не Доступно</p></div>
                                                    }
                                                    {i['delivery'] !== 'None' ?
                                                        <p className='deliveryMailing'>
                                                            Доставлено
                                                            <b>{i['delivery']}</b>
                                                            <span>сообщений</span>
                                                        </p>
                                                        :
                                                        <div className='zaglushka'><p>Не Доступно</p></div>
                                                    }
                                                    {i['error'] !== 'None' ?
                                                        <p className='errorMailing'>
                                                            Ошибок
                                                            <b>{i['error']}</b>
                                                            <span>сообщений</span>
                                                        </p>
                                                        :
                                                        <div className='zaglushka'><p>Не Доступно</p></div>
                                                    }
                                                    {i['read'] !== 'None' ?
                                                        <p className='readMailing'>
                                                            Прочитано
                                                            <b>{i['read']}</b>
                                                            <span>сообщений</span>
                                                        </p>
                                                        :
                                                        <div className='zaglushka'><p>Не Доступно</p></div>
                                                    }
                                                    {i['transition'] !== 'None' ?
                                                        <p className='transitionMailing'>
                                                            Переходы
                                                            <b>{i['transition']}</b>
                                                            <span>сообщений</span>
                                                        </p>
                                                        :
                                                        <div className='zaglushka'><p>Не Доступно</p></div>
                                                    }
                                                </div>
                                                <div className="SelfMailingCabinetStatisticMobile">
                                                    {i['send'] !== 'None' ?
                                                        <p className='sendMailing'>
                                                            <img src="/static/img/cabinet/send.png" alt="" />
                                                            Отправленно: <b> {i['send']}</b>
                                                        </p>
                                                        :
                                                        <div className='zaglushkaMobile'> <p><img src="/static/img/cabinet/send.png" alt="" /> Не Доступно</p></div>
                                                    }
                                                    {i['delivery'] !== 'None' ?
                                                        <p className='deliveryMailing'>
                                                            <img src="/static/img/cabinet/delivery.png" alt="" />
                                                            Доставлено: <b> {i['delivery']}</b>
                                                        </p>
                                                        :
                                                        <div className='zaglushkaMobile'> <p><img src="/static/img/cabinet/delivery.png" alt="" /> Не Доступно</p></div>
                                                    }
                                                    {i['error'] !== 'None' ?
                                                        <p className='errorMailing'>
                                                            <img src="/static/img/cabinet/error.png" alt="" />
                                                            Ошибок: <b> {i['error']}</b>
                                                        </p>
                                                        :
                                                        <div className='zaglushkaMobile'> <p><img src="/static/img/cabinet/error.png" alt="" /> Не Доступно</p></div>
                                                    }
                                                    {i['read'] !== 'None' ?
                                                        <p className='readMailing'>
                                                            <img src="/static/img/cabinet/read.png" alt="" />
                                                            Прочитано: <b> {i['read']}</b>
                                                        </p>
                                                        :
                                                        <div className='zaglushkaMobile'> <p><img src="/static/img/cabinet/read.png" alt="" /> Не Доступно</p></div>
                                                    }
                                                    {i['transition'] !== 'None' ?
                                                        <p className='transitionMailing'>
                                                            <img src="/static/img/cabinet/transition.png" alt="" />
                                                            Переходы: <b> {i['transition']}</b>
                                                        </p>
                                                        :
                                                        <div className='zaglushkaMobile'> <p><img src="/static/img/cabinet/transition.png" alt="" /> Не Доступно</p></div>
                                                    }
                                                </div>
                                            </div>

                                    )
                                })
                                : <></>
                        )
                    })}
                </div>
            </>
        </>
    )
}

export default MailingStatistic