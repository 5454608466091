import './mailing.css'
import { Route, Routes } from 'react-router-dom'
import MailingStatistic from './statistic'
import MailingKey from './mailingKey'

const Mailing = () => {

  return (
    <>
      <Routes>
        <Route path='/' element={<MailingKey />} />
        <Route path='/statistic' element={<MailingStatistic />} />
      </Routes>
    </>
  )
}

export default Mailing