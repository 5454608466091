import { Routes, Route } from 'react-router-dom';
import { useState, useEffect, createContext } from 'react'
import axios from 'axios';
import BasicSite from "./basicSite";
import CabinetSite from './cabinetSite';
import Registration from './cabinet/registration/registration'
import Login from './cabinet/login/login';
import './css/Style.css';
import AdminSite from './adminSite';
import AdminLogin from './admin/adminLogin/adminLogin';
import { Helmet } from 'react-helmet'
export const UserContext = createContext()

function App() {
  let x = {
    email: 'basmajyan93@gmail.com',
    last_name: 'User', token: 's56d23a345s2d45545as',
    number: 44444444444, admin: true, referal_sale: false,
    tarif: false, bot: true, tarif_text: "4 дней, 1 часов, 5 минут",
  }
  const [user, setUser] = useState('')


  useEffect(() => {
    axios.get('/api/is_auth')
      .then(response => {
        if (response.data['email'] || response.data['last_name']) {
          setUser(response.data)
          localStorage.setItem('userIsAuth', "true")
        } else {
          localStorage.setItem('userIsAuth', null)
        }
      })
  }, [])

  return (
    <UserContext.Provider value={user}>
      <>
        {window.location.hostname == 'msk.baksbo.ru' &&
          <noscript><div><img src="https://mc.yandex.ru/watch/97298099" style="position:absolute; left:-9999px;" alt="" /></div></noscript>
        }
      </>
      <Routes>
        <Route path='/*' element={<BasicSite />} />
        {user || localStorage.getItem('userIsAuth') === "true" ?
          <>
            <Route path='registration' element={<CabinetSite />} />
            <Route path='login' element={<CabinetSite />} />
            <Route path='/cabinet/*' element={<CabinetSite />} />
          </>
          :
          <>
            <Route path='registration' element={<Registration />} />
            <Route path='login' element={<Login />} />
            <Route path='/cabinet/*' element={<Login />} />
          </>
        }
        {user.admin ?
          <Route path='/admin/*' element={<AdminSite />} />
          :
          <Route path='/admin/*' element={<AdminLogin />} />
        }
      </Routes>
    </UserContext.Provider>
  );
}

export default App;
