import './style.css'
import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import Aos from 'aos'
import 'aos/dist/aos.css'


export default function MessageMailing() {
    const [instagram, setInstagram] = useState('')
    const [telegram, setTelegram] = useState('')
    const [whatsapp, setWhatsapp] = useState('')
    const [viber, setViber] = useState('')
    const [vk, setVk] = useState('')

    useEffect(() => {
        axios.get('/api/price')
            .then(request => request.data.prices)
            .then(request => {
                setInstagram(request.instagram)
                setTelegram(request.telegram)
                setWhatsapp(request.whatsapp)
                setViber(request.viber)
                setVk(request.vk)
            }
            )
    }, [])

    const mailing = useRef(null)

    useEffect(() => {
        let link = window.location.href.split('/')
        link = link[link.length - 1]
        if (link === '#mailings') {
            window.scrollTo({ behavior: 'smooth', top: mailing.current.offsetTop })
        }
    }, [])
    useEffect(() => {
        Aos.init({ duration: 1500 })

    }, [])

    return (
        <>
            <div ref={mailing} id='mailings'></div>
            <div id='mailings' className="messageMailing">
                <div className='apps'>
                    <Link to={'/instagram'}>
                        <div data-aos="fade-up" className="mailingApp instagram " style={{transition: '0.7s'}}>
                            <div>
                                <img className='twoD instagram2d' src="/static/img/icons/gold_insta.png" alt="" />
                                <img className='treeD instagram3d' src="/static/img/insta3d.png" alt="" />
                            </div>
                            <p className='name'>Instagram</p>
                            <p className='instagramPrice price'>от {instagram}<b> ₽</b></p>
                            <span>*за одно сообщение</span>
                            <button className='instagramButton' >Подробней</button>
                        </div>
                    </Link>
                    <Link to={'/telegram'}>
                        <div id='scrollmailings' data-aos="fade-up" className="mailingApp telegram " style={{transition: '0.7s'}}>
                            <div>
                                <img className='twoD telegram2d' src="/static/img/icons/gold_tg.png" alt="" />
                                <img className='treeD telegram3d' src="/static/img/tg3d.png" alt="" />
                            </div>
                            <p className='name'>Telegram</p>
                            <p className='telegramPrice price'>от {telegram}<b> ₽</b></p>
                            <span>*за одно сообщение</span>
                            <button className='telegramButton' >Подробней</button>
                        </div>
                    </Link>
                    <Link to={'/whatsapp'}>
                        <div data-aos="fade-up" className="mailingApp whatsApp" style={{transition: '0.7s'}}>
                            <div>
                                <img className='twoD whatsApp2d' src="/static/img/icons/gold_whatsApp.png" alt="" />
                                <img className='treeD whatsApp3d' src="/static/img/whats3d.png" alt="" />
                            </div>
                            <p className='name'>WhatsApp</p>
                            <p className='whatsAppPrice price'>от {whatsapp}<b> ₽</b></p>
                            <span>*за одно сообщение</span>
                            <button className='whatsAppButton' >Подробней</button>
                        </div>
                    </Link>
                    <Link to={'/viber'}>
                        <div data-aos="fade-up" className="mailingApp viber" style={{transition: '0.7s'}}>
                            <div>
                                <img className='twoD viber2d' src="/static/img/icons/gold_viber.png" alt="" />
                                <img className='treeD viber3d' src="/static/img/viber3d.png" alt="" />
                            </div>
                            <p className='name'>Viber</p>
                            <p className='viberPrice price'>от {viber}<b> ₽</b></p>
                            <span>*за одно сообщение</span>
                            <button className='viberButton' >Подробней</button>

                        </div>
                    </Link>
                    <Link to={'/vk'}>
                        <div data-aos="fade-up" className="mailingApp vkontakte" style={{transition: '0.7s'}}>
                            <div>
                                <img className='twoD vkontakte2d' src="/static/img/icons/gold_vk.png" alt="" />
                                <img className='treeD vkontakte3d' src="/static/img/vk3d.png" alt="" />
                            </div>
                            <p className='name'>Vkontakte</p>
                            <p className='vkontaktePrice price'>от {vk}<b> ₽</b></p>
                            <span>*за одно сообщение</span>
                            <button className='vkontakteButton' >Подробней</button>
                        </div>
                    </Link>
                </div>
            </div>
        </>
    )
}