
import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import './support.css'



const Support = (args) => {
    const consultation = useRef(null)
    const [name, setName] = useState('')
    const [number, setNumber] = useState()
    const [success, setSuccess] = useState(false)
    const policyRef = useRef(null)
    const nameRef = useRef(null)
    const buttonRef = useRef(null)
    const error = useRef('')
    const responceFon = useRef('')

    function formSuccess() {
        setSuccess(true)
    }

    function pressEnter(e) {
        if (e.keyCode === 13) {
            if (e.target.id === 'name') {
                if (name) {
                    document.getElementById('number').focus()
                    error.current.innerHTML = ''
                } else {
                    nameRef.current.focus()
                    error.current.innerHTML = 'Некорректное заполнение имени!'
                }
            } else {
                if (number.length < 11) {
                    if (policyRef.current.checked) {
                        submitForm()
                        error.current.innerHTML = ''
                    } else {
                        document.getElementById('number').focus()
                        error.current.innerHTML = 'Вы не приняли политику конфиденциальности!'
                    }
                } else {
                    document.getElementById('number').focus()
                    error.current.innerHTML = 'Поле "Телефон" заполнен не корректно!'
                }
            }
        }
    }


    function submitForm() {

        if (name) {
            if (number.length >= 12 && number[0] === '+' || number.length >= 9 && number[0] !== '+') {
                if (policyRef.current.checked) {
                    buttonRef.current.disabled = true
                    let csrf = ''
                    let token = ''
                    if (document.cookie) {
                        csrf = document.cookie.split('; ')
                        for (let i in csrf) {
                            if (csrf[i].includes('csrftoken')) {
                                token = csrf[i].replace("csrftoken=", '')
                                break
                            }
                        }
                    }
                    error.current.innerHTML = ''
                    axios.get('/api/support', {
                        params: {
                            'name': name,
                            'number': number,
                            'category': args.category,
                        },

                    })
                        .then(response => {
                            buttonRef.current.disabled = false
                            setName('')
                            setNumber('')
                            formSuccess()

                        })
                } else {
                    document.getElementById('number').focus()
                    error.current.innerHTML = 'Вы не приняли политику конфиденциальности!'
                }
            } else {
                document.getElementById('number').focus()
                error.current.innerHTML = 'Поле "Телефон" заполнен не корректно!'
            }
        } else {
            nameRef.current.focus()
            error.current.innerHTML = 'Некорректное заполнение имени!'
        }



    }

    useEffect(() => {
        let link = window.location.href.split('/')
        link = link[link.length - 1]
        if (link === '#freeConsultation') {
            window.scrollTo({ behavior: 'smooth', top: consultation.current.offsetTop })
        }
    }, [])
    useEffect(() => {
        if (number) {
            document.getElementById('number').style.paddingLeft = '50px'
        }
    }, [number])
    function numberFocus() {
        if (!number) {
            setNumber('+7')
        }
    }
    function numberBlur() {
        if (!number) {
            document.getElementById('number').style.paddingLeft = '5%'
        }
    }
    return (

        args.active &&
        <>
            {success ?
                < div className='supportDiv'>
                    <div onClick={() => args.setFunc(false)} className='supportDivFon'>
                    </div>
                    <div ref={responceFon} className='formResponceDiv formResponceDivActive'>
                        <div className='formResponce'>
                            <p>Спасибо! Данные успешно отправлены</p>
                            <p>Вам позвонит первый освободившийся менеджер</p>
                            <p>ГРАФИК РАБОТЫ МЕНЕДЖЕРОВ:</p>
                            <span>пн. - пт. с 8:00 до 21:00 по МСК.</span>
                            <span>сб. - вс. с 11:00 до 21:00 по МСК.</span>
                            <button onClick={() => args.setFunc(false)}>Понятно</button>
                        </div>
                    </div>
                </div>

                :
                <div className='supportDiv'>
                    <div onClick={() => args.setFunc(false)} className='supportDivFon'>
                    </div>


                    <div className="homeForm">
                        <div className='supportForm'>
                            <div className="homeFormClose" onClick={() => args.setFunc(false)}>
                                <img src="/static/img/manuButtonActove.png" alt="" />
                            </div>
                            <h2>Оставить заявку</h2>
                            <p>Заполните форму и мы с Вами свяжемся в течение 30 минут!</p>
                            <div className='form'>
                                <input ref={nameRef} id='name' onKeyDown={pressEnter} onChange={event => setName(event.target.value)} value={name} placeholder='Имя' className='nameInput' type="text" />
                                <section>

                                    <PhoneInput
                                        id='number'
                                        onKeyDown={pressEnter}
                                        onFocus={numberFocus}
                                        onBlur={numberBlur}
                                        placeholder="Телефон"
                                        value={number}
                                        onChange={setNumber} />
                                </section>
                                <div className='formdiv'>
                                    <input ref={policyRef} type="checkbox" name="polytic" id="polyticChekbox" />
                                    <label htmlFor="polyticChekbox">Я согласен с <a target='_blank' href="/static/files/privacy_policy.pdf"> политикой конфиденциальности</a></label>
                                </div>
                                <button ref={buttonRef} onClick={submitForm}>Отправить</button>
                            </div>
                            <p ref={error} className='errorText'></p>
                        </div>
                    </div>
                </div>
            }
        </>

    )
}

export default Support