import { Routes, Route, Navigate } from 'react-router-dom';
import './css/Style.css';
import Header from './layout/Header';
import Footer from './layout/Footer';
import Home from './page/home/home';
import Contacts from './page/contact/contact';
import Instagram from './page/social/instagram';
import Telegram from './page/social/telegram';
import Whatsapp from './page/social/whatsapp';
import Viber from './page/social/viber';
import Vk from './page/social/vk';
import Review from './page/review/review';
import NotFoundPage from './page/notFound/notFound';
import SelfMailing from './page/selfMailing/selfMailing';
import Bot from './page/bot/bot';
import Blog from './page/blog/blog';
import BlogItem from './page/blogItem/blogItem';
import { useEffect } from 'react';

const RedirectTo404 = () => {
  useEffect(() => {
    window.location.href = 'https://baksbo.ru/404';
  }, []);

  return null;
};


function BasicSite() {

  return (
    <>
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='#freeConsultation' element={<Home />} />
        <Route path='instagram' element={<Instagram />} />
        <Route path='telegram' element={<Telegram />} />
        <Route path='whatsapp' element={<Whatsapp />} />
        <Route path='viber' element={<Viber />} />
        <Route path='vk' element={<Vk />} />
        <Route path='soft' element={<SelfMailing />} />
        <Route path='bot' element={<Bot />} />
        <Route path='review' element={<Review />} />
        <Route path='reviews' element={<Review />} />
        <Route path='contact' element={<Contacts />} />
        <Route path='blog' element={<Blog />} />
        <Route path='blog/:id' element={<BlogItem />} />
        
        <Route path='*' element={<NotFoundPage />} status={404}/>
      </Routes>
      <Footer />
    </>
  );
}

export default BasicSite;
