import React from 'react';
import ReactWaves from '@dschoon/react-waves';
import './botSoundTest.css'


export default class WaveForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      wavesurfer: null,
      playing: false,
      pos: 0,
    };
  }

  onLoading = ({ wavesurfer, originalArgs=[] }) => {
    this.setState({ loaded: originalArgs[0] === 100, wavesurfer });
  };

  onPosChange = (pos, wavesurfer) => {
    if (pos !== this.state.pos) {
      this.setState({ pos, wavesurfer });
    }
  };

 

  render () {
    return (
      <div onClick={this.props.setOnClick} className='waveForm'>
        <div onClick={() => { this.setState({ playing: !this.state.playing }) }} className='wavePlay'>
            {!this.state.playing?
                <img src="/static/img/bot/play.png" alt="" />:
                <img src="/static/img/bot/pause.png" alt="" />
            }
        </div>
        <ReactWaves
          audioFile={this.props.audio}
          options={{
            barGap: 2,
            barWidth: 2,
            barHeight: 0,
            cursorWidth: 0,
            height: 60,
            hideScrollbar: true,
            progressColor: '#F7D81A',
            normalize: true,
            responsive: true,
            waveColor: '#D1D6DA',
          }}
          volume={1}
          zoom={0}
          pos={this.state.pos}
          playing={this.state.playing}
          onPosChange={this.onPosChange}
          onLoading={this.onLoading}          
        />        
      </div>
    )
  }
}