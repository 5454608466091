import React, { useContext, useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { UserContext } from '../../../App'
import './profileMailing.css'


const ProfileMailing = () => {
  const user = useContext(UserContext)

  const [payBlock, setPayBlock] = useState(false)

  function openPayBlock() {
    if (payBlock) {
      setPayBlock(false)
      return
    }
    setPayBlock(true)
  }

  function createMailing() {
    axios.post('/api/mailing', {
      print: 'print',
    })
  }
  function payFunc(service) {
    if (service === 'month') {
      axios.get('/api/payment')
        .then(response => {
          window.location.replace(response.data)
        })
      }else if (service === 'demo') {
        axios.get('/api/demo_payment')
          .then(response => {
            window.location.replace(response.data)
          })        
    }{}
  }
  return (
    <div className='profileMailing'>
      {/* {payBlock ?
        <>
          <div className='payBlock'>
            <p>Способы оплаты</p>
            <div className='methodsDiv'>
            <div onClick={() => payFunc('yandex')} className='methods'>
              <img src="/static/img/cabinet/qr_pay.png" alt="" />
              <p>Оплата QR кодом</p>
            </div>
            <div onClick={() => payFunc('intellectmoney')} className='methods'>
              <img src="/static/img/cabinet/card_pay.png" alt="" />
              <p>Оплата банковской картой</p>
            </div>
            </div>
          </div>
          <div onClick={() => setPayBlock(false)} className='payBlockFon'></div>
        </>
        :
        <></>
      } */}
      <div className="selfMailingItem telegramItem">
        <div className='selfMailingInfo'>
          <div className="selfMailingInfoText">
            <p className='selfMailingInfoHeader'>telegram</p>
            <span className="selfMailingInfoSpan">
              <span>- Сбор базы из чатов групп</span>
              <span>- Добавляйте аккаунты в чаты (инвайтинг)</span>
              <span>- Отправляйте текст, видео, картинки, посты, аудио</span>
              <span>- Высокая скорость работы </span>
              <span>- Работайте сразу с нескольких аккаунтов</span>
              <span>- Подробная статистика </span>
              <span>- Техподдержка</span>
              <span>- Запуск с любого устройства</span>
              <span>- Не нужно контролировать работу</span>
              <span>- Беспатное обучение </span>
              <span>- Подробно расскажем где покупать аккаунты</span>
              <span>- Без скачивания и установки</span>
            </span>
          </div>
          <div className='selfMailingInfoButtons'>
            <p>Три вида в одной лицензии</p>
            <div className='selfMailingInfoButtonDiv'>
              <div>
                <img src="/static/img/cabinet/mailing_ico.png" alt="" />
                <p>Рассылка <br /> сообщений</p>
              </div>
              <div>
                <img src="/static/img/cabinet/invite_ico.png" alt="" />
                <p>Инвайтинг</p>
              </div>
              <div>
                <img src="/static/img/cabinet/parser_ico.png" alt="" />
                <p>Парсер</p>
              </div>
            </div>
          </div>
        </div>

        <div className='selfMailingRigth'>
          <div className="selfMailingInfoImg">
            <img src="/static/img/tg3d.png" alt="" />
            <div className='selfMailingInfoMessage'>
              <h3>1 месяц</h3>
              <p>Доступа к лицензии</p>
              <span>Без лимитов  и ограничений</span>

            </div>
          </div>
          <div className='selfMailingButtons'>
            {user['tarif'] ?
              <>
                <span>
                  <b>До окончании тарифа: </b>
                  <br />
                  {user['tarif_text']}
                </span>
                <Link to={'/cabinet/selfmailing/activate'}>
                  <button>Открыть</button>
                </Link>
              </>
              :
              <>
                <p>Стоимость: <b> 1900₽</b> <i>3900₽</i></p>
                <button onClick={() => payFunc('month')}>Купить</button>
                <a onClick={() => payFunc('demo')}>
                  <button className='demo_tarif' >Купить софт на 5 часов | 490 ₽</button>
                </a>
                <Link to={'/cabinet/selfmailing/activate'}>
                  <button className='demo' >Посмотреть софт</button>
                </Link>
              </>
            }

          </div>
        </div>
        <div className='selfMailingItemCover'></div>
      </div>
      {/* <div className="selfMailingItem telegramItem"></div> */}
      <div className='comingSoonDiv'>
        <div className="comingSoon">
          <img src="/static/img/icons/waapp.png" alt="" />
          <p>WhatsApp</p>
          <span>Скоро...</span>
        </div>        
      </div>

    </div>
  )
}

export default ProfileMailing