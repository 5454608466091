import axios from 'axios'
import React, { useEffect, useRef, useState, useContext } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useSearchParams } from 'react-router-dom'
import HelpDiv from '../../../elements/helpDiv/helpDiv'
import { UserContext } from '../../../../App'
import '../telegramServices.css'


const MailingTg = () => {
    const [searchParams] = useSearchParams()
    const user = useContext(UserContext)
    const mailingTgLoading = useRef('')
    const loginsRef = useRef('')
    const numbersRef = useRef('')
    const accountsRef = useRef('')
    const proxyDivRef = useRef('')
    const proxyRef = useRef('')
    const mediaContentRef = useRef('')
    const proxyFileRef = useRef('')
    const textRef = useRef('')
    const startRef = useRef('')
    const accountsDivRef = useRef('')
    const numberDivRef = useRef('')
    const loginDivRef = useRef('')
    const postLinkRef = useRef('')
    const mailingTgUTMRef = useRef('')
    const tgMediaContentRef = useRef('')

    const [dataGet, setDataGet] = useState(false)
    const [redirectToken, setRedirectToken] = useState('')
    const [text, setText] = useState('')
    const [postLink, setPostLink] = useState('')
    const [usernames, setUsernames] = useState()
    const [usernamesCount, setUsernamesCount] = useState(0)
    const [numbers, setNumbers] = useState('')
    const [numbersCount, setNumbersCount] = useState(0)
    const [sessionsList, setSessionsList] = useState(0)
    const [proxyCount, setProxyCount] = useState(0)
    const [useProxy, setUseProxy] = useState('false')
    const [workingAccouts, setWorkingAccouts] = useState(1)
    const [delay, setDelay] = useState(30)
    const [messageOfAccaunt, setMessageOfAccaunt] = useState(1)
    const [status, setStatus] = useState('setting')
    const [usernemesLog, setUsernemesLog] = useState('')
    const [numbersLog, setNumbersLog] = useState('')
    const [usernemesLogCount, setUsernemesLogCount] = useState('')
    const [numbersLogCount, setNumbersLogCount] = useState('')
    const [logs, setLogs] = useState('')

    const [contentName, setcontentName] = useState(null)
    const [contentError, setContentError] = useState(null)
    const [picture, setPicture] = useState(null)
    const [audio, setAudio] = useState(null)
    const [video, setVideo] = useState(null)
    const [utm, setUtm] = useState(null)
    const utmButton = useRef(null)
    const utmCopy = useRef(null)

    function validURL(str) {
        var regex = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
        if (!regex.test(str)) {
            return false;
        } else {
            return true;
        }
    }
    function changeProxy() {
        if (useProxy === 'false') {
            setUseProxy('true')
        } else {
            setUseProxy('false')
        }
    }

    function startMailing() {
        if (sessionsList) {
            textRef.current.classList.remove('tgTextError')
            if (usernames || numbers) {
                numberDivRef.current.classList.remove('tgTextError')
                loginDivRef.current.classList.remove('tgTextError')
                if (text && !postLink || text && postLink || !text && postLink) {
                    accountsDivRef.current.classList.remove('tgTextError')
                    setStatus('start')

                    axios.post('/api/telegram_mailing/' + searchParams.get("id") + '/start', {
                        'text': text,
                        'messagesPerAccount': workingAccouts,
                        'delay': delay,
                        'sessionCount': messageOfAccaunt,
                        'postLink': postLink,
                    }).then(response => {
                        if (response.status == 200) {
                            setVideo('')
                            setAudio('')
                            setPicture('')
                            setcontentName('')
                        }
                    })
                } else {
                    textRef.current.classList.add('tgTextError')
                }
            } else {
                numberDivRef.current.classList.add('tgTextError')
                loginDivRef.current.classList.add('tgTextError')
            }
        } else {
            accountsDivRef.current.classList.add('tgTextError')
        }
    }
    function pauseMailing() {
        axios.post('/api/telegram_mailing/' + searchParams.get("id") + '/pause')
            .then(response => {
                if (response.status == 200) {
                    setVideo('')
                    setAudio('')
                    setPicture('')
                    setcontentName('')
                    setStatus('finish')
                }
            })
    }

    function linkValidate(value) {
        setUtm(value)
    }
    function saveLog() {
        if (logs) {
            const link = document.createElement("a");
            const file = new Blob([logs], { type: 'text/plain' })
            link.href = URL.createObjectURL(file)
            link.download = "logs.txt"
            link.click()
        }
    }
    function saveUsernames() {
        if (usernemesLog) {
            const link = document.createElement("a");
            const file = new Blob([usernemesLog], { type: 'text/plain' })
            link.href = URL.createObjectURL(file)
            link.download = "usernemes.txt"
            link.click()
        }
    }
    function saveNumbers() {
        if (numbersLog) {
            const link = document.createElement("a");
            const file = new Blob([numbersLog], { type: 'text/plain' })
            link.href = URL.createObjectURL(file)
            link.download = "numbers.txt"
            link.click()
        }
    }

    function createUTM() {
        if (validURL(utm)) {
            utmButton.current.disabled = true
            axios.get('/api/utm_link/' + searchParams.get("id"), {
                params: {
                    link: utm
                }
            }).then(response => {
                if (response.status == 200) {
                    setUtm('https://baksbo.ru/link/' + response.data)
                    utmCopy.current.style.display = 'flex'
                    utmButton.current.style.display = 'none'

                }
            })
        }

    }
    function setParams(operation, setFunction, value, name) {
        if (operation === '+') {
            setFunction(+value + 1)
            changeMessageOfAccaunt()
        }
        else if (operation === '-') {
            if (name == 'delay') {
                if (value > 30) {
                    setFunction(+value - 1)
                }
            }
            else {
                if (value > 1) {
                    setFunction(+value - 1)
                }
            }
        }
    }
    function changeDelay() {
        if (delay < 30) {
            setDelay(30)
        }
    }
    function changeMessageOfAccaunt() {
        if (sessionsList) {
            if (messageOfAccaunt >= sessionsList) {
                setMessageOfAccaunt(sessionsList)
            }
        }
    }

    function uploadLogins() {
        loginsRef.current.click()
    }

    function uploadNumbers() {
        numbersRef.current.click()
    }

    function uploadAccaunts() {
        accountsRef.current.click()
    }
    function uploadProxy() {
        proxyRef.current.click()
    }
    function inputTrigger(event) {
        let data = new FormData();
        for (let i = 0; i < event.target.files.length; i++) {
            data.append(event.target.files[i]['name'], event.target.files[i])
        }
        axios.post('/api/telegram_mailing/' + searchParams.get("id") + '/post_sessions', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(response => {
            if (response.status = 200) {
                getTaskData()
            }
        })
    }
    function proxyinputTrigger(event) {
        let data = new FormData();
        for (let i = 0; i < event.target.files.length; i++) {
            data.append(event.target.files[i]['name'], event.target.files[i])
        }
        axios.post('/api/telegram_mailing/' + searchParams.get("id") + '/post_proxy', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(response => {
            if (response.status = 200) {
                getTaskData()
            }
        })
    }
    function useramesTrigger() {
        let logins = loginsRef.current.files[0]
        let numbers = numbersRef.current.files[0]
        let data = new FormData()
        data.append('logins', logins)
        data.append('numbers', numbers)

        axios.post('/api/telegram_mailing/' + searchParams.get("id") + '/post_users', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(response => {
            if (response.status = 200) {
                getTaskData()
            }
        })
    }
    function mediaContentTrigger(event) {
        if (event.target.files[0]) {
            var imgTypes = ['jpg', 'jpeg', 'png', 'gif']
            var audioTypes = ['mp3']
            var videoTypes = ['mp4', 'mov', 'mp4', 'mov', 'wmv', 'avi', 'avchd', 'mkv', 'mpeg-2']

            let file = event.target.files[0]
            var name = event.target.files[0].name
            name = name.split('.')
            setPicture('')
            setAudio('')
            setVideo('')
            setcontentName('')
            setContentError('')

            if (imgTypes.includes(name[+name.length - 1].toLowerCase())) {
                if (file.size < 25000000) {
                    setcontentName(event.target.files[0].name)
                    setPicture(URL.createObjectURL(file))
                } else {
                    setContentError('Слишком большой файл, изображение не может быть больше чем 25MB')
                    return
                }
            }
            else if (audioTypes.includes(name[+name.length - 1])) {
                if (file.size < 30000000) {
                    setAudio(URL.createObjectURL(file))
                    setcontentName(event.target.files[0].name)
                } else {
                    setContentError('Слишком большой файл, аудио не может быть больше чем 30MB')
                    return
                }
            }
            else if (videoTypes.includes(name[+name.length - 1])) {
                if (file.size < 300000000) {
                    setVideo(URL.createObjectURL(file))
                    setcontentName(event.target.files[0].name)
                } else {
                    setContentError('Слишком большой файл, аудио не может быть больше чем 300MB')
                    return
                }
            }

            let data = new FormData();
            data.append('media', file);
            axios.post('/api/telegram_mailing/' + searchParams.get("id") + '/post_media', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then(response => {
                if (response.status = 200) {
                    getTaskData()
                }
            })

        }
    }
    function mediaContentUpload() {
        mediaContentRef.current.click()
    }
    function updateTaskData() {
        if (searchParams.get("id")) {
            axios.post('/api/telegram_mailing/' + searchParams.get("id") + '/update', {
                'text': text,
                'messagesPerAccount': workingAccouts,
                'useProxy': useProxy,
                'delay': delay,
                'sessionCount': messageOfAccaunt,
                'postLink': postLink,
            })
        }
    }
    function getTaskData() {
        if (searchParams.get("id")) {
            axios.get('/api/telegram_mailing/' + searchParams.get("id"))
                .then(response => {
                    if (response.status == '200') {
                        setLogs(response.data.logs)
                        setUsernames(response.data.logins)
                        setNumbers(response.data.numbers)
                        setUsernamesCount(response.data.loginsCount)
                        setNumbersCount(response.data.numbersCount)
                        setSessionsList(response.data.sessionsList)
                        setText(response.data.text)
                        setPostLink(response.data.post_link)
                        setMessageOfAccaunt(response.data.messagesPerAccount)
                        setDelay(response.data.delay)
                        setWorkingAccouts(response.data.sessionCount)
                        setProxyCount(response.data.proxyList)
                        setUseProxy(response.data.use_proxy)
                        setDataGet(true)
                        setStatus(response.data.status)
                        setUsernemesLog(response.data.usernamesLog)
                        setNumbersLog(response.data.numbersLog)
                        setRedirectToken(response.data.redirect);
                        setUsernemesLogCount(response.data.usernamesLogCount)
                        setNumbersLogCount(response.data.numbersLogCount);
                        mailingTgLoading.current.style.display = 'none'
                    }
                })
        }
    }
    useEffect(() => {
        if (searchParams.get("id") == '0') {
            setPicture('/static/img/imgonline-com-ua-Transparent-backgr-YugIiSnntUGUz.png')
        }
        getTaskData()
        setInterval(() => {
            getTaskData()
        }, 10000);
    }, [])

    useEffect(() => {
        if (dataGet) {
            updateTaskData()
        }
    }, [text, workingAccouts, delay, messageOfAccaunt, postLink, useProxy])



    useEffect(() => {
        tgMediaContentRef.current.style.opacity = '1'
        mailingTgUTMRef.current.style.opacity = '1'
        textRef.current.style.opacity = '1'
        if (postLink) {
            if (validURL(postLink)) {
                postLinkRef.current.classList.remove('tgTextError')
                tgMediaContentRef.current.style.opacity = '0.4'
                mailingTgUTMRef.current.style.opacity = '0.4'
                textRef.current.style.opacity = '0.4'
            }
            else {
                postLinkRef.current.classList.add('tgTextError')
            }
        }
        else {
            postLinkRef.current.classList.remove('tgTextError')

        }
    }, [postLink])



    return (
        <div className="mailingTgDiv">
            {!user['tarif'] ?
                <div className='demoDisplay'> </div>
                :
                <></>
            }
            <div className='mailingTgLoadingDiv'>
                <div ref={mailingTgLoading} className='mailingTgLoading'>
                    <img src="/static/img/loading.png" alt="" />
                </div>
            </div>
            <div className='mailingTgBlock1'>
                <fieldset ref={accountsDivRef} className='mailingTgBorder accauntSumm'>
                    <HelpDiv margin={{ top: 5, left: -10 }} text={'Общее количество загруженных аккаунтов для работы.'} />
                    <legend>Количество загруженных аккаунтов</legend>
                    {sessionsList ?
                        <span><i>{sessionsList}</i> Аккаунтов</span>
                        :
                        <span><i>0</i> Аккаунтов</span>
                    }
                    <div className="line"></div>
                    <button onClick={uploadAccaunts}>Загрузить</button>
                    <input ref={accountsRef} type="file" onChange={inputTrigger} accept=".json,.session" multiple />
                </fieldset>
                <div className='mailingTgBorder controlPanel'>
                    {status === 'setting' ?
                        <button ref={startRef} onClick={startMailing}> <img src="/static/img/cabinet/play-solid.svg" alt="" />Запустить</button>
                        :
                        status === 'start' ?
                            <button onClick={pauseMailing} ref={startRef}> <img src="/static/img/cabinet/pause-solid.svg" alt="" /> Остоновить</button>
                            :
                            <button ref={startRef} onClick={startMailing}> <img src="/static/img/cabinet/play-solid.svg" alt="" /> Запустить заново</button>
                    }
                    {/* <div className="line"></div>
                    <button> <img src="/static/img/cabinet/pause-solid.svg" alt="" /> Пауза</button>
                    <div className="line"></div>
                    <button> <img src="/static/img/cabinet/stop-solid.svg" alt="" /> Стоп</button> */}
                </div>
            </div>
            <div className='mailingTgBlock1'>
                    
                <fieldset ref={proxyDivRef} className='mailingTgBorder accauntSumm'>
                    <HelpDiv margin={{ top: 5, left: -10 }} text={'Общее количество загруженных прокси для работы. \n Формат загрузки, каждый прокси с новой строчки: \n Username:password@hostIP:port'} />
                    
                    <legend>Количество загруженных прокси</legend>
                
                    {proxyCount ?
                        <span><i>{proxyCount}</i> Прокси</span>
                        :
                        <span><i>0</i> Прокси</span>
                    }
                    <div className='line'></div>
                    {useProxy === 'false' ?
                        <input onClick={changeProxy} id="useProxy" class="switch-input" type="checkbox" />
                        :
                        <input onClick={changeProxy} checked id="useProxy" class="switch-input" type="checkbox" />
                    }
                    <label for="useProxy" class="switch"></label>

                    <div className='line'></div>

                    <button onClick={uploadProxy}>Загрузить</button>
                    <input ref={proxyRef} type="file" onChange={proxyinputTrigger} accept=".txt" />
                </fieldset>


                <fieldset ref={mailingTgUTMRef} className='mailingTgBorder mailingTgUTMDiv'>
                    <HelpDiv margin={{ top: 5, left: -10 }} text={'Вставьте свою ссылку, чтобы сгенерировать ссылку с UTM меткой для отслеживание статистики на нашем сайте в разделе (Задачи / Статистика).'} />
                    <legend>Отслеживание переходов</legend>
                    {redirectToken ?
                        <>
                            <input value={'https://baksbo.ru/link/' + redirectToken} type="text" placeholder='Введите ссылку для отслеживании' />
                            <CopyToClipboard text={'https://baksbo.ru/link/' + redirectToken}>
                                <img className='actoveUtm' src="/static/img/cabinet/copy-regular.png" alt="" />
                            </CopyToClipboard>
                        </>
                        :
                        <>
                            <input value={utm} onChange={event => linkValidate(event.target.value)} onPaste={event => linkValidate(event.target.value)} type="text" placeholder='Введите ссылку для отслеживании' />
                            <button onClick={createUTM} ref={utmButton}>Создать</button>
                            <CopyToClipboard text={utm}>
                                <img ref={utmCopy} src="/static/img/cabinet/copy-regular.png" alt="" />
                            </CopyToClipboard>
                        </>
                    }
                    <p>Скопировано!</p>
                </fieldset>
            </div>
            <div className='mailingTgBlock2 mailingBlock2'>
                <div className='tgUsersBlock'>

                    <fieldset ref={numberDivRef} className='mailingTgBorder'>
                        <HelpDiv margin={{ top: 0, left: -10 }} text={'Список логинов для рассылки сообщений, добавлять без "@"'} />
                        <legend>Usernames</legend>
                        <textarea value={usernames}></textarea>
                        <span>{usernamesCount}</span>
                        <div className="line"></div>
                        <input onChange={useramesTrigger} ref={loginsRef} type="file" accept='.txt' />
                        <button onClick={uploadLogins}>Загрузить</button>
                    </fieldset>
                    <fieldset ref={loginDivRef} className='mailingTgBorder'>
                        <HelpDiv margin={{ top: 0, left: -10 }} text={'Список номеров для рассылки сообщений, добавлять без "8 и +"'} />

                        <legend>Номера</legend>
                        <textarea value={numbers}></textarea>
                        <span>{numbersCount}</span>
                        <div className="line"></div>
                        <input onChange={useramesTrigger} ref={numbersRef} type="file" accept='.txt' />
                        <button onClick={uploadNumbers}>Загрузить</button>
                    </fieldset>
                </div>
                <div className='mailingTgTextUTM'>
                    {/* --------------------- */}
                    <fieldset ref={textRef} className='mailingTgBorder tgText'>
                        <HelpDiv margin={{ top: 0, left: -10 }} text={'Добавьте текст для рассылки. Максимальное количество без медиафайла до 3000 символов, с медиафайлом до 1000 символов.\nВ тексте можно указать до 2х ссылок на любые ресурсы (сайт, канал, чат и так далее).'} />

                        <legend>Текст</legend>
                        <textarea value={text} onChange={event => setText(event.target.value)} placeholder='Введите свой текст сюуда...' maxlength="3000"></textarea>
                    </fieldset>



                    <fieldset ref={postLinkRef} className='mailingTgBorder mailingTgPostLinkDiv'>
                        <HelpDiv margin={{ top: 5, left: -10 }} text={'Вставьте ссылку на пост из telegram канала или чата.'} />
                        <legend>Ссылка на пост</legend>
                        <input value={postLink} onChange={event => setPostLink(event.target.value)} type="text" placeholder='Введите ссылку на пост' />
                    </fieldset>
                </div>
            </div>
            <div className="mailingTgBlock3">
                <div className='mailingTgBorder tgServicesUsersParams'>
                    <HelpDiv margin={{ top: 10, left: -10 }} text={'Настройка работы аккаунтов и скорости рассылки.'} />
                    <div className='userParam'>
                        <span>Отпровлять сообщений с каждего аккаунта</span>
                        <div className="input">
                            <label onClick={() => setParams('-', setWorkingAccouts, workingAccouts, 'workingAccouts')} className='minusLabel'>-</label>
                            <input min="1" type="number" onChange={event => setWorkingAccouts(Math.abs(event.target.value))} value={workingAccouts} />
                            <label onClick={() => setParams('+', setWorkingAccouts, workingAccouts, 'workingAccouts')} className='plusLabel'>+</label>
                        </div>
                    </div>
                    <div className='userParam'>
                        <span>Задержка между сообщениями (сек.)</span>
                        <div className="input">
                            <label onClick={() => setParams('-', setDelay, delay, 'delay')} className='minusLabel'>-</label>
                            <input min="1" type="number" onChange={event => setDelay(Math.abs(event.target.value))} onBlur={changeDelay} value={delay} />
                            <label onClick={() => setParams('+', setDelay, delay, 'delay')} className='plusLabel'>+</label>
                        </div>
                    </div>
                    <div className='userParam'>
                        <span>Количество работающих аккаунтов</span>
                        <div className="input">
                            <label onClick={() => setParams('-', setMessageOfAccaunt, messageOfAccaunt, 'messageOfAccaunt')} className='minusLabel'>-</label>
                            <input min="1" type="number" onChange={event => setMessageOfAccaunt(Math.abs(event.target.value))} onBlur={changeMessageOfAccaunt} value={messageOfAccaunt} />
                            <label onClick={() => setParams('+', setMessageOfAccaunt, messageOfAccaunt, 'messageOfAccaunt')} className='plusLabel'>+</label>
                        </div>
                    </div>
                </div>
                <fieldset ref={tgMediaContentRef} className='mailingTgBorder tgMediaContent'>
                    <HelpDiv margin={{ top: -30, left: -10 }} text={'Рекомендуемый размер медиафайлов до 20 мб. Размер файла будет влиять на прямую на скорость рассылки.'} />

                    <legend>Загрузка медиа контента</legend>
                    <div className='tgContent'>
                        {video ?
                            <video src={video}>
                            </video>
                            :
                            <></>
                        }
                        {audio ?
                            <a target='_blank' href={audio}><img src="/static/img/cabinet/music_file.png" alt="" /></a>
                            :
                            <></>
                        }
                        {picture ?
                            <img src={picture} alt="" />
                            :
                            <></>
                        }
                        {contentName ?
                            <span>{contentName}</span>
                            :
                            <></>
                        }
                        {contentError ?
                            <span className='contentError'>{contentError}</span>
                            :
                            <></>
                        }
                        {!video && !audio && !picture && !contentName && !contentError ?
                            <p>Загрузите медиа изображение/видео/аудио нажав кнопку "Загрузить".</p>
                            :
                            <></>
                        }
                    </div>
                    <div className="line"></div>
                    <div className='tgContentUploadDiv'>
                        <input ref={mediaContentRef} onChange={mediaContentTrigger} type="file" accept={'.mp4,.mov,.wmv,.avi,.avchd,.mkv,.mpeg-2,.mp3,.jpg,.jpeg,.png,.gif'} />
                        <button onClick={mediaContentUpload}>Загрузить</button>
                    </div>

                </fieldset>
            </div >
            <div className='mailingTgBlockInfo'>
                <div></div>
                <p>Информация</p>
                <div></div>
            </div>
            <div className='mailingTgBlock4'>
                <fieldset className='mailingTgBorder tgLog'>
                    <legend>Лог работы</legend>
                    <textarea value={logs} ></textarea>
                    <div className="line"></div>
                    <button onClick={saveLog}>Скачать</button>
                </fieldset>
                <div className='tgUsersBlock'>
                    <fieldset className='mailingTgBorder'>
                        <legend>Usernames</legend>
                        <textarea value={usernemesLog} ></textarea>
                        <span>{usernemesLogCount}</span>
                        <div className="line"></div>
                        <button onClick={saveUsernames}>Скачать</button>
                    </fieldset>
                    <fieldset className='mailingTgBorder'>
                        <legend>Номера</legend>
                        <textarea value={numbersLog}></textarea>
                        <span>{numbersLogCount}</span>
                        <div className="line"></div>
                        <button onClick={saveNumbers}>Скачать</button>
                    </fieldset>
                </div>
            </div>
        </div >
    )
}

export default MailingTg