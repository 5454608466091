import React, { useState, useEffect, useRef, useContext } from 'react'
import './botCabinet.css'
import axios from 'axios'
import { UserContext } from '../../../App'

const BotCabinet = () => {
  const popUpInput = useRef(null)
  const popUpButton = useRef(null)


  const user = useContext(UserContext)
  const [balance, setBalance] = useState('0')
  const [popUp, setPopUp] = useState('')
  const [history, setHistory] = useState([])
  // const [history, setHistory] = useState([{"id":125,"summ":"1001","status":"payed","method":"Карта","comment":"Пополнение баланса: 1001 руб.","date":"00:52 10.04.2023"},{"id":124,"summ":"6666","status":"payed","method":"Карта","comment":"Пополнение баланса: 6666 руб.","date":"00:37 10.04.2023"},{"id":123,"summ":"6004","status":"payed","method":"Карта","comment":"Пополнение баланса: 6004 руб.","date":"00:50 10.04.2023"}])

  function payFunc() {
    if (!popUp) {
      popUpInput.current.classList.add('wrongPopup')
      popUpButton.current.disabled = true
    }
    else {
      popUpInput.current.classList.add('wrongPopup')
      popUpButton.current.disabled = true
      axios.get('/api/bot_payment/' + popUp)
        .then(response => {
          window.location.replace(response.data)
          popUpInput.current.classList.remove('wrongPopup')
          popUpButton.current.disabled = false
        })
    }

  }

  useEffect(() => {
    if ((popUp && popUp < 1000)) {
      popUpInput.current.classList.add('wrongPopup')
      popUpButton.current.disabled = true
      return
    }
    popUpInput.current.classList.remove('wrongPopup')
    popUpButton.current.disabled = false

  }, [popUp])

  useEffect(() => {
    axios.get('/api/bot')
      .then(response => {
        setHistory(response.data.history);
        setBalance(response.data.balance);
      })
  }, [])

  return (
    <>
      <div className='botCabinetDiv'>
        <div className='botCabinetInfo'>
          {user['bot'] ?
            <></>
            :
            <div className='paymentHidediv'>
              Недоступно!
            </div>
          }
          <>
            <div className='botCabinetInfoBalanceDiv botCabinetInfoBalanceInfoDiv'>
              <fieldset className='botCabinetInfoBalance'>
                <legend>Баланс</legend>
                <p>{balance} ₽</p>
              </fieldset>
            </div>

            <div className='botCabinetInfoBalanceDiv'>
              <fieldset className='botCabinetInfoBalance'>
                <legend>Пополнить баланс</legend>
                <input ref={popUpInput} onChange={event => setPopUp(event.target.value)} value={popUp} type="number" placeholder='Пополнить (мин. 1000 ₽)' />
              </fieldset>
              <div className='botCabinetBalanceInput'>
                <button ref={popUpButton} onClick={payFunc}>
                  <p>Оплатить</p>
                  <img src="/static/img/cabinet/popUp.png" alt="" />
                </button>
              </div>
            </div>

            <fieldset className='botCabinetInfoHistoryDiv'>
              <legend>История пополнения</legend>
              {history[0] ?
                history.map(item => {
                  return (
                    <div key={item['id']} className='botCabinetHistory'>
                      <p><span>Сумма: </span> {item['summ']} руб.</p>
                      <p><span>Статус: </span> Оплачен</p>
                      <p><span>Метод: </span> {item['method']}</p>
                      <p><span>Дата: </span> {item['date']}</p>
                    </div>
                  )
                })
                : <h4>Пусто</h4>}
            </fieldset>
          </>

        </div>
      </div>
    </>
  )
}

export default BotCabinet