import React, { useEffect, useState } from 'react'
import './blogItem.css'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const BlogItem = () => {
    const { id } = useParams();
    const navigate = useNavigate();


    const getBlog = () => {
        axios.get(`/api/blog/slug/${id}`)
            .then(request => {
                setData(request.data)
            }).catch(error => {
                if (error.request.status == 404) {
                    navigate('/blog')
                }

            }
            )
    }

    const [data, setData] = useState(null)

    useEffect(() => {
        getBlog()
    }, [])


    return (

        <div className='blogItemDiv'>
            {data &&
                <>
                    <Helmet>
                        <title>{data.title}</title>
                        <meta name="description" content={data.description}></meta>
                        <meta name="keywords" content={data.keywords}></meta>
                        <link rel="canonical" href={`https://baksbo.ru/blog/${data.slug}`} />
                    </Helmet>
                    <h1>{data.header}</h1>
                    <hr />
                    {data.image && <img src={data.image} alt="" />}
                    <div dangerouslySetInnerHTML={{ __html: data.text }}></div>
                </>
            }

        </div>

    )
}

export default BlogItem