import "./contact.css"
import MetaTags from 'react-meta-tags';

export default function Contacts() {
    return (
        <>
            <MetaTags>
                <title>Сервис массовой рассылки сообщений Baksbo – контакты</title>
                <meta name="description" content="Телефон сервиса массовой рассылки сообщений Baksbo: 8 (920) 966-05-91. Адрес e-mail: baksbo.ru@mail.ru. Telegram: @baksbo_ru. Instagram: @baksbo.ru. "></meta>
                <meta name="keywords" content="контакты baksbo ru, массовая рассылка сообщений контакты, сервис массовой рассылки сообщений телефон, сервис массовой рассылки сообщений email."></meta>
                <meta property="og:image:secure" content="/static/img/icons/contact.png"></meta>
            </MetaTags>
            <div className="contactFon"></div>
            <div className="contactDiv">
                <div className="contactSocial">
                    <div className="contactLine"></div>
                    <div className='contactLinks'>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/baksbo.ru/">
                            <img src="static/img/icons/insta.png" alt="" />
                        </a>

                        <a target="_blank" rel="noopener noreferrer" href="https://t.me/baksbo_ru">
                            <img src="static/img/icons/tg.png" alt="" />
                        </a>

                        <a target="_blank" rel="noopener noreferrer" href="https://wa.me/+789209660591">
                            <img src="static/img/icons/waapp.png" alt="" />
                        </a>

                        <a target="_blank" rel="noopener noreferrer" href="https://vk.com/baksbo_ru">
                            <img src="static/img/icons/vk.png" alt="" />

                        </a>
                    </div>
                    <div className="contactLine"></div>
                </div>
                <div className="contactInfo">
                    <h2>Контакты</h2>
                    <div><p>Телефон</p><span>8 (920) 966-05-91</span></div>
                    <div><p>WhatsApp</p><span>8 (920) 966-05-91</span></div>
                    <div><p>Telegram</p><span>@baksbo_ru</span></div>
                    <div><p>Почта</p><span>baksbo.ru@mail.ru</span></div>
                    <div><p>Instagram</p><span>@baksbo.ru</span></div>

                </div>
            </div>
        </>
    )
}