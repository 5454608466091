import React, { useEffect, useRef, useState } from 'react'
import InputMask from 'react-input-mask'
import axios from 'axios'
import './usersBase.css'
import { Link } from 'react-router-dom'

const UsersBase = () => {
    const downloadRef = useRef(null)

    const [search, setSearch] = useState('')
    const [dataCount, setDataCount] = useState(null)
    const [next, setNext] = useState('asdasd')
    const [previous, setPrevious] = useState('asdasd')
    const [startDate, setStartDate] = useState([])
    const [endDate, setEndDate] = useState([])
    const [tarif, setTarif] = useState('')
    const [deleteItem, setDeleteItem] = useState(null)
    const [downlaod, setDownlaod] = useState(null)
    const [data, setData] = useState([])
    // const [data, setData] = useState([
    //     {
    //         "id": 442,
    //         "username": "bar.aleksej.v@gmail.com",
    //         "name": "Алексей",
    //         "number": "79990009885",
    //         "verified_mail": true,
    //         "tarif_date": null,
    //         "last_login": "2023-08-15T00:35:30.957953+03:00",
    //         "date_joined": "2023-08-15T00:35:12.542420+03:00"
    //     },
    //     {
    //         "id": 441,
    //         "username": "nnjob1120@gmail.com",
    //         "name": "Nina",
    //         "number": "79190817577",
    //         "verified_mail": true,
    //         "tarif_date": "2023-08-15T02:10:01.043588+03:00",
    //         "last_login": "2023-08-14T16:08:39.208651+03:00",
    //         "date_joined": "2023-08-14T16:08:28.579878+03:00"
    //     },
    //     {
    //         "id": 440,
    //         "username": "incruiseslove@gmail.com",
    //         "name": "Анастасия",
    //         "number": "79114617940",
    //         "verified_mail": true,
    //         "tarif_date": null,
    //         "last_login": "2023-08-13T13:44:06.559331+03:00",
    //         "date_joined": "2023-08-13T13:43:53.425089+03:00"
    //     },
    //     {
    //         "id": 439,
    //         "username": "outstanding00@mail.ru",
    //         "name": "Ян",
    //         "number": "79992165307",
    //         "verified_mail": true,
    //         "tarif_date": null,
    //         "last_login": "2023-08-09T18:55:35.042522+03:00",
    //         "date_joined": "2023-08-09T18:55:19.829686+03:00"
    //     },
    //     {
    //         "id": 438,
    //         "username": "Johnypyatochkax@gmail.com",
    //         "name": "Михаил",
    //         "number": "79512747290",
    //         "verified_mail": true,
    //         "tarif_date": null,
    //         "last_login": "2023-08-09T10:42:33.306600+03:00",
    //         "date_joined": "2023-08-09T10:42:03.283694+03:00"
    //     },
    //     {
    //         "id": 437,
    //         "username": "ivchenko.vladislav@internet.ru",
    //         "name": "Влад",
    //         "number": "79500034567",
    //         "verified_mail": true,
    //         "tarif_date": null,
    //         "last_login": "2023-07-31T18:38:05.340044+03:00",
    //         "date_joined": "2023-07-31T18:37:52.712626+03:00"
    //     },
    //     {
    //         "id": 436,
    //         "username": "denlukanov012@gmail.com",
    //         "name": "Денис",
    //         "number": "79017858373",
    //         "verified_mail": true,
    //         "tarif_date": null,
    //         "last_login": "2023-07-31T16:55:34.290200+03:00",
    //         "date_joined": "2023-07-31T16:55:16.761052+03:00"
    //     },
    //     {
    //         "id": 435,
    //         "username": "fonzosever058@gmail.com",
    //         "name": "Саша",
    //         "number": "79026728931",
    //         "verified_mail": true,
    //         "tarif_date": null,
    //         "last_login": "2023-07-29T16:02:01.208490+03:00",
    //         "date_joined": "2023-07-29T16:01:35.169165+03:00"
    //     },
    //     {
    //         "id": 434,
    //         "username": "solovevvalerij0@gmail.com",
    //         "name": "Валерий",
    //         "number": "79032486833",
    //         "verified_mail": true,
    //         "tarif_date": null,
    //         "last_login": "2023-07-28T16:53:56.904597+03:00",
    //         "date_joined": "2023-07-28T16:53:28.934694+03:00"
    //     },
    //     {
    //         "id": 433,
    //         "username": "otinoff@gmail.com",
    //         "name": "Андрей",
    //         "number": "79515841083",
    //         "verified_mail": true,
    //         "tarif_date": null,
    //         "last_login": "2023-07-27T06:16:21.442671+03:00",
    //         "date_joined": "2023-07-27T06:15:19.588318+03:00"
    //     },
    //     {
    //         "id": 432,
    //         "username": "sanya-rus44yo@mail.ru",
    //         "name": "Александр",
    //         "number": "79108083579",
    //         "verified_mail": true,
    //         "tarif_date": null,
    //         "last_login": "2023-07-27T00:16:57.824500+03:00",
    //         "date_joined": "2023-07-27T00:16:39.405919+03:00"
    //     }])

    function getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }
    const handleInputChange = (index, field, value, id) => {
        changeElement(id, field, value)
        const newData = [...data]
        newData[index][field] = value
        setData(newData)
    }

    const changeElement = (id, field, value) => {
        const csrftoken = getCookie('csrftoken')
        axios.patch(`/api/users_base/${id}`, {
            field: field,
            id: id,
            value: value,
        }, {
            headers: {
                'X-CSRFToken': csrftoken,
            },
        }
        ).then(response => {
            console.log(response.data)
        })
    }

    function formatDate(date) {
        const months = [
            'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
            'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
        ];

        const hours = date.getHours().toString().padStart(2, '0')
        const minutes = date.getMinutes().toString().padStart(2, '0')
        const day = date.getDate().toString().padStart(2, '0')
        const month = months[date.getMonth()]
        const year = date.getFullYear()

        return `${day} ${month} ${year} г. ${hours}:${minutes}`
    }

    const deleteElement = (id) => {
        const csrftoken = getCookie('csrftoken')
        axios.delete(`/api/base/${id}`,
            {
                headers: {
                    'X-CSRFToken': csrftoken,
                },
            }
        ).then(response => {
            getDate('', search)
            setDeleteItem(null)
        })

    }


    const getDate = (url = '', q = '') => {
        if (url === '') {
            url = `/api/users_base?q=${q}&end_date=${endDate}&start_date=${startDate}&tarif=${tarif}`
        }
        axios.get(url)
            .then(response => {
                setData(response.data.results)
                setNext(response.data.next)
                setPrevious(response.data.previous)
                setDataCount(response.data.count)
            })
    }
    function download(params) {
        downloadRef.current.style.opacity = '0.3'
        axios.get('/api/download')
            .then(response => {
                if (response.data === 'success') {
                    downloadRef.current.style.opacity = '1'
                    setDownlaod(true)
                }
            })
    }

    useEffect(() => {
        getDate('', search)
    }, [search, startDate, endDate,tarif])


    return (
        <div className='crmBaseDiv'>
            {deleteItem &&
                <>
                    <div className='crmDeleteItem'>
                        <p>Удалить элемеент?</p>
                        <p onClick={() => deleteElement(deleteItem)}>Да</p>
                        <p onClick={() => setDeleteItem(null)}>Отмена</p>
                    </div>
                    <div onClick={() => setDeleteItem(null)} className="crmDeleteItemFon"></div>
                </>
            }
            <div className='crmBaseFilter'>
                <input type="text" placeholder={dataCount ? `Поиск из ${dataCount} контатов` : 'Поиск'} value={search} onChange={(e) => setSearch(e.target.value)} />

                <div className='crmBaseFilterDiv'>
                    <p>Дата:</p>
                    <span>От <input value={startDate} onChange={e => setStartDate(e.target.value)} type="date" /></span>
                    <span>До <input value={endDate} onChange={e => setEndDate(e.target.value)} type="date" /></span>

                </div>
                <div className='crmBaseFilterDiv crmBaseCheckFilterDiv'>
                    <p>Тариф:</p>
                    <input value={tarif} onChange={e => setTarif(e.target.checked)} type="checkbox" />
                </div>

                <div className="crmBaseNavigation">
                    {previous && <p onClick={() => getDate(previous, search)}>←</p>}
                    {next && <p onClick={() => getDate(next, search)}>→</p>}
                </div>

                <div className="crmBaseFilterDiv">
                    {!downlaod ?
                        <a ref={downloadRef} onClick={download} ><p>Подготовить данные</p></a> 
                        :
                        <Link ref={downloadRef} to={'/static/media/CRM_users.xlsx'} target='_blank' download>Скачать</Link>
                    }
                </div>
                
            </div>
            <div className='crmBaseContainer'>

                <div className='crmBaseHeader crmUserBaseHeader'>
                    <p>Дата регистрации</p> {/*   date_joined     */}
                    <p>Верификация</p> {/*   verified_mail     */}
                    <p>Имя</p> {/*   name     */}
                    <p>Номер</p> {/*    number    */}
                    <p>Email</p> {/*   username     */}
                    <p>Тариф</p> {/*   tarif_date     */}
                    <p>Дата входа</p> {/*   last_login     */}
                </div>
                {data && data.map((item, index) => (
                    <div className='crmBase crmUserBase' key={index}>

                        <div className='crmBaseInputDiv'>
                            <span><span className='crmBaseInputMobiletag'>Дата регистрации:</span>{formatDate(new Date(item.date_joined))}</span>
                        </div>
                        <div className='crmBaseInputDiv'>
                            <p>

                            </p>
                            {item.verified_mail ?
                                <span><span className='crmBaseInputMobiletag'>Верификация:</span>✅</span> :
                                <span><span className='crmBaseInputMobiletag'>Верификация:</span>❌</span>}
                        </div>

                        <div className='crmBaseInputDiv'>
                            <input placeholder='Имя' type="text" value={item.name} onChange={(e) => handleInputChange(index, 'name', e.target.value, item.id)}/>
                        </div>
                        <div className='crmBaseInputDiv'>
                            <InputMask maskChar=" " mask="9 (999) 999-99-99" placeholder='Номер' type="text" value={item.number} onChange={(e) => handleInputChange(index, 'number', e.target.value, item.id)}/>
                        </div>

                        <div className='crmBaseInputDiv'>
                            <input placeholder='Email' type="text" value={item.username} />
                        </div>

                        <div className='crmBaseInputDiv'>
                            {item.tarif_date ? <span> <span className='crmBaseInputMobiletag'>Тариф:</span> {formatDate(new Date(item.tarif_date))}</span>
                                : <span><span className='crmBaseInputMobiletag'>Тариф:</span>❌</span>
                            }
                        </div>
                        <div className='crmBaseInputDiv'>
                            <span><span className='crmBaseInputMobiletag'>Дата входа:</span>{formatDate(new Date(item.last_login))}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default UsersBase