import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { AreaChart, Area, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ComposedChart, Bar } from 'recharts';
import { useSearchParams } from 'react-router-dom';
import './statistic.css'


const Statistic = () => {
  const [searchParams] = useSearchParams()

  const [send, setSend] = useState({})
  const [delivery, setDelivery] = useState({})
  const [error, setError] = useState({})
  const [redirect, setRedirect] = useState({})
  const [counts, setCounts] = useState({})
  const [sendCounts, setSendCounts] = useState(0)
  const [delDveryCounts, setDeliveryCounts] = useState(0)
  const [redirectCounts, setRedirectCounts] = useState(0)
  const [errorCounts, setErrorCounts] = useState(0)
  const mailingTgLoading = useRef('')


  const [time, setTime] = useState('00')
  const sendData = useRef()
  const deliveryData = useRef()
  const transitionData = useRef()
  const errorData = useRef()
  const sendButton = useRef()
  const deliveryButton = useRef()
  const transitionButton = useRef()
  const errorButton = useRef()


  function switchGraph(event) {
    sendButton.current.style.color = 'white'
    deliveryButton.current.style.color = 'white'
    transitionButton.current.style.color = 'white'
    errorButton.current.style.color = 'white'
    sendData.current.style.display = 'none'
    deliveryData.current.style.display = 'none'
    transitionData.current.style.display = 'none'
    errorData.current.style.display = 'none'
    if (event.target.innerHTML === 'Отправлено') {
      sendButton.current.style.color = '#F7D81A'
      sendData.current.style.display = 'flex'
    }
    else if (event.target.innerHTML === 'Доставлено') {
      deliveryButton.current.style.color = '#F7D81A'
      deliveryData.current.style.display = 'flex'
    }
    else if (event.target.innerHTML === 'Переходы') {
      transitionButton.current.style.color = '#F7D81A'
      transitionData.current.style.display = 'flex'
    }
    else {
      errorButton.current.style.color = '#F7D81A'
      errorData.current.style.display = 'flex'
    }
  }

  useEffect(() => {
    if (searchParams.get("id")) {
      axios.get('/api/statistic/' + searchParams.get("id"))
        .then(response => {
          setSend(response.data.send)
          setDelivery(response.data.delivery)
          setError(response.data.error)
          setRedirect(response.data.redirect)
          setSendCounts(response.data.sendCount)
          setDeliveryCounts(response.data.deliveryCount)
          setRedirectCounts(response.data.redirectCount)
          setErrorCounts(response.data.errorCount)
          setTime(response.data.time)
          setCounts([{
            name: ' ',
            Отправлено: response.data.sendCount,
            Доставлено: response.data.deliveryCount,
            Ошибки: response.data.errorCount,
            Переходы: response.data.redirectCount,
          }])

          mailingTgLoading.current.style.display = 'none'          
          sendButton.current.style.color = '#F7D81A'
        })
    }
  }, [])




  var tetsmap = [1, 2, 3, 1, 2, 3, 1, 2, 3, 1]
  return (
    <div className='statisticDiv'>
      <div className='mailingTgLoadingDiv'>
        <div ref={mailingTgLoading} className='mailingTgLoading'>
          <img src="/static/img/loading.png" alt="" />
        </div>
      </div>
      <div className='statisticKaseDiv'>
        <div className="statisticKase">
          <p> <img src="/static/img/cabinet/send.png" alt="" /> Отправлено</p>
          <b>{sendCounts}</b>
          <span>сообщений</span>
        </div>
        <div className="statisticKase">
          <p> <img src="/static/img/cabinet/delivery.png" alt="" /> Доставлено</p>
          <b>{delDveryCounts}</b>
          <span>сообщений</span>
        </div>
        <div className="statisticKase">
          <p> <img src="/static/img/cabinet/error.png" alt="" /> Ошибок</p>
          <b>{errorCounts}</b>
          <span>сообщений</span>
        </div>
        {/* <div className="statisticKase">
          <p> <img src="/static/img/cabinet/read.png" alt="" /> Прочитано</p>
          <b>254</b>
          <span>сообщений</span>
        </div> */}
        <div className="statisticKase">
          <p> <img src="/static/img/cabinet/transition.png" alt="" /> Переходы</p>
          <b>{redirectCounts}</b>
          <span>сообщений</span>
        </div>
      </div>
      <div className='statisticGraphicDiv'>
        <div className='statisticGraphicControl'>
          <p onClick={e => switchGraph(e)} ref={sendButton}>Отправлено</p>
          <p onClick={e => switchGraph(e)} ref={deliveryButton}>Доставлено</p>
          <p onClick={e => switchGraph(e)} ref={transitionButton}>Переходы</p>
          <p onClick={e => switchGraph(e)} ref={errorButton}>Ошибки</p>
        </div>
        <div ref={sendData} className='statisticGraphic statisticGraphicSend'>
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart width={500} height={300} data={send} margin={{ top: 5, right: 30, left: 20, bottom: 5, }}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#F7D81A" stopOpacity={0.3} />
                  <stop offset="95%" stopColor="#F7D81A" stopOpacity={0.01} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="10 0" />
              <XAxis dataKey="name" />
              <Tooltip />
              <Area type="monotone" dataKey="Отправлено" stroke="#F7D81A" fill="url(#colorUv)" />
            </AreaChart>
          </ResponsiveContainer>
        </div>
        <div ref={deliveryData} className='statisticGraphic statisticGraphicDelivery'>
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart width={500} height={300} data={delivery} margin={{ top: 5, right: 30, left: 20, bottom: 5, }}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#F7D81A" stopOpacity={0.3} />
                  <stop offset="95%" stopColor="#F7D81A" stopOpacity={0.01} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="10 0" />
              <XAxis dataKey="name" />
              <Tooltip />
              <Area type="monotone" dataKey="Доставлено" stroke="#F7D81A" fill="url(#colorUv)" />
            </AreaChart>
          </ResponsiveContainer>
        </div>
        <div ref={transitionData} className='statisticGraphic statisticGraphicTransition'>
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart width={500} height={300} data={redirect} margin={{ top: 5, right: 30, left: 20, bottom: 5, }}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#F7D81A" stopOpacity={0.3} />
                  <stop offset="95%" stopColor="#F7D81A" stopOpacity={0.01} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="10 0" />
              <XAxis dataKey="name" />
              <Tooltip />
              <Area type="monotone" dataKey="Переходы" stroke="#F7D81A" fill="url(#colorUv)" />
            </AreaChart>
          </ResponsiveContainer>
        </div>
        <div ref={errorData} className='statisticGraphic statisticGraphicErrors'>
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart width={500} height={300} data={error} margin={{ top: 5, right: 30, left: 20, bottom: 5, }}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#F7D81A" stopOpacity={0.3} />
                  <stop offset="95%" stopColor="#F7D81A" stopOpacity={0.01} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="10 0" />
              <XAxis dataKey="name" />
              <Tooltip />
              <Area type="monotone" dataKey="Ошибки" stroke="#F7D81A" fill="url(#colorUv)" />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className='diagramDiv'>
        <div className='taskDiagramDiv'>
          <div className='taskDiagram'>
            <div className='diagramYAxis'>
              <div><img src="/static/img/cabinet/send.png" alt="" /></div>
              <div><img src="/static/img/cabinet/delivery.png" alt="" /></div>
              <div><img src="/static/img/cabinet/transition.png" alt="" /></div>
              <div><img src="/static/img/cabinet/error.png" alt="" /></div>
            </div>
            <div className='diagram'>
              <ResponsiveContainer>
                <ComposedChart
                  layout="vertical"
                  data={counts}
                  barGap="15"
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                >
                  <CartesianGrid stroke="#f5f5f5" />
                  <XAxis type="number" />
                  <YAxis dataKey="name" type="category" scale="band" />
                  <Tooltip />
                  <Bar dataKey="Отправлено" barSize={32} fill="#0EABD5" radius={[0, 20, 20, 0]} />
                  <Bar dataKey="Доставлено" barSize={32} fill="#F1E56C" radius={[0, 20, 20, 0]} />
                  <Bar dataKey="Переходы" barSize={32} fill="#26B344" radius={[0, 20, 20, 0]} />
                  <Bar dataKey="Ошибки" barSize={32} fill="#C41617" radius={[0, 20, 20, 0]} />
                </ComposedChart>
              </ResponsiveContainer>
              <div className='diagramClassificator'>
                <div><div></div> <p>Отправлено</p></div>
                <div><div></div> <p>Отправлено</p></div>
                <div><div></div> <p>Отправлено</p></div>
                <div><div></div> <p>Отправлено</p></div>
              </div>
            </div>
          </div>
          <div className='taskTimerDiv'>
            <div className='timerDiv timer'>
              <p>Таймер</p>
              {/* <h4>1:30</h4> */}
              {time ?
                <h4>{time}</h4>
                :
                <h3></h3>
              }
              <span>длительность рассылки</span>
              <img src="/static/img/cabinet/timerElipse.png" alt="" />
            </div>
            <div className='timerDiv errorTimer'>
              <p>Ошибки</p>
              <h4>{errorCounts}</h4>
              <span>не доставленных сообщений</span>
              <img src="/static/img/cabinet/errorElipse.png" alt="" />
            </div>
          </div>
        </div>
        {/* <div className='taskAnswers'>
          <h4>Ответы</h4>
          {tetsmap.map(i => {
            return (
              <div className="answer">
                <p>Username</p>
                <span>Lorem, ipsum dolor sit amet consectetur adipisicing elit.Lorem, ipsum dolor sit</span>
              </div>

            )
          })}
        </div> */}
      </div>
    </div>
  )
}

export default Statistic