import { Route, Routes } from 'react-router-dom'
import NotFoundPage from '../../page/notFound/notFound'
import Download from '../page/download/download'
import Mailing from '../page/mailing/mailing'
import MyMailing from '../page/myMailing/myMailing'
import Payment from '../page/payment/payment'
import Profile from '../page/profile/profile'
import ProfileMailing from '../page/profileMailing/profileMailing'
import Referral from '../page/referal/referal'
import Statistic from '../page/statistic/statistic'
import Tasks from '../page/statistic/tasks'
import Inviting from '../page/telegramServices/invitingTg/inviting'
import MailingTg from '../page/telegramServices/mailingTg/mailingTg'
import ParserTg from '../page/telegramServices/parserTg/parserTg'
import TelegramServices from '../page/telegramServices/telegramServices'
import Training from '../page/training/training'
import BotCabinet from '../page/bot/botCabinet'
import BotDevelopment from '../page/bot/botDevelopment/botDevelopment'
import BotTraining from '../page/bot/botTraining/botTraining'
import './cabinet.css'


export default function Cabinet() {
    return (
        <>
            <Routes>
                <Route path='/' element={<ProfileMailing />} />
                <Route path='/selfmailing' element={<ProfileMailing />} />
                <Route path='/payment' element={<Payment />} />
                <Route path='/telegram_mailing' element={<MailingTg />} />
                <Route path='/telegram_inviting' element={<Inviting />} />
                <Route path='/telegram_parser' element={<ParserTg />} />
                <Route path='/selfmailing/activate' element={<TelegramServices />} />
                <Route path='/mailing/*' element={<Mailing />} />
                <Route path='/my_mailing' element={<MyMailing />} />
                <Route path='/statistic' element={<Statistic />} />
                <Route path='/task' element={<Tasks />} />
                <Route path='/referral' element={<Referral />} />
                <Route path='/training' element={<Training />} />
                <Route path='/bot/pay' element={<BotCabinet />} />
                <Route path='/bot/development' element={<BotDevelopment />} />
                <Route path='/bot/learning' element={<BotTraining />} />
                <Route path='/profile' element={<Profile />} />
                <Route path='/download' element={<Download />} />
                <Route path='*' status={404} element={<NotFoundPage />} />
            </Routes>
        </>
    )
}