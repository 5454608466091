import axios from 'axios'
import React, { useContext, useState, useRef, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { UserContext } from '../../../App'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './referal.css'


const Referral = () => {

  const [referalError, setReferalError] = useState('')
  const [referalLink, setReferalLink] = useState('')
  const [info, setInfo] = useState({
    bonus: '0',
    payedBonus: '0',
    sale: '0',
    referalAuthor: '',
    orders: '0',
    parters: '0',
  })
  const user = useContext(UserContext)
  const copyLabel = useRef(null)
  const link = useRef(null)
  const saleTag = useRef(null)
  const [searchParams] = useSearchParams()


  const copyToClipBoard = async () => {

    try {
      // await navigator.clipboard.writeText('https://baksbo.ru/cabinet/referral/?referal=' + user['token']);
      link.current.style.opacity = '0'
      copyLabel.current.style.opacity = '1'
      saleTag.current.style.color = '#76ff00'
      saleTag.current.style.fontSize = '15px'
      setTimeout(() => {
        link.current.style.opacity = '1'
        copyLabel.current.style.opacity = '0'
        saleTag.current.style.color = '#989898'
        saleTag.current.style.fontSize = '14px'
      }, 5000);
    } catch (err) {
      console.log('copyError');
    }
  };

  function getRefferalInfo() {
    axios.get('/api/referal-info')
      .then((response) => {
        setInfo({
          bonus: response.data['bonus'],
          payedBonus: response.data['payed_bonus'],
          sale: response.data['sale'],
          referalAuthor: response.data['referal_author'],
          orders: response.data['referal_orders'],
          parters: response.data['referal_count'],
        })
      })
  }

  function cansel() {
    window.history.replaceState(null, "", "/cabinet/referral")
    setReferalLink('')
    getRefferalInfo()
  }
  function confirm() {
    axios.get('/api/referal-link/' + searchParams.get("referal"))
      .then(response => {
        if (response.data === 'ok') {
          setReferalLink('')
          getRefferalInfo()
          window.history.replaceState(null, "", "/cabinet/referral")
        } else {
          setReferalError(response.data)
        }
      })
  }


  useEffect(() => {
    if (searchParams.get("referal")) {
      var token = searchParams.get("referal")
      axios.get('/api/get-referral/' + token)
        .then(response => {
          if (response.data) {
            setReferalLink(response.data)
          }
        })
    }
    getRefferalInfo()
  }, [])

  return (
    <>
      {referalLink ?
        <>
          <div className='referralPartnerActivationDiv'>
            <p><b>{referalLink}</b> рекомендует вам сервис массовых рассылок сообщений baksbo.ru и делится СКИДКОЙ -5% на первый заказ.</p>
            {referalError ?
              <span className='referalError'>{referalError}</span>
              :
              <></>
            }
            <div>
              <button onClick={cansel} className='referralPartnerActivationButtonCansel'>Отклонить</button>
              <button onClick={confirm}>Принять</button>
            </div>
          </div>
          <div className='refferalManuFon refferalManuFonActive'></div>
        </>
        :
        <></>
      }

      <div className='referalDiv'>
        <div className="referalInfoDiv">
          <div className="referalInfo">
            <span>вознаграждение:</span>
            {info.bonus ?
              <p>{info.bonus} руб.</p>
              :
              <p>0 руб.</p>
            }
          </div>
          <div className="referalInfo">
            <span>Выведено:</span>
            {info.payedBonus ?
              <p>{info.payedBonus} руб.</p>
              :
              <p>0 руб.</p>
            }
          </div>
          <div className="referalInfo">
            <span>Скидка:</span>
            <p>{info.sale} %</p>
          </div>
          <div className="referalInfo">
            <span>Заказы:</span>
            <p>{info.orders} шт.</p>
          </div>
          <div className="referalInfo">
            <span>переходы:</span>
            <p>{info.parters}</p>
          </div>
        </div>
        <div className='referalContactInfo'>
          <fieldset className='referalContactLinkDiv'>
            <legend >Реферальная ссылка</legend>
            <h6 ref={saleTag}>Скидка -5% на первый заказ!</h6>
            <CopyToClipboard text={'https://baksbo.ru/cabinet/referral/?referal=' + user['token']}>
              <p ref={link} onClick={copyToClipBoard}>{'https://baksbo.ru/cabinet/referral/?referal=' + user['token']}</p>
            </CopyToClipboard>
            <CopyToClipboard text={'https://baksbo.ru/cabinet/referral/?referal=' + user['token']}>
              <img onClick={copyToClipBoard} src="/static/img/cabinet/copy-regular.png" alt="" />
            </CopyToClipboard>
            <span ref={copyLabel}>Скопировано!</span>
          </fieldset>

          <fieldset className='referalContactAuthor'>
            {user['referal_sale'] ?
              <h6>У вас есть скидка -5% на первый заказ!</h6>
              : <></>
            }
            <legend >Реферальный партнер</legend>
            <img src="/static/img/cabinet/user-solid.png" alt="" />
            {info['referalAuthor'] ?
              <p>{info['referalAuthor']}</p>
              :
              <p>Вы не подключены к реферальной программе</p>
            }
          </fieldset>
        </div>
        <div className='referalFAQDiv'>
          <div className='referralTableDiv'>
            <p className='referralTableText boldText'>Накопительный расчёт вознаграждения за весь период сотрудничества по реферальной ссылке.</p>
            <div className="referralTable">
              <div className='referralTableColumn'>
                <p>Заказов</p>
                <span>От 0</span>
                <span>От 10</span>
                <span>От 30</span>
                <span>От 50</span>
                <span>От 100</span>
                <span>От 150</span>
                <span>От 200</span>
                <span>От 250</span>
                <span>От 350</span>
                <span>От 450</span>
                <span>От 550</span>
              </div>
              <div className='referalTableLine'></div>
              <div className='referralTableColumn'>
                <p>&nbsp;</p>
                <span>5 %</span>
                <span>6 %</span>
                <span>7 %</span>
                <span>8 %</span>
                <span>9 %</span>
                <span>10 %</span>
                <span>11 %</span>
                <span>12 %</span>
                <span>13 %</span>
                <span>14 %</span>
                <span>15 %</span>
              </div>
            </div>
          </div>

          <div className='referaPayMethodDiv'>
            <p className='boldText'>
              ВНИМАНИЕ! Зачисление вознаграждения производится сразу после того как клиент совершит оплату по заказу.
              <br />
              С вами свяжется наш менеджер и уточнит варианты перевода вознаграждения.
            </p>
            <br />
            <p>
              Зачисление вознаграждения и подсчёт заказов производится
              после того как будет завершена работа по заказу. Данная
              информация обновляется один раз в сутки в данном разделе.
              <br /><br />
              С вами свяжется наш менеджер и уточнит варианты
              перевода вознаграждения.
            </p>
            <div className='referaPayMethodImageDiv'>
              <img src="/static/img/cabinet/tinkoff.png" alt="" />
              <img src="/static/img/cabinet/sber.png" alt="" />
              <img src="/static/img/cabinet/qiwi.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Referral 