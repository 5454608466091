import './widget.css'
import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';


export default function ButtonWidget() {
  const circle = useRef()
  const msmsm = useRef()
  const chatWord = useRef()
  const manuButton = useRef()



  function circleAnimation() {
    setTimeout(() => {
      msmsm.current.style.opacity = '0'
      circle.current.style.backgroundColor = 'white'
      circle.current.style.backgroundPositionX = '5px'
    }, 500);
    setTimeout(() => {
      circle.current.style.backgroundPositionX = '-58px'
    }, 1700);
    setTimeout(() => {
      circle.current.style.backgroundPositionX = '-123px'
    }, 2700);
    setTimeout(() => {
      circle.current.style.backgroundPositionX = '-188px'
    }, 3700);
    setTimeout(() => {
      circle.current.style.backgroundPositionX = '-188px'
    }, 4500);
    setTimeout(() => {
      circle.current.style.backgroundColor = '#0889cb'
      msmsm.current.style.opacity = '1'
    }, 4900);
    setTimeout(() => {
      circle.current.style.backgroundColor = '#0889cb'
      circle.current.style.backgroundPositionX = '5px'

    }, 5500);

  }
  function openMsmsm() {
    setInterval(() => {
      chatWord.current.style.transform = 'scale(1)'
      chatWord.current.style.right = '80px'
      setTimeout(() => {
        chatWord.current.style.transform = 'scale(0)'
        chatWord.current.style.right = '0'

      }, 6000);
    }, 16000);
  }
  function autoOpenMsmsm() {
    setTimeout(() => {
      if (window.screen.width >= 600) {
        manuButton.current.classList.add('activeWidgetManu')
        chatWord.current.style.transform = 'scale(0)'
        chatWord.current.style.right = '0'
        setTimeout(() => {
          chatWord.current.style.display = 'none'
        }, 300);
      }
    }, 7000);
  }


  useEffect(() => {
    circleAnimation()
    setInterval(() => {
      circleAnimation()
    }, 6200);
    openMsmsm()
    autoOpenMsmsm()
  }, [])
  function scrollMailing() {
    window.scrollTo({ behavior: 'smooth', top: document.getElementById('freeConsultation').offsetTop })
  }
  function openManuButton() {
    manuButton.current.classList.toggle('activeWidgetManu')

    chatWord.current.style.transform = 'scale(0)'
    chatWord.current.style.right = '0'
    setTimeout(() => {
      chatWord.current.style.display = 'none'
    }, 300);
  }
  function closeManuButton() {
    manuButton.current.classList.remove('activeWidgetManu')
  }
  return (
    <>
      <div className="buttonWidget">
        <div ref={manuButton} onClick={closeManuButton} className='widgetManu'>
          <div className='closewidgetManu'></div>
          <p>Свяжитесь с нами прямо сейчас</p>
          <a href="tel:89209660591">
            <div style={{ backgroundColor: '#37C837' }}><img src="static/img/button/svgexport-16.png" alt="" /></div>
            <p>8 (920) 966-05-91</p>
            <span>Позвоните нам</span>
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://t.me/baksbo_ru">
            <div style={{ backgroundColor: '#139BD0' }}><img src="static/img/button/svgexport-15.png" alt="" /></div>
            <p>Telegram</p>
            <span>Напишите нам в Telegram</span>
          </a>

          <a target="_blank" rel="noopener noreferrer" href="https://wa.me/+789209660591">
            <div style={{ backgroundColor: '#25D366' }}><img src="static/img/button/svgexport-13.png" alt="" /></div>
            <p>Whatsapp</p>
            <span>Напишите нам в Whatsapp</span>
          </a>
          <Link onClick={scrollMailing} to='/#freeConsultation'>
            <div style={{ backgroundColor: '#0C683F' }}><img src="static/img/button/user-solid.png" alt="" /></div>
            <p>Бесплатная консультация</p>
            <span>Получите бесплатную консультацию</span>
          </Link>

        </div>
        <div ref={chatWord} className='chatWord'>
          <p>Есть вопросы? Обращайтесь!</p>
        </div>
        <div onClick={openManuButton} ref={circle} className='circleButtonWidget'>
          <img ref={msmsm} className='msmsm' src="static/img/button/msms.png" alt="" />
        </div>
      </div>
    </>
  );
}

