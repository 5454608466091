import "./notFound.css"
import {useNavigate} from 'react-router-dom';



export default function NotFoundPage() {
    const navigate = useNavigate();    


    return (
        <>
            <div className="page404Div">
                <div className="social404">
                    <a className="notFoundLink" href="/">baksbo.ru</a>
                    <div className="page404Line"></div>
                    <div className="socialLinks404">
                            <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/baksbo.ru/">
                                <img src="/static/img/icons/insta.png" alt="" />
                            </a>
                            <a target="_blank" rel="noopener noreferrer" href="https://t.me/baksbo_ru">
                                <img src="/static/img/icons/tg.png" alt="" />
                            </a>
                            <a target="_blank" rel="noopener noreferrer" href="https://wa.me/+789209660591">
                                <img src="/static/img/icons/waapp.png" alt="" />
                            </a>
                            <a target="_blank" rel="noopener noreferrer" href="https://vk.com/baksbo_ru">
                                <img src="/static/img/icons/vk.png" alt="" />

                            </a>
                        </div>
                    <div className="page404Line"></div>
                </div>
                <div className="info404">
                    <div>
                        <p className="text404">404</p>
                        <p className="notFoundText">Страница не найдена</p>
                        <p className="goBackText" onClick={() => navigate(-1)}>
                        <svg width="39" height="15" viewBox="0 0 39 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M39 7.5H1M1 7.5L7.41558 1M1 7.5L7.41558 14" stroke="#F4F4F4" stroke-width="0.8"/>
                        </svg>

                        &nbsp;&nbsp;Вернуться назад</p>
                        <div>
                                <p>Техническая поддержка:<b>8 (920) 966-05-91</b></p>
                            </div>
                    </div>
                </div>
            </div>
        </>
    )
}