import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import './blog.css'


function htmlToText(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    return doc.body.textContent || "";
}


function truncateString(str) {
    const maxLength = 70;
    const text = htmlToText(str);
    console.log(text);
    if (text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
    }
    return text;

}

const Blog = () => {
    const getItems = () => {
        axios.get('/api/blog')
            .then(request => {
                setData(request.data)
            })
    }


    const [data, setData] = useState([])

    useEffect(() => {
        getItems()
    }, [])

    return (

        <div className='blogDiv'>
            <Helmet>
                <title>
                    {window.location.hostname == 'msk.baksbo.ru' ?
                        'Блог BAKSBO' :
                        'Блог BAKSBO — сервис массовых рассылок'
                    }
                </title>
                <meta
                    name="description"
                    content={window.location.hostname == 'msk.baksbo.ru' ?
                        'Блог про качественные рассылки в соцсетях, автообзвон и софт для отправки сообщений.' :
                        'Блог про качественные рассылки в соцсетях, автообзвон и софт для отправки сообщений. Множество статей по важным темам в сфере рассылок'
                    }
                />
                <link rel="canonical" href='https://baksbo.ru/blog' />
            </Helmet>
            <h2 className='headerBlog'>Полезные статьи</h2>
            <div className='blogs'>
                {data.length > 0 ?
                    data.map(item =>
                        <>
                            <Link
                                to={item.slug ? `/blog/${item.slug}` : `/blog/${item.id}`}

                            >
                                <div className='blog'>
                                    <img src={item.image} alt="" />
                                    <div className='blogContnetdiv'>
                                        <h2>{item.header}</h2>
                                        <div dangerouslySetInnerHTML={{ __html: truncateString(item.text) }}></div>
                                    </div>
                                </div>
                            </Link>
                        </>
                    )
                    :
                    <></>
                }

            </div>
        </div>
    )
}

export default Blog