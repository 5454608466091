import React, { useRef, useState, useEffect } from 'react'
import { MetaTags } from 'react-meta-tags'
import CheckEmail from '../checkEmail/checkEmail'
import { useNavigate } from 'react-router-dom'
import './forgetPassword.css'
import axios from 'axios'

const ForgetChange = (args) => {
    const navigate = useNavigate();
    const [error, setError] = useState('')
    const [code, setCode] = useState('')
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const passwordRef = useRef(null)
    const password2Ref = useRef(null)
    const codeRef = useRef(null)
    const buttonRef = useRef(null)
    const checkEmailDiv = useRef(null)
    const div = useRef(null)
    const fon = useRef(null)


    useEffect(() => {
        if (args.active == true) {
            checkEmailDiv.current.style.display = 'flex'
            div.current.style.display = 'flex'
            fon.current.style.display = 'flex'
        } else {
            checkEmailDiv.current.style.display = 'none'
            div.current.style.display = 'none'
            fon.current.style.display = 'none'
        }
    }, [args.active])
    function pressEnter(params) {
        if (params.keyCode === 13) {
            submit()
        }
    }
    function submit() {
        if (code) {
            if (password) {
                setError('')
                if (password.length > 7) {
                    setError('')
                    password2Ref.current.focus()
                    if (password === password2) {
                        buttonRef.current.disabled = true
                        axios.get('/api/reset-password/', {
                            params: {
                                code: code,
                                email: args.email,
                                password: password,
                            },
                        })
                            .then(response => {
                                buttonRef.current.disabled = false
                                if (response.data === 'success') {
                                    setError('')
                                    args.setActive()
                                }
                                else if (response.data === 'wrong code') {
                                    setError('Не правильный код!')
                                  }

                            })
                    } else {
                        password2Ref.current.focus()
                        setError('Пароли не совпадают.')
                    }
                } else {
                    passwordRef.current.focus()
                    setError('Пароль не может содержать менее 8 символов.')
                }
            } else {
                passwordRef.current.focus()
                setError('Заполните поле пароль.')
            }

        } else {
            codeRef.current.focus()
            setError('Вводите код подтверждения.')
        }
    }
    return (
        <>
            <div ref={div} className='forgetChange formResponceDiv formResponceDivActive forgetPassword'>
                <div ref={fon} className='formResponceFon '></div>

                <div ref={checkEmailDiv} className='checkEmailDiv'>
                    <h2>Восстановление пароля</h2>
                    <p>На ваш E-mail придет код восстановление пароля.<br /> <i>Проверьте папку «СПАМ»</i></p>
                    <input ref={codeRef} maxLength={5} onKeyDown={pressEnter} value={code} onChange={(elem) => setCode(elem.target.value.slice(0, 4))} placeholder='Вводите код' type="number" />
                    <input ref={passwordRef} onKeyDown={pressEnter} value={password} onChange={(elem) => setPassword(elem.target.value.trim())} type="password" placeholder='Новый пароль' className='nameInput' />
                    <input ref={password2Ref} onKeyDown={pressEnter} value={password2} onChange={(elem) => setPassword2(elem.target.value.trim())} type="password" placeholder='Повторите пароль' className='nameInput' />
                    <button ref={buttonRef} onClick={submit}>Отправить</button>
                    <p className='errorText'>{error}</p>
                </div>
            </div>
        </>
    )
}

export default ForgetChange