import React, { useEffect } from 'react'
import Aos from 'aos'
import './benefice.css'

const Benefice = () => {
    useEffect(() => {
        Aos.init({ duration: 2500 })
    
    }, [])
    return (
        <>
            <h5 className='howWeWork beneficeHeader'>Преимущество перед всеми</h5>
            <div className='beneficeDiv'>
                <div className="benefice">
                    <img data-aos="fade-up" src="/static/img/benefice/pay.png" alt="" />
                    <h2>Оплата после!</h2>
                    <p>Вы оплачиваете заказ после того, как <i>менеджер подтвердит его и обсудит с вами все нюансы заказа.</i> В некоторых случаях после аналитики вашей ниши и предварительной оценки базы.</p>
                </div>
                <div className="benefice">
                    <img data-aos="fade-up" src="/static/img/benefice/quality.png" alt="" />
                    <h2>Качественные аккаунты</h2>
                    <p>Для высокой конверсии мы рассылаем сообщения <i>только с качественных аккаунтов,</i> которые вызывают доверие, тем самым клиент лучше воспринимает ваше сообщение.</p>
                </div>
                <div className="benefice">
                    <img data-aos="fade-up" src="/static/img/benefice/goal.png" alt="" />
                    <h2>Целевая аудитория</h2>
                    <p>БОЛЬШАЯ КОМАНДА - БОЛЬШОЙ ОПЫТ <br /><i>Наша команда имеет опыт более 5 лет в аналитике целевой аудитории.</i> Мы тщательно проанализируем вашу нишу и максимально эффективно соберём вашу целевую аудитори, чтобы в дальнейшем сделать рассылку.</p>
                </div>
                <div className="benefice">
                    <img data-aos="fade-up" src="/static/img/benefice/pay_form.png" alt="" />
                    <h2>Форма оплаты</h2>
                    <p>Мы работаем со <i>всеми заказчиками, как с физическими лицами так и с юридическими лицами</i>. Предоставляем полный пакет закрывающих документов по оплате. Договор, приложение, акт выполненных работ.
                    <br /><br />
                        <i>-  Оплата</i> по ссылке Сбербанк эквайринг (физ. и юр.)<br />
                        <i>-  Оплата</i> по расчетному счету (юр.)</p>
                </div>
                <div className="benefice">
                    <img data-aos="fade-up" src="/static/img/benefice/full_time.png" alt="" />
                    <h2>7 дней в неделю</h2>
                    <p>Работаем без выходных, каникул и праздничных дней. <i>Пишите и звоните нам в любое рабочее время,</i> точный график работы менеджеров указано в контактах.</p>
                </div>
                <div className="benefice">
                    <img data-aos="fade-up" src="/static/img/benefice/comfort.png" alt="" />
                    <h2>Удобный сервис</h2>
                    <p><i>- БЕСПЛАТНЫЙ</i> подбор аудитории для рассылки, у нас самая опытная команда в аналитике. <br />
                        <i>- БЕСПЛАТНО</i> поможем составить вам текст для повышение конверсии и отклика.<br />
                        <i>- БЕСПЛАТНАЯ</i> консультация от наших менеджеров, которые знают все о массовых рассылках.</p>
                </div>
            </div>
        </>
    )
}

export default Benefice