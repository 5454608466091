const headers = {
    "127.0.0.1": ' ГДЕ ТО ТАМ',
    "localhost": ' ГДЕ ТО ТАМ',
    "baksbo.ru": '',
    "msk.baksbo.ru": ' в Москве',
    "spb.baksbo.ru": ' в Санкт-Петербурге',
    "krd.baksbo.ru": ' в Краснодаре',
    "kzn.baksbo.ru": ' в Казани',
}

export default headers