import React, { useState, useEffect } from 'react'
import ClientCreator from '../client/clientCreator'
import ClientEditor from '../client/clientEditor'
import InputMask from 'react-input-mask'
import { NumericFormat } from 'react-number-format'
import axios from 'axios'
import './admin.css'

const Admin = () => {

  const [mobItems, setMobItems] = useState([])
  const [items, setItems] = useState([[], [], [], [], [], [], []])


  const [clientCreator, setClientCreator] = useState(false)
  const [clientEditor, setClientEditor] = useState(null)
  const [highlightedRow, setHighlightedRow] = useState(null)
  const [deleteItem, setDeleteItem] = useState(null)

  function getCookie(name) {
    let cookieValue = null
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';')
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
          break
        }
      }
    }
    return cookieValue;
  }

  useEffect(() => {
    getClients()
  }, [])
  useEffect(() => {
    getClients()
  }, [clientCreator, clientEditor])

  useEffect(() => {
    updateClients()
  }, [items])

  const getClients = () => {
    axios.get('/api/clients').then(
      response => {
        setItems(response.data)
        setMobItems([].concat(...response.data))
      }
    )
  }

  const patchClient = (id, status) => {
    const csrftoken = getCookie('csrftoken')
    axios.patch(`/api/client/${id}`, {
      status: status
    }, {
      headers: {
        'X-CSRFToken': csrftoken,
      },
    }
    ).then(response => {
      if (response.status == 200) {
        getClients()
      }
      else if (response.status == 400) {
        getClients()
      }
    })
      .catch(response => {
        getClients()
      })
  }

  const deleteClients = (id) => {
    const csrftoken = getCookie('csrftoken')
    axios.delete(`/api/clients/${id}`, {
      headers: {
        'X-CSRFToken': csrftoken,
      },
    }).then(response => {
      if (response.status == 200) {
        console.log('okk')
        getClients()
        setDeleteItem(null)
      }
      else {
        getClients()
        setDeleteItem(null)
      }
    })
      .catch(response => {
        getClients()
        setDeleteItem(null)
      })
  }

  const updateClients = () => {
    const csrftoken = getCookie('csrftoken')
    axios.put('/api/clients', {
      data: items
    }, {
      headers: {
        'X-CSRFToken': csrftoken,
      },
    }
    ).then(response => {
      if (response.status == 200) {
        console.log('okk')
      }
      else if (response.status == 400) {
        // setError(true)
      }
    })
      .catch(response => {
        // setError(true)
      })
  }

  const handleDragStart = (e, rowIndex, itemIndex) => {
    e.dataTransfer.setData('rowIndex', rowIndex);
    e.dataTransfer.setData('itemIndex', itemIndex);
  };

  const handleDragOver = (e, rowIndex) => {
    e.preventDefault();
    setHighlightedRow(rowIndex);
  };

  const handleDragLeave = () => {
    setHighlightedRow(null);
  };

  const handleDrop = (e, targetRowIndex, targetItemIndex) => {
    const oldRowIndex = e.dataTransfer.getData('rowIndex')
    const oldItemIndex = e.dataTransfer.getData('itemIndex')

    const newItems = items.map((row) => [...row])

    const [draggedItem] = newItems[oldRowIndex].splice(oldItemIndex, 1)
    newItems[targetRowIndex].splice(targetItemIndex, 0, draggedItem)

    setItems(newItems)
    setHighlightedRow(null)
  };


  const handleDragEnter = (e) => {
    console.log(1)
    e.preventDefault()
  };
  return (
    <div className='crmDiv'>
      <div className='crmClientAdd'>
        <p onClick={() => setClientCreator(true)}>Добавить заказ <b>+</b></p>
      </div>
      {clientEditor &&
        <ClientEditor id={clientEditor} getClients={getClients} setClientEditor={setClientEditor} />
      }
      {clientCreator &&
        <ClientCreator getClients={getClients} setClientCreator={setClientCreator} />
      }
      {window.innerWidth > 800 ? <div className="crmSection">
        <div className="crmApp">
          <div className='crmHedaers'>
            <p>Новый клиент </p>
            <p>Согласование базы</p>
            <p>Сбор базы</p>
            <p>Согласование оффера</p>
            <p>Оплата </p>
            <p>Запуск рассылки</p>
            <p>Рассылка завершена</p>
          </div>
          <div className="crmContainer">
            {items.map((row, rowIndex) => (
              <div
                key={rowIndex}
                className={`row ${highlightedRow === rowIndex ? 'highlighted' : ''}`}
                onDragOver={(e) => handleDragOver(e, rowIndex)}
                onDragLeave={handleDragLeave}
                onDragEnd={() => setHighlightedRow(null)}
                onDrop={(e) => handleDrop(e, rowIndex, row.length)}
              >
                {row.length ? (

                  row.sort((b, a) => a.id - b.id).map((item, itemIndex) => (
                    <div
                      key={item.id}
                      className={`item ${item.contact_type == 'telegram' ? 'itemInfo_telegram' : item.contact_type == 'whatsapp' ? "itemInfo_whatsApp" : item.contact_type}`}
                      draggable
                      onDragStart={(e) => handleDragStart(e, rowIndex, itemIndex)}
                      onDrop={(e) => handleDrop(e, rowIndex, itemIndex)}
                    >
                      <div className='itemInfo'>
                        <p><span>Имя: </span>{item.name}</p>
                        <p><span>Телефон: </span> <InputMask maskChar=" " mask="9 (999) 999-99-99" readOnly type="text" value={item.phone} /> </p>
                        {/* {item.contact_type &&
                          <p><span>Связ: </span>{item.contact_type == 'telegram' ? ' Telegram' : item.contact_type == 'whatsapp' ? "WhatsApp" : item.contact_type}</p>
                        } */}
                        {item.contact &&
                          <p><span>Telegram: </span>{item.contact}</p>
                        }
                        {item.type || item.col || item.price || item.date_order ?
                          <div className="crmLineDiv"></div>
                          :
                          <></>
                        }

                        {item.type &&
                          <p><span>Рассылка: </span>{
                            item.type == 'instagram' ? 'Instagram' :
                              item.type == 'telegram' ? 'Telegram' :
                                item.type == 'whatsApp' ? 'WhatsApp' :
                                  item.type == 'viber' ? 'Viber' :
                                    item.type == 'vk' ? 'Вконтакте' :
                                      item.type == 'sms' ? 'СМС' : 
                                      item.type == 'consultation' ? 'Консультация' : 
                                      <></>
                          }</p>}
                        {item.col &&
                          <p><span>Объем: </span><NumericFormat readOnly allowLeadingZeros thousandSeparator=" " suffix={' шт.'} placeholder='Объем' type="text" value={item.col} /></p>}
                        {item.price &&
                          <p><span>Стоимость: </span><NumericFormat readOnly allowLeadingZeros thousandSeparator=" " suffix={' руб.'} placeholder='Стоимость' type="text" value={item.price} /></p>}
                        {item.date_order && <p><span>Запуск: </span>{item.date_order}</p>}
                        {item.comment &&
                          <>

                            <div className="crmLineDiv"></div>

                            <div className='itemCommentDiv'>
                              <p>Комментарий:</p>
                              <p>{item.comment}</p>
                            </div>
                          </>
                        }

                        <div className="crmLineDiv"></div>
                        {item.manager && <p><span>Ответственный: {item.manager}</span></p>}
                        <p><span>Дата: {item.date}</span></p>
                      </div>
                      <div className={`rowButton ${item.vip && 'vip_client'}`}>
                        <img onClick={() => setClientEditor(item.id)} src="/static/img/admin/settings.png" alt="" />
                        <img onClick={() => setDeleteItem(item.id)} src="/static/img/admin/trash-can-solid.svg" alt="" />
                        {item.vip &&
                          <img className='vip_image' src="/static/img/admin/crown-solid.png" alt="Vip client" title='Vip клиент' />}
                        {item.partner &&
                          <img className='vip_image partner_image' src="/static/img/admin/percent-solid.png" alt="Partner" title='Партнер' />}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className='crmEmptyItem'>
                    <p>Пусто</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
        :
        <div className='mobileCrmClientdiv'>

          {mobItems.map(item => (
            <div key={item.id}
              className={`item mobileCrmClientItem mobileCrmClientItem${item.status} ${item.contact_type == 'telegram' ? 'itemInfo_telegram' : item.contact_type == 'whatsapp' ? "itemInfo_whatsApp" : item.contact_type}`}>
              <div className='itemInfo'>
                <p><span>Имя: </span>{item.name}</p>
                <p><span>Телефон: </span> <InputMask maskChar=" " mask="9 (999) 999-99-99" readOnly type="text" value={item.phone} /> </p>
                {item.contact &&
                  <p><span>Telegram: </span>{item.contact}</p>
                }
                {item.type || item.col || item.price || item.date_order ?
                  <div className="crmLineDiv"></div>
                  :
                  <></>
                }

                {item.type &&
                  <p><span>Рассылка: </span>{

                    item.type == 'instagram' ? 'Instagram' :
                      item.type == 'telegram' ? 'Telegram' :
                        item.type == 'whatsApp' ? 'WhatsApp' :
                          item.type == 'viber' ? 'Viber' :
                            item.type == 'vk' ? 'Вконтакте' :
                              'СМС'
                  }</p>}
                {item.col &&
                  <p><span>Объем: </span><NumericFormat readOnly allowLeadingZeros thousandSeparator=" " suffix={' шт.'} placeholder='Объем' type="text" value={item.col} /></p>}
                {item.price &&
                  <p><span>Стоимость: </span><NumericFormat readOnly allowLeadingZeros thousandSeparator=" " suffix={' руб.'} placeholder='Стоимость' type="text" value={item.price} /></p>}
                {item.date_order && <p><span>Запуск: </span>{item.date_order}</p>}
                {item.comment &&
                  <>

                    <div className="crmLineDiv"></div>

                    <div className='itemCommentDiv'>
                      <p>Комментарий:</p>
                      <p>{item.comment}</p>
                    </div>
                  </>
                }

                <div className="crmLineDiv"></div>
                {item.manager && <p><span>Ответственный: {item.manager}</span></p>}
                <p><span>Дата: {item.date}</span></p>

                <div className='crmClientCreatorRelation'>
                  <select id="newsletter" name="newsletter" value={item.status} onChange={(e) => patchClient(item.id, e.target.value)}>
                    <option value="new">Новый клиент</option>
                    <option value="matching">Согласование базы</option>
                    <option value="collection">Сбор базы</option>
                    <option value="approval">Согласование оффера</option>
                    <option value="payment">Оплата</option>
                    <option value="launch">Запуск рассылки</option>
                    <option value="completed">Рассылка завершена</option>
                  </select>
                </div>

              </div>
              <div className={`rowButton ${item.vip && 'vip_client'}`}>
                <img onClick={() => setClientEditor(item.id)} src="/static/img/admin/settings.png" alt="" />
                <img onClick={() => setDeleteItem(item.id)} src="/static/img/admin/trash-can-solid.svg" alt="" />
                {item.vip &&
                  <img className='vip_image' src="/static/img/admin/crown-solid.png" alt="Vip client" title='Vip клиент' />}
                {item.partner &&
                  <img className='vip_image partner_image' src="/static/img/admin/percent-solid.png" alt="Partner" title='Партнер' />}
              </div>
            </div>
          ))}
        </div>
      }
      {deleteItem &&
        <>
          <div className='crmDeleteItem'>
            <p>Удалить элемеент?</p>
            <p onClick={() => deleteClients(deleteItem)}>Да</p>
            <p onClick={() => setDeleteItem(null)}>Отмена</p>
          </div>
          <div onClick={() => setDeleteItem(null)} className="crmDeleteItemFon"></div>
        </>
      }
    </div>
  )
}

export default Admin