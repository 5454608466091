import { Routes, Route, useNavigate, useLocation, Link } from 'react-router-dom';
import { useRef, useEffect } from 'react'
import NotFoundPage from './page/notFound/notFound';
import Admin from './admin/admin/admin';
import Base from './admin/base/base';
import axios from 'axios';
import UsersBase from './admin/usersBase/usersBase';


function AdminSite() {
    const cabinetSection = useRef(null)
    const location = useLocation()
    const navigate = useNavigate()

    function logout() {
        axios.get('/api/logout')
            .then(response => {
                if (response.data[0] === 'success') {
                    navigate('/admin')
                    window.location.reload()
                }
                localStorage.setItem('userIsAuth', 'false')
            })
    }
    // useEffect(() => {
    //     if (location.pathname === '/login' || location.pathname === '/registration' && localStorage.getItem('userIsAuth') === 'true') {
    //         navigate('/admin')
    //     }
    // }, [])

    return (
        <>
            <div ref={cabinetSection} className='adminSection'>
                <div className='adminMenus'>
                    <p className='adminLogo'>B</p>
                    <Link to={'crm'}>CRM</Link>
                    <Link to={'base'}>База клиентов</Link>
                    <Link to={'users_base'}>База ЛК</Link>
                    <Link onClick={logout} to='/admin'>Выйти</Link>
                </div>
                <Routes>
                    <Route path='' element={<Admin />} />
                    <Route path='/crm' element={<Admin />} />
                    <Route path='/base' element={<Base />} />
                    <Route path='/users_base' element={<UsersBase />} />
                    <Route path='*' status={404} element={<NotFoundPage />} />
                </Routes>
            </div>
        </>
    );
}

export default AdminSite;
