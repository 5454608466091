import React, { useRef, useState ,useEffect } from 'react'
import './forgetPassword.css'
import axios from 'axios'
import ForgetChange from './forgetChange'

const ForgetPassword = (args) => {
    const [error, setError] = useState('')
    const [email, setEmail] = useState('')
    const [forgetChange, setforgetChange] = useState(false)
    const emailRef = useRef(null)
    const buttonRef = useRef(null)
    const checkEmailDiv = useRef(null)
    const div = useRef(null)
    const fon = useRef(null)


    useEffect(() => {
        
        if (args.active == true) {
            checkEmailDiv.current.style.display = 'flex'
            div.current.style.display = 'flex'
            fon.current.style.display = 'flex'
        } else {
            checkEmailDiv.current.style.display = 'none'
            div.current.style.display = 'none'
            fon.current.style.display = 'none'
        }
    }, [args.active])
    function pressEnter(params) {
        if (params.keyCode === 13) {
            submit()
        }
    }
    function submit() {
        if (email) {
            buttonRef.current.disabled = true
            axios.get('/api/foreget-password-code/', {
                params: {
                    email: email,
                },
            })
                .then(response => {
                    buttonRef.current.disabled = false
                    if (response.data === 'success') {
                        setError('')
                        setforgetChange(true)
                        args.setActive(false)
                    }
                    else if (response.data === 'user is not defiend') {
                        setError('Такой аккаунт не зарегистрирован.')
                    }
                    
                })
        } else {
            emailRef.current.focus()
            setError('Заполните поле E-mail.')
        }
    }
    function closeFon() {
        args.setActive(false)
    }
    return (
        <>
            <div ref={div} className='formResponceDiv formResponceDivActive forgetPassword'>
                <div onClick={closeFon} ref={fon} className='formResponceFon '></div>

                <div ref={checkEmailDiv} className='checkEmailDiv'>
                    <h2>Восстановление пароля</h2>
                    <p>На ваш E-mail придет код восстановление пароля.</p>
                    <input ref={emailRef} onKeyDown={pressEnter} value={email} onChange={(elem) => setEmail(elem.target.value.trim())} type="email" placeholder='E-mail' className='nameInput' />
                    <button ref={buttonRef} onClick={submit}>Отправить</button>
                    <p className='errorText'>{error}</p>
                </div>
            </div>
            <ForgetChange setActive={setforgetChange} email={email} active={forgetChange} />
        </>
    )
}

export default ForgetPassword