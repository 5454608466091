import axios from 'axios'
import React, { useEffect, useRef, useState,useContext } from 'react'
import { useSearchParams } from 'react-router-dom'
import { UserContext } from '../../../../App'
import '../telegramServices.css'


const ParserTg = () => {
    const [searchParams] = useSearchParams()
    const user = useContext(UserContext)
    const fileRef = useRef('')
    const startRef = useRef('')
    const accountsDivRef = useRef('')
    const mailingTgLoading = useRef('')
    const disabledLabelRef = useRef('')


    const [dataGet, setDataGet] = useState(false)
    const [link, setLink] = useState('')
    const [status, setStatus] = useState('')
    const [numbers, setNumbers] = useState('')
    const [logins, setLogins] = useState('')
    const [session, setSession] = useState('')
    const [numbersCount, setNumbersCount] = useState(0)
    const [loginsCount, setLoginsCount] = useState(0)
    const [activeRange, setActiveRange] = useState(0)
    const [Percent, setPercent] = useState(0)
    const [category, setCategory] = useState('')
    const [active, setActive] = useState('')
    const [onlineHidden, setOnlineHidden] = useState('')
    const [photo, setphoto] = useState('false')
    const [all, setAll] = useState('false')
    const [messageWriters, setMessageWriters] = useState('false')
    const [loginScrapping, setLoginScrapping] = useState('false')
    const [numberScrapping, setNumberScrapping] = useState('false')



    function startParsing() {
        setStatus('start')
        setPercent(0)
        axios.post('/api/telegram_parser/' + searchParams.get("id") + '/start', {
            'targetUsername': link,
            'category': category,
            'active': active,
            'activeRange': activeRange,
            'onlineHidden': onlineHidden,
            'all': all,
            'writers': messageWriters,
            'photo': photo,
            'loginScrapping': loginScrapping,
            'numberScrapping': numberScrapping,
        }).then(response => {
            if (response.status == 200) {
                startRef.current.disabled = false
                setStatus('finish')

                // setVideo('')
                // setAudio('')
                // setPicture('')
                // setcontentName('')
            }
        })
        // if (sessionsList) {
        //     textRef.current.classList.remove('tgTextError')
        //     if (usernames || numbers) {
        //         numberDivRef.current.classList.remove('tgTextError')
        //         loginDivRef.current.classList.remove('tgTextError')
        //         if (text) {
        //             accountsDivRef.current.classList.remove('tgTextError')
        //             setStatus('start')
        //             startRef.current.disabled = true


        //         } else {
        //             textRef.current.classList.add('tgTextError')
        //         }
        //     } else {
        //         numberDivRef.current.classList.add('tgTextError')
        //         loginDivRef.current.classList.add('tgTextError')
        //     }
        // } else {
        //     accountsDivRef.current.classList.add('tgTextError')
        // }
    }

    function saveUsernames() {
        if (logins) {
            const linkFile = document.createElement("a");
            const file = new Blob([logins], { type: 'text/plain' })
            linkFile.href = URL.createObjectURL(file)
            var channelName = link
            if (link) {
                if (channelName.indexOf('/') > -1) {
                    channelName = link.split('/')
                    channelName = channelName[channelName.length - 1]
                }
            }
            if (link && loginsCount) {
                linkFile.download = channelName + '_' + "usernemes" + '_' + loginsCount + ".txt"
            }
            else { linkFile.download = "usernemes.txt" }
            linkFile.click()
        }
    }
    function saveNumbers() {
        if (numbers) {
            const linkFile = document.createElement("a");
            const file = new Blob([numbers], { type: 'text/plain' })
            linkFile.href = URL.createObjectURL(file)
            var channelName = link
            if (link) {
                if (channelName.indexOf('/') > -1) {
                    channelName = link.split('/')
                    channelName = channelName[channelName.length - 1]
                }
            }
            if (link && loginsCount) {
                linkFile.download = channelName + '_' + "numbers" + '_' + numbersCount + ".txt"
            }
            else { linkFile.download = "numbers.txt" }
            linkFile.click()
        }
    }
    function getMessageWriters() {
        if (messageWriters === 'false') {
            setMessageWriters('true')
        } else {
            setMessageWriters('false')
        }
    }
    function getAll() {
        if (all === 'false') {
            setAll('true')            
        } else {
            setAll('false')            
        }
    }
    function photoChange() {
        if (photo === 'false') {
            setphoto('true')
        } else {
            setphoto('false')
        }
    }
    function loginScrappingChange() {
        if (loginScrapping === 'false') {
            setLoginScrapping('true')
        } else {
            setLoginScrapping('false')
        }
    }
    function numberScrappingChange() {
        if (numberScrapping === 'false') {
            setNumberScrapping('true')
        } else {
            setNumberScrapping('false')
        }
    }


    function uploadAccaunts() {
        fileRef.current.click()
    }

    function inputTrigger(event) {
        let data = new FormData();

        for (let i = 0; i < event.target.files.length; i++) {
            data.append(event.target.files[i]['name'], event.target.files[i])
        }
        axios.post('/api/telegram_parser/' + searchParams.get("id") + '/post_sessions', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(response => {
            if (response.status = 200) {
                getTaskData()
            }
        })
    }

    function getTaskData() {
        if (searchParams.get("id")) {
            axios.get('/api/telegram_parser/' + searchParams.get("id"))
                .then(response => {
                    if (response.status == '200') {
                        setSession(response.data.sessionsList)
                        setStatus(response.data.status)
                        setLink(response.data.target_username)
                        setActiveRange(response.data.active_days)
                        setOnlineHidden(response.data.active_status)
                        setCategory(response.data.user_category)
                        setActive(response.data.user_status)
                        setLogins(response.data.usernames)
                        setNumbers(response.data.numbers)
                        setPercent(response.data.user_count_percent)
                        setNumbersCount(response.data.numbers_count)
                        setLoginsCount(response.data.logins_count)

                        if (response.data.photo == true) {
                            setphoto('true')
                        } else {
                            setphoto('false')
                        }
                        if (response.data.scrape_usernames == true) {
                            setLoginScrapping('true')
                        } else {
                            setLoginScrapping('false')
                        }
                        if (response.data.scrape_numbers == true) {
                            setNumberScrapping('true')
                        } else {
                            setNumberScrapping('false')
                        }
                        if (response.data.all == true) {
                            setAll('true')
                        } else {
                            setAll('false')
                        }
                        if (response.data.writers == true) {
                            setMessageWriters('true')
                        } else {
                            setMessageWriters('false')
                        }

                        setDataGet(true)
                        mailingTgLoading.current.style.display = 'none'
                    }
                })
        }
    }
    function updateTaskData() {
        if (searchParams.get("id")) {
            axios.post('/api/telegram_parser/' + searchParams.get("id") + '/update', {
                'targetUsername': link,
                'category': category,
                'active': active,
                'activeRange': activeRange,
                'onlineHidden': onlineHidden,
                'all': all,
                'writers': messageWriters,
                'photo': photo,
                'loginScrapping': loginScrapping,
                'numberScrapping': numberScrapping,
            })
        }
    }
    useEffect(() => {
        getTaskData()
        setInterval(() => {
            getTaskData()
        }, 8000);
    }, [])

    useEffect(() => {
        if (dataGet) {
            updateTaskData()
        }
        if (all === 'true') {
            disabledLabelRef.current.classList.add('disabledLabel1')
        }
        else{
            disabledLabelRef.current.classList.remove('disabledLabel1')
        }
        if (messageWriters === 'true') {
            disabledLabelRef.current.classList.add('disabledLabel2')
        }
        else{
            disabledLabelRef.current.classList.remove('disabledLabel2')
        }
    }, [link, category, active, activeRange, onlineHidden, photo, all, loginScrapping, numberScrapping,messageWriters])

    return (
        <div className='parserTgDiv mailingTgDiv'>
            {!user['tarif']?
                <div className='demoDisplay'> </div>
            :
                <></>
            }
            <div className='mailingTgLoadingDiv'>
                <div ref={mailingTgLoading} className='mailingTgLoading'>
                    <img src="/static/img/loading.png" alt="" />
                </div>
            </div>
            {status === 'start' ?
                <div className='mailingTgSleepDiv'>
                    <div className='mailingTgSleep'>
                        <img src="/static/img/loading.png" alt="" />
                        <span>{Percent}%</span>
                        <p>Парсер запущен, это может занять несколько минут, пожалуйста ожидайте</p>
                    </div>
                </div> : <></>}
            <div className="mailingTgBlock1">
                <fieldset className='mailingTgBorder groupLink'>
                    <legend>Логин или ссылка на группу</legend>
                    <input placeholder='Вводите ссылку или логин' value={link} onChange={event => setLink(event.target.value)} type="text" />
                </fieldset>
                <fieldset ref={accountsDivRef} className='mailingTgBorder accauntSumm'>
                    <legend>Аккаунт для работы</legend>
                    {session ?
                        <span style={{ color: '#0cb20c' }}>Загружено</span>
                        :
                        <span>Не загружено</span>
                    }
                    <div className="line"></div>
                    <button onClick={uploadAccaunts}>Загрузить</button>
                    <input ref={fileRef} type="file" onChange={inputTrigger} accept=".json,.session" multiple />
                </fieldset>
            </div>
            <div className="mailingTgBlock2">
                <div className='parserSettings'>
                    <label ref={disabledLabelRef}>
                        <span>Категория пользователей</span>
                        <div>
                            <div onClick={() => setCategory('users')}>
                                {category === 'users' ?
                                    <input checked type="radio" id='users' name='userType' value="users" />
                                    :
                                    <input type="radio" id='users' name='userType' value="users" />
                                }
                                <label for="users">Участники</label>
                            </div>
                            <div onClick={() => setCategory('admins')}>
                                {category === 'admins' ?
                                    <input checked type="radio" id='admins' name='userType' value="admins" />
                                    :
                                    <input type="radio" id='admins' name='userType' value="admins" />
                                }
                                <label for="admins">Администраторы</label>
                            </div>
                        </div>
                        <span>Активность пользователей</span>
                        <div>
                            <div onClick={() => setActive('active')}>
                                {active === 'active' ?
                                    <input checked type="radio" id='activeUser' name='userActiveDays' value="activeUser" />
                                    :
                                    <input type="radio" id='activeUser' name='userActiveDays' value="activeUser" />
                                }
                                <label for="activeUser">Активные</label>
                            </div>
                            <div onClick={() => setActive('unactive')}>
                                {active === 'unactive' ?
                                    <input checked type="radio" id='activeAdmin' name='userActiveDays' value="activeAdmin" />
                                    :
                                    <input type="radio" id='activeAdmin' name='userActiveDays' value="activeAdmin" />
                                }
                                <label for="activeAdmin">Неактивные</label>
                            </div>
                        </div>
                        <div className='rangeInputDiv'>
                            <input className='rangeInput' type="range" min="0" max="100" onChange={event => setActiveRange(event.target.value)} value={activeRange} />
                            {activeRange == 0 ?

                                <p>Любая активность</p>
                                :
                                <p>Активность {activeRange} дней</p>
                            }
                        </div>
                        <span>Если онлайн скрыт</span>
                        <div className='tgOnlineRadioDiv'>
                            <div onClick={() => setOnlineHidden('recently')}>
                                {onlineHidden === 'recently' ?
                                    < input checked type="radio" id='recently' name='userActivestatus' value="recently" />
                                    :
                                    <input type="radio" id='recently' name='userActivestatus' value="recently" />
                                }
                                <label for="recently">Был недавно (до 2-3 дней)</label>
                            </div>
                            <div onClick={() => setOnlineHidden('thisWeek')}>
                                {onlineHidden === 'thisWeek' ?
                                    <input checked type="radio" id='thisWeek' name='userActivestatus' value="thisWeek" />
                                    :
                                    <input type="radio" id='thisWeek' name='userActivestatus' value="thisWeek" />
                                }
                                <label for="thisWeek">Был на этой неделе (от 2-3 до 7 дней)</label>
                            </div>
                            <div onClick={() => setOnlineHidden('thisMonth')}>
                                {onlineHidden === 'thisMonth' ?
                                    <input type="radio" id='thisMonth' name='userActivestatus' value="thisMonth" />
                                    :
                                    <input type="radio" id='thisMonth' name='userActivestatus' value="thisMonth" />
                                }
                                <label for="thisMonth">Был в этом месяце (от 7 до 30 дней)</label>
                            </div>
                        </div>

                    </label>

                    <div className="checkboxSetting">
                        <p>
                            Собрать всех
                            {all === 'false' ?
                                <input onClick={getAll} id="getAll" class="switch-input" type="checkbox" />
                                :
                                <input onClick={getAll} checked id="getAll" class="switch-input" type="checkbox" />
                            }
                            <label for="getAll" class="switch"></label>
                        </p>
                        <p>
                            Кто пишет в чате
                            {messageWriters === 'false' ?
                                <input onClick={getMessageWriters} id="messageWriters" class="switch-input" type="checkbox" />
                                :
                                <input onClick={getMessageWriters} checked id="messageWriters" class="switch-input" type="checkbox" />
                            }
                            <label for="messageWriters" class="switch"></label>
                        </p>
                        <p>
                            Наличие аватара
                            {photo === 'false' ?
                                <input onClick={photoChange} id="ifAvatar" class="switch-input" type="checkbox" />
                                :
                                <input onClick={photoChange} checked id="ifAvatar" class="switch-input" type="checkbox" />
                            }
                            <label for="ifAvatar" class="switch"></label>
                        </p>
                        <p>
                            Сбор логинов
                            {loginScrapping === 'false' ?
                                <input onClick={loginScrappingChange} id="loginCollection" class="switch-input" type="checkbox" />
                                :
                                <input onClick={loginScrappingChange} checked id="loginCollection" class="switch-input" type="checkbox" />
                            }
                            <label for="loginCollection" class="switch"></label>
                        </p>
                        <p>
                            Сбор номеров
                            {numberScrapping === 'false' ?
                                <input onClick={numberScrappingChange} id="numberCollection" class="switch-input" type="checkbox" />
                                :
                                <input onClick={numberScrappingChange} checked id="numberCollection" class="switch-input" type="checkbox" />
                            }
                            <label for="numberCollection" class="switch"></label>
                        </p>
                    </div>
                </div>
                <div className='tgUsersBlock'>
                    <fieldset className='mailingTgBorder'>
                        <legend>Usernames</legend>
                        <textarea value={logins}></textarea>
                        <span>{loginsCount}</span>
                        <div className="line"></div>
                        <button onClick={saveUsernames}>Скачать</button>
                    </fieldset>
                    <fieldset className='mailingTgBorder'>
                        <legend>Номера</legend>
                        <textarea value={numbers}></textarea>
                        <span>{numbersCount}</span>
                        <div className="line"></div>
                        <button onClick={saveNumbers}>Скачать</button>
                    </fieldset>
                </div>
            </div>
            <div className='mailingTgBlock4'>
                <div className='mailingTgBorder parsingStartDiv'>
                    {status === 'setting' ?
                        <button onClick={startParsing}>Запустить парсинг</button>
                        :
                        status === 'start' ?
                            <button disabled >Запущено</button>
                            :
                            <button onClick={startParsing}>Запустить заново</button>
                    }
                </div>
            </div>
        </div>
    )
}

export default ParserTg