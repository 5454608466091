import { useRef, useState, useEffect } from 'react'
import MetaTags from 'react-meta-tags';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import './adminLogin.css'


const AdminLogin = () => {
  const [searchParams] = useSearchParams()
  const [referalLink, setReferalLink] = useState('')
  const [registrationLink, setRegistrationLink] = useState('/registration')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorTetx, setErrorTetx] = useState('')
  const navigate = useNavigate();
  const error = useRef(null)
  const emailRef = useRef(null)
  const passwordRef = useRef(null)
  const buttonRef = useRef(null)
  const [userName, setUserName] = useState('admin@adm.in')

  function passwordHide() {
    if (passwordRef.current.type === 'password') {
      passwordRef.current.type = 'text'
    } else {
      passwordRef.current.type = 'password'
    }
  }

  // get cookies for post request
  function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      var cookies = document.cookie.split(';');
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim()
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }
  // on press enter key
  function pressEnter(params) {
    if (params.keyCode === 13) {
      submit()
    }
  }
  // this is submit function, you know?
  function submit() {
    if (email) {
      setErrorTetx('')
      passwordRef.current.focus()
      if (password) {
        setErrorTetx('')
        var csrftoken = getCookie('csrftoken')
        buttonRef.current.disabled = true
        axios.post('/api/admin-login', {
          'email': email,
          'password': password,
          'X-CSRFToken': csrftoken,
        }).then(response => {
          buttonRef.current.disabled = false
          if (response.data === 'success') {
            navigate('/admin')
            window.location.reload()
          }
          else if (response.data === 'wrong password') {
            setErrorTetx('Вы ввели неверный E-mail или пароль.')
            passwordRef.current.focus()
          }
          else if (response.data === 'user is not admin') {
            setErrorTetx('Аккаунт не админ')            
          }
          else if (response.data === 'user is not defiend') {
            setErrorTetx('Такой аккаунт не зарегистрирован.')
          }
        })
      } else {
        passwordRef.current.focus()
        setErrorTetx('Заполните поле пароль.')
      }
    } else {
      emailRef.current.focus()
      setErrorTetx('Заполните поле E-mail.')
    }
  }
  return (
    <>
      <MetaTags>
        <title>Логин Baksbo.ru</title>
      </MetaTags>
      <div className="authorizationPage ">
        <div className='loginDiv authorizationForm '>
          <h2>Вход в администрацию</h2>
          {referalLink ?
            <p>
              <b>{referalLink}</b> рекомендует вам сервис массовых рассылок сообщений baksbo.ru и делится СКИДКОЙ -5% на первый заказ.
              <br />Заполните форму чтобы войти в аккаунт.
            </p>
            :
            <p>Заполните форму чтобы войти в аккаунт.</p>
          }
          <div className='form'>
            <input ref={emailRef} onKeyDown={pressEnter} value={email} onChange={(elem) => setEmail(elem.target.value.trim())} type="email" placeholder='E-mail' className='nameInput' />
            <div className='passwordHideDiv'>
              <input ref={passwordRef} onKeyDown={pressEnter} value={password} onChange={(elem) => setPassword(elem.target.value.trim())} type="password" placeholder='Пароль' className='nameInput' />
              <img onClick={passwordHide} src="/static/img/cabinet/eye-slash-solid.png" alt="" />
            </div>
            <button onClick={submit} ref={buttonRef} >Вход</button>
          </div>
          <p ref={error} className='errorText'>{errorTetx}</p>
        </div>
      </div>
    </>
  )
}

export default AdminLogin

