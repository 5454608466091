import axios from 'axios'
import React, { useRef, useState, useEffect, useContext } from 'react'
import { useSearchParams } from 'react-router-dom'
import HelpDiv from '../../../elements/helpDiv/helpDiv'
import { UserContext } from '../../../../App'
import '../telegramServices.css'

const Inviting = () => {
    const [searchParams] = useSearchParams()
    const user = useContext(UserContext)

    const mailingTgLoading = useRef('')
    const loginsRef = useRef('')
    const numbersRef = useRef('')
    const accountsRef = useRef('')
    const textRef = useRef('')

    const startRef = useRef('')
    const accountsDivRef = useRef('')
    const numberDivRef = useRef('')
    const loginDivRef = useRef('')
    const proxyDivRef = useRef('')
    const proxyRef = useRef('')
    const proxyFileRef = useRef('')

    const [proxyCount, setProxyCount] = useState(0)
    const [useProxy, setUseProxy] = useState('false')
    const [dataGet, setDataGet] = useState(false)
    const [targetUsername, setTargetUsername] = useState('')
    const [usernames, setUsernames] = useState()
    const [usernamesCount, setUsernamesCount] = useState(0)
    const [numbers, setNumbers] = useState('')
    const [numbersCount, setNumbersCount] = useState(0)
    const [sessionsList, setSessionsList] = useState(0)
    const [workingAccouts, setWorkingAccouts] = useState(1)
    const [delay, setDelay] = useState(30)
    const [messageOfAccaunt, setMessageOfAccaunt] = useState(1)
    const [inviteOfAccaunt, setInviteOfAccaunt] = useState(1)
    const [status, setStatus] = useState('setting')
    const [usernemesLog, setUsernemesLog] = useState('')
    const [numbersLog, setNumbersLog] = useState('')
    const [usernemesLogCount, setUsernemesLogCount] = useState('')
    const [numbersLogCount, setNumbersLogCount] = useState('')

    const [notFoundLogs, setNotFoundLogs] = useState('')
    const [deliveryLogs, setDeliveryLogs] = useState('')
    const [changeAccountLogs, setChangeAccountLogs] = useState('')
    const [logs, setLogs] = useState('')

    function startMailing() {
        setStatus('start')
        axios.post('/api/telegram_invite/' + searchParams.get("id") + '/start', {
            'targetUsername': targetUsername,
            'delay': delay,
            'messageOfAccaunt': messageOfAccaunt, // Приглашения с аккаунта
            'sessionCount': workingAccouts, // Количество сессий
            'inviteOfAccaunt': inviteOfAccaunt, // Пригласить за раз
        }).then(response => {
            if (response.status == 200) {
                setStatus('finish')

                // setVideo('')
                // setAudio('')
                // setPicture('')
                // setcontentName('')
            }
        })
        // if (sessionsList) {
        //     textRef.current.classList.remove('tgTextError')
        //     if (usernames || numbers) {
        //         numberDivRef.current.classList.remove('tgTextError')
        //         loginDivRef.current.classList.remove('tgTextError')
        //         if (text) {
        //             accountsDivRef.current.classList.remove('tgTextError')
        //             setStatus('start')
        //             startRef.current.disabled = true


        //         } else {
        //             textRef.current.classList.add('tgTextError')
        //         }
        //     } else {
        //         numberDivRef.current.classList.add('tgTextError')
        //         loginDivRef.current.classList.add('tgTextError')
        //     }
        // } else {
        //     accountsDivRef.current.classList.add('tgTextError')
        // }
    }

    function changeProxy() {
        if (useProxy === 'false') {
            setUseProxy('true')
        } else {
            setUseProxy('false')
        }
    }

    function pauseMailing() {
        axios.post('/api/telegram_invite/' + searchParams.get("id") + '/pause')
            .then(response => {
                if (response.status == 200) {
                    setStatus('finish')
                }
            })
    }

    function saveLog() {
        if (logs) {
            const link = document.createElement("a");
            const file = new Blob([logs], { type: 'text/plain' })
            link.href = URL.createObjectURL(file)
            link.download = "logs.txt"
            link.click()
        }
    }
    function saveUsernames() {
        if (usernemesLog) {
            const link = document.createElement("a");
            const file = new Blob([usernemesLog], { type: 'text/plain' })
            link.href = URL.createObjectURL(file)
            link.download = "usernemes.txt"
            link.click()
        }
    }
    function saveNumbers() {
        if (numbersLog) {
            const link = document.createElement("a");
            const file = new Blob([numbersLog], { type: 'text/plain' })
            link.href = URL.createObjectURL(file)
            link.download = "numbers.txt"
            link.click()
        }
    }
    function updateTaskData() {
        if (searchParams.get("id")) {
            axios.post('/api/telegram_invite/' + searchParams.get("id") + '/update', {
                'targetUsername': targetUsername,
                'delay': delay,
                'useProxy': useProxy,
                'messageOfAccaunt': messageOfAccaunt, // Приглашения с аккаунта
                'sessionCount': workingAccouts, // Количество сессий
                'inviteOfAccaunt': inviteOfAccaunt, // Пригласить за раз
            })
        }
    }

    function useramesTrigger() {
        let logins = loginsRef.current.files[0]
        let numbers = numbersRef.current.files[0]
        let data = new FormData()
        data.append('logins', logins)
        data.append('numbers', numbers)

        axios.post('/api/telegram_invite/' + searchParams.get("id") + '/post_users', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(response => {
            if (response.status = 200) {
                getTaskData()
            }
        })
    }
    function changeDelay() {
        if (delay < 30) {
            setDelay(30)
        }
    }
    function changeMessageOfAccaunt() {
        if (sessionsList) {
            if (workingAccouts >= sessionsList) {
                setWorkingAccouts(sessionsList)
            }
        }
    }

    function setParams(operation, setFunction, value, name) {
        if (operation === '+') {
            setFunction(+value + 1)
            changeMessageOfAccaunt()
        }
        else if (operation === '-') {
            if (name == 'delay') {
                if (value > 30) {
                    setFunction(+value - 1)
                }
            }
            else {
                if (value > 1) {
                    setFunction(+value - 1)
                }
            }
        }
    }
    function uploadLogins() {
        loginsRef.current.click()
    }

    function uploadNumbers() {
        numbersRef.current.click()
    }

    function uploadAccaunts() {
        accountsRef.current.click()
    }
    function uploadProxy() {
        proxyRef.current.click()
    }
    
    function inputTrigger(event) {
        let data = new FormData();

        for (let i = 0; i < event.target.files.length; i++) {
            data.append(event.target.files[i]['name'], event.target.files[i])
        }
        axios.post('/api/telegram_invite/' + searchParams.get("id") + '/post_sessions', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(response => {
            if (response.status = 200) {
                getTaskData()
            }
        })
    }
    function proxyinputTrigger(event) {
        let data = new FormData();
        for (let i = 0; i < event.target.files.length; i++) {
            data.append(event.target.files[i]['name'], event.target.files[i])
        }
        axios.post('/api/telegram_invite/' + searchParams.get("id") + '/post_proxy', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(response => {
            console.log(response);
            if (response.status = 200) {
                getTaskData()
            }
        })
    }
    function getTaskData() {
        if (searchParams.get("id")) {
            axios.get('/api/telegram_invite/' + searchParams.get("id"))
                .then(response => {
                    if (response.status == '200') {
                        setLogs(response.data.logs)
                        setUsernames(response.data.logins)
                        setNumbers(response.data.numbers)
                        setUsernamesCount(response.data.loginsCount)
                        setNumbersCount(response.data.numbersCount)
                        setSessionsList(response.data.sessionsList)
                        setTargetUsername(response.data.targetUsername)
                        setMessageOfAccaunt(response.data.messagesPerAccount)
                        setWorkingAccouts(response.data.sessionCount)
                        setInviteOfAccaunt(response.data.invitePerAccount)
                        setUseProxy(response.data.use_proxy)
                        setProxyCount(response.data.proxyList)
                        setDelay(response.data.delay)
                        setDataGet(true)
                        setStatus(response.data.status)
                        setUsernemesLog(response.data.usernamesLog)
                        setNumbersLog(response.data.numbersLog)
                        setNotFoundLogs(response.data.notFoundLogs)
                        setDeliveryLogs(response.data.deliveryLogs)
                        setChangeAccountLogs(response.data.changeAccountLogs)
                        setUsernemesLogCount(response.data.usernamesLogCount)
                        setNumbersLogCount(response.data.numbersLogCount)
                        mailingTgLoading.current.style.display = 'none'
                    }
                })
        }
    }
    useEffect(() => {
        getTaskData()
        setInterval(() => {
            getTaskData()
        }, 10000);
    }, [])

    useEffect(() => {
        if (dataGet) {
            updateTaskData()
        }
    }, [targetUsername, workingAccouts, delay, messageOfAccaunt, inviteOfAccaunt, useProxy])

    return (
        <div className='invitingTgDiv mailingTgDiv inviteTgDiv'>
            {!user['tarif'] ?
                <div className='demoDisplay'> </div>
                :
                <></>
            }
            <div className='mailingTgLoadingDiv'>
                <div ref={mailingTgLoading} className='mailingTgLoading'>
                    <img src="/static/img/loading.png" alt="" />
                </div>
            </div>
            <div className='mailingTgBlock1'>
                <div className='tgUsersBlock'>
                    <fieldset ref={numberDivRef} className='mailingTgBorder'>
                        <legend>Usernames</legend>
                        <HelpDiv margin={{ top: 0, left: -10 }} text={'Список логинов для добавления в группу, добавлять без "@"'} />
                        <textarea value={usernames}></textarea>
                        <span>{usernamesCount}</span>
                        <div className="line"></div>
                        <input onChange={useramesTrigger} ref={loginsRef} type="file" accept='.txt' />
                        <button onClick={uploadLogins}>Загрузить</button>
                    </fieldset>
                    <fieldset ref={loginDivRef} className='mailingTgBorder'>
                        <HelpDiv margin={{ top: 0, left: -10 }} text={'Список номеров для добавления в группу, добавлять без "8 и +"'} />
                        <legend>Номера</legend>
                        <textarea value={numbers}></textarea>
                        <span>{numbersCount}</span>
                        <div className="line"></div>
                        <input onChange={useramesTrigger} ref={numbersRef} type="file" accept='.txt' />
                        <button onClick={uploadNumbers}>Загрузить</button>
                    </fieldset>
                </div>
                <div className='fitemOfBlock1'>
                    <fieldset ref={accountsDivRef} className='mailingTgBorder accauntSumm'>
                        <HelpDiv margin={{ top: 5, left: -10 }} text={'Общее количество загруженных аккаунтов для работы.'} />
                        <legend>Количество загруженных аккаунтов</legend>
                        {sessionsList ?
                            <span><i>{sessionsList}</i> Аккаунтов</span>
                            :
                            <span><i>0</i> Аккаунтов</span>
                        }
                        <div className="line"></div>
                        <button onClick={uploadAccaunts}>Загрузить</button>
                        <input ref={accountsRef} type="file" onChange={inputTrigger} accept=".json,.session" multiple />
                    </fieldset>
                    

                    <fieldset ref={proxyDivRef} className='mailingTgBorder accauntSumm'>
                        <HelpDiv margin={{ top: 5, left: -10 }} text={'Общее количество загруженных прокси для работы.\n Формат загрузки, каждый прокси с новой строчки:\n Username:password@hostIP:port'} />
                        <legend>Количество загруженных прокси</legend>
                        {proxyCount ?
                            <span><i>{proxyCount}</i> Прокси</span>
                            :
                            <span><i>0</i> Прокси</span>
                        }
                        <div className='line'></div>
                        {useProxy === 'false' ?
                            <input onClick={changeProxy} id="useProxy" class="switch-input" type="checkbox" />
                            :
                            <input onClick={changeProxy} checked id="useProxy" class="switch-input" type="checkbox" />
                        }
                        <label for="useProxy" class="switch"></label>

                        <div className='line'></div>

                        <button onClick={uploadProxy}>Загрузить</button>
                        <input ref={proxyRef} type="file" onChange={proxyinputTrigger} accept=".txt" />
                    </fieldset>

                    <fieldset className='mailingTgBorder groupLink'>
                        <legend>Логин или ссылка на группу</legend>
                        <input placeholder='Вводите ссылку или логин' value={targetUsername} onChange={event => setTargetUsername(event.target.value)} type="text" />
                    </fieldset>

                    <div className='mailingTgBorder tgServicesUsersParams'>
                        <div className='userParam'>
                            <span>Задержка</span>
                            <div className="input">
                                <label onClick={() => setParams('-', setDelay, delay, 'delay')} className='minusLabel'>-</label>
                                <input min="1" type="number" onChange={event => setDelay(Math.abs(event.target.value))} onBlur={changeDelay} value={delay} />
                                <label onClick={() => setParams('+', setDelay, delay, 'delay')} className='plusLabel'>+</label>
                            </div>
                        </div>
                        <div className='userParam'>
                            <span>Приглашения с аккаунта</span>
                            <div className="input">
                                <label onClick={() => setParams('-', setInviteOfAccaunt, inviteOfAccaunt, 'inviteOfAccaunt')} className='minusLabel'>-</label>
                                <input min="1" type="number" onChange={event => setInviteOfAccaunt(Math.abs(event.target.value))} onBlur={changeMessageOfAccaunt} value={inviteOfAccaunt} />
                                <label onClick={() => setParams('+', setInviteOfAccaunt, inviteOfAccaunt, 'inviteOfAccaunt')} className='plusLabel'>+</label>
                            </div>
                        </div>
                        <div className='userParam'>
                            <span>Количество сессий</span>
                            <div className="input">
                                <label onClick={() => setParams('-', setWorkingAccouts, workingAccouts, 'workingAccouts')} className='minusLabel'>-</label>
                                <input min="1" type="number" onChange={event => setWorkingAccouts(Math.abs(event.target.value))} value={workingAccouts} />
                                <label onClick={() => setParams('+', setWorkingAccouts, workingAccouts, 'workingAccouts')} className='plusLabel'>+</label>
                            </div>
                        </div>
                        <div className='userParam'>
                            <span>Пригласить за раз</span>
                            <div className="input">
                                <label onClick={() => setParams('-', setMessageOfAccaunt, messageOfAccaunt, 'messageOfAccaunt')} className='minusLabel'>-</label>
                                <input min="1" type="number" onChange={event => setMessageOfAccaunt(Math.abs(event.target.value))} onBlur={changeMessageOfAccaunt} value={messageOfAccaunt} />
                                <label onClick={() => setParams('+', setMessageOfAccaunt, messageOfAccaunt, 'messageOfAccaunt')} className='plusLabel'>+</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mailingTgBlockInfo'>
                <div></div>
                <p>Информация</p>
                <div></div>
            </div>
            {/* <div className='mailingTgBlock2'>
                <div className='mailingTgBorder invitingTgAccountsInfo'>
                    {deliveryLogs ?
                        <p>Доставлено {deliveryLogs}</p>
                        :
                        <p>Доставлено 0</p>
                    }
                    <div className="line"></div>
                    {notFoundLogs ?
                        <p>Не найдено {notFoundLogs}</p>
                        :
                        <p>Не найдено 0</p>
                    }
                    <div className="line"></div>
                    {changeAccountLogs ?
                        <p>Сменнено аккаунтов {changeAccountLogs}</p>
                        :
                        <p>Сменнено аккаунтов 0</p>
                    }
                </div>
            </div> */}
            <div className='mailingTgBlock3'>
                <fieldset className='mailingTgBorder tgLog'>
                    <legend>Лог работы</legend>
                    <textarea value={logs} ></textarea>
                    <div className="line"></div>
                    <button onClick={saveLog}>Скачать</button>
                </fieldset>
                <div className='tgUsersBlock'>

                    <fieldset className='mailingTgBorder'>
                        <legend>Usernames</legend>
                        <textarea value={usernemesLog} ></textarea>
                        <span>{usernemesLogCount}</span>
                        <div className="line"></div>
                        <button onClick={saveUsernames}>Скачать</button>
                    </fieldset>
                    <fieldset className='mailingTgBorder'>
                        <legend>Номера</legend>
                        <textarea value={numbersLog}></textarea>
                        <span>{numbersLogCount}</span>
                        <div className="line"></div>
                        <button onClick={saveNumbers}>Скачать</button>
                    </fieldset>
                </div>
            </div>
            <div className='mailingTgBlock4'>
                <div className='mailingTgBorder parsingStartDiv'>

                    {status === 'setting' ?
                        <button ref={startRef} onClick={startMailing}>Запустить</button>
                        :
                        status === 'start' ?
                            <button ref={startRef} onClick={pauseMailing} >Остоновить</button>
                            :
                            <button ref={startRef} onClick={startMailing}>Запустить заново</button>
                    }
                </div>
            </div>
        </div>
    )
}

export default Inviting