import { useEffect, useRef, useState } from 'react'
import './mailing.css'
import axios from 'axios'
import { Link } from 'react-router-dom'


const MailingKey = () => {
    const mailing = useRef(null)
    const apps = useRef(null)
    const [linkActive, setLinkActive] = useState(null)
    const [instagram, setInstagram] = useState('')
    const [telegram, setTelegram] = useState('')
    const [whatsapp, setWhatsapp] = useState('')
    const [viber, setViber] = useState('')
    const [vk, setVk] = useState('')

    useEffect(() => {
        axios.get('/api/price')
            .then(request => request.data.prices)
            .then(request => {
                setInstagram(request.instagram)
                setTelegram(request.telegram)
                setWhatsapp(request.whatsapp)
                setViber(request.viber)
                setVk(request.vk)
            }
            )
        axios.get('/api/self-mailing/',
            {
                params: {
                    platform: 'all',
                },
            })
            .then(response => {
                if (response.data[0]) {
                    setLinkActive(true)
                }
            })
    }, [])
    return (
        <>
            <p className='cabinetMessageMailingText'>Воспользуйтесь массовой рассылкой сообщений в соцсети и мессенджеры. Наша команда имеет большой опыт, любой проект мы берем под ключ, вам остаётся только согласовывать те или иные этапы рассылки.</p>
            {/* {linkActive ?
                <Link className='buyMaiiling' to={'/cabinet/mailing/statistic'}>Статистика рассылкы</Link>
                :
                <></>
            } */}
            <div ref={mailing} id='mailings' className="messageMailing cabinetMessageMailing">
                <div ref={apps} className='apps'>
                    <Link target="_blank" to={'/instagram'}>
                        <div className="mailingApp instagram " style={{ transition: '0.5s' }}>
                            <div>
                                <img className='twoD instagram2d' src="/static/img/icons/gold_insta.png" alt="" />
                                <img className='treeD instagram3d' src="/static/img/insta3d.png" alt="" />
                            </div>
                            <p className='name'>Instagram</p>
                            <p className='instagramPrice price'>от {instagram}<b> ₽</b></p>
                            <span>*за одно сообщение</span>
                            <button className='instagramButton' >Подробней</button>
                        </div>
                    </Link>
                    <Link target="_blank" to={'/telegram'}>
                        <div id='scrollmailings' className="mailingApp telegram " style={{ transition: '0.5s' }}>
                            <div>
                                <img className='twoD telegram2d' src="/static/img/icons/gold_tg.png" alt="" />
                                <img className='treeD telegram3d' src="/static/img/tg3d.png" alt="" />
                            </div>
                            <p className='name'>Telegram</p>
                            <p className='telegramPrice price'>от {telegram}<b> ₽</b></p>
                            <span>*за одно сообщение</span>
                            <button className='telegramButton' >Подробней</button>
                        </div>
                    </Link>
                    <Link target="_blank" to={'/whatsapp'}>
                        <div className="mailingApp whatsApp" style={{ transition: '0.5s' }}>
                            <div>
                                <img className='twoD whatsApp2d' src="/static/img/icons/gold_whatsApp.png" alt="" />
                                <img className='treeD whatsApp3d' src="/static/img/whats3d.png" alt="" />
                            </div>
                            <p className='name'>WhatsApp</p>
                            <p className='whatsAppPrice price'>от {whatsapp}<b> ₽</b></p>
                            <span>*за одно сообщение</span>
                            <button className='whatsAppButton' >Подробней</button>
                        </div>
                    </Link>
                    <Link target="_blank" to={'/viber'}>
                        <div className="mailingApp viber" style={{ transition: '0.5s' }}>
                            <div>
                                <img className='twoD viber2d' src="/static/img/icons/gold_viber.png" alt="" />
                                <img className='treeD viber3d' src="/static/img/viber3d.png" alt="" />
                            </div>
                            <p className='name'>Viber</p>
                            <p className='viberPrice price'>от {viber}<b> ₽</b></p>
                            <span>*за одно сообщение</span>
                            <button className='viberButton' >Подробней</button>

                        </div>
                    </Link>
                    <Link target="_blank" to={'/vk'}>
                        <div className="mailingApp vkontakte" style={{ transition: '0.5s' }}>
                            <div>
                                <img className='twoD vkontakte2d' src="/static/img/icons/gold_vk.png" alt="" />
                                <img className='treeD vkontakte3d' src="/static/img/vk3d.png" alt="" />
                            </div>
                            <p className='name'>Vkontakte</p>
                            <p className='vkontaktePrice price'>от {vk}<b> ₽</b></p>
                            <span>*за одно сообщение</span>
                            <button className='vkontakteButton' >Подробней</button>
                        </div>
                    </Link>
                </div>
            </div>
        </>

    )
}

export default MailingKey