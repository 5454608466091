import React, { useEffect, useRef, useState } from 'react'
import './cookieWidget.css'


const CookieWidget = () => {
    const cookie = useRef('')
    const [cookieState, setCookie] = useState('')

    function cookieAccept() {
        localStorage.setItem('cookie', "true")        
        cookie.current.style.display = 'none'            
    }

    useEffect(() => {
        if (localStorage.getItem('cookie')){
            cookie.current.style.display = 'none'            
        }else{
            cookie.current.style.display = 'flex'
        }
    }, [])


    return (
        <div ref={cookie} className='cookieWidgetDiv'>
            <p>Используя данный сайт, вы даете согласие на использование <i>файлов cookie,</i> помогающих нам сделать его удобнее для вас. <a target='_blank' href="/static/files/privacy_policy.pdf">Подробнее</a> </p>
            <button onClick={cookieAccept}>Хорошо</button>
        </div>
    )
}

export default CookieWidget