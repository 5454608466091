import { useState, useEffect, useRef } from 'react'
import MetaTags from 'react-meta-tags';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import CheckEmail from '../elements/checkEmail/checkEmail';
import './registration.css'
import PhoneInput from 'react-phone-number-input';


const Registration = () => {
    const [login, setLogin] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [number, setNumber] = useState()
    const [searchParams] = useSearchParams()
    const [referalLink, setReferalLink] = useState('')
    const [errorTetx, setErrorTetx] = useState('')
    const navigate = useNavigate();
    const error = useRef(null)
    const loginRef = useRef(null)
    const numberRef = useRef(null)
    const emailRef = useRef(null)
    const passwordRef = useRef(null)
    const buttonRef = useRef(null)
    const policyRef = useRef(null)
    const [checkEmailActive, setCheckEmailActive] = useState(false)
    const [userName, setUserName] = useState('')

    function passwordHide() {
        if (passwordRef.current.type === 'password') {
            passwordRef.current.type = 'text'
        } else {
            passwordRef.current.type = 'password'
        }
    }

    function pressEnter(params) {
        if (params.keyCode === 13) {
            submit()
        }
    }
    function getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim()
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    function submit() {
        if (login) {
            numberRef.current.focus()
            if (number && number.length > 5) {
                emailRef.current.focus()
                setErrorTetx('')
                if (email) {
                    setErrorTetx('')
                    if (validateEmail(email)) {
                        setErrorTetx('')
                        passwordRef.current.focus()
                        if (password) {
                            setErrorTetx('')
                            if (password.length > 7) {
                                setErrorTetx('')
                                if (policyRef.current.checked) {
                                    buttonRef.current.disabled = true
                                    setErrorTetx('')
                                    buttonRef.current.focus()
                                    var csrftoken = getCookie('csrftoken')
                                    axios.post('/api/registration', {
                                        'name': login,
                                        'number': number,
                                        'email': email,
                                        'password': password,
                                        'X-CSRFToken': csrftoken,
                                    }).then(response => {
                                        buttonRef.current.disabled = false
                                        if (response.data === 'success') {
                                            setCheckEmailActive(true)
                                            setUserName(email)
                                            setLogin('')
                                            setEmail('')
                                            setPassword('')
                                            setErrorTetx('')
                                        }
                                        else if (response.data === 'this email address is already being used') {
                                            setErrorTetx('Этот E-mail адрес уже занят. Попробуйте другой.')
                                            emailRef.current.focus()
                                        }
                                        else if (response.data === 'username is already being used') {
                                            setErrorTetx('Этот логин уже занят. Попробуйте другой.')
                                            loginRef.current.focus()
                                        }
                                    })

                                } else {
                                    policyRef.current.focus()
                                    setErrorTetx('Вы не приняли политику конфиденциальности.')
                                }

                            } else {
                                passwordRef.current.focus()
                                setErrorTetx('Пароль не может содержать менее 8 символов.')
                            }
                        } else {
                            passwordRef.current.focus()
                            setErrorTetx('Заполните поле пароль.')
                        }

                    } else {
                        emailRef.current.focus()
                        setErrorTetx('Некорректное заполнение E-mail.')
                    }
                } else {
                    emailRef.current.focus()
                    setErrorTetx('Заполните поле E-mail.')
                }
            }
            else {
                numberRef.current.focus()
                setErrorTetx('Заполните поле Телефон.')
            }
        } else {
            loginRef.current.focus()
            setErrorTetx('Заполните поле Имя.')
        }
    }
    function validateEmail(email) {
        var chrbeforAt = email.substr(0, email.indexOf('@'));
        if (!(email.trim().length > 127)) {
            if (chrbeforAt.length >= 2) {
                var re = /^(([^<>()[\]{}'^?\\.,!|//#%*-+=&;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
                return re.test(email);
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    useEffect(() => {
        if (localStorage.getItem('userIsAuth') === 'true') {
            navigate('/cabinet')
        }
        if (searchParams.get("referal")) {
            var token = searchParams.get("referal")
            axios.get('/api/get-referral/' + token)
                .then(response => {
                    if (response.data) {
                        setReferalLink(response.data)
                    }
                })
        }
    }, [])
    useEffect(() => {
        if (number) {
            document.getElementById('number').style.paddingLeft = '50px'
        }
    }, [number])
    function numberFocus() {
        if (!number) {
            setNumber('+7')
        }
    }
    function numberBlur() {
        if (!number) {
            document.getElementById('number').style.paddingLeft = '5%'
        }
    }
    return (
        <>
            <MetaTags>
                <title>Регистрация Baksbo.ru</title>
            </MetaTags>
            <CheckEmail user={userName} active={checkEmailActive} />
            <div className="authorizationPage">
                <div className='authorizationForm'>
                    <h2>Регистрация</h2>
                    {referalLink ?
                        <p>
                            <b>{referalLink}</b> рекомендует вам сервис массовых рассылок сообщений baksbo.ru и делится СКИДКОЙ -5% на первый заказ.
                            <br />Заполните форму чтобы зарегистрироватся.
                        </p>
                        :
                        <p>Заполните форму чтобы зарегистрироватся.</p>
                    }
                    <div className='form'>
                        <input ref={loginRef} onKeyDown={pressEnter} value={login} onChange={(elem) => setLogin(elem.target.value.trim())} type="text" placeholder='Имя' className='nameInput' />
                        <section>
                            <PhoneInput
                                id='number'
                                ref={numberRef}
                                onKeyDown={pressEnter}
                                onFocus={numberFocus}
                                onBlur={numberBlur}
                                placeholder="Телефон"
                                value={number}
                                onChange={setNumber} />
                        </section>
                        <input ref={emailRef} onKeyDown={pressEnter} value={email} onChange={(elem) => setEmail(elem.target.value.trim())} type="email" placeholder='E-mail' className='nameInput' />
                        <div className='passwordHideDiv'>
                            <input ref={passwordRef} onKeyDown={pressEnter} value={password} onChange={(elem) => setPassword(elem.target.value.trim())} type="password" placeholder='Пароль' className='nameInput' />
                            <img onClick={passwordHide} src="/static/img/cabinet/eye-slash-solid.png" alt="" />
                        </div>
                        <div className="formdiv">
                            <input ref={policyRef} type="checkbox" name="polytic" id="polyticChekbox" />
                            <label htmlFor="polyticChekbox">Регистрируясь в сервисе BAKSBO.RU, вы подтверждаете свое согласие с <a target='_blank' href="/static/files/privacy_policy_bot.pdf"> пользовательским соглашением BAKSBO-BOT</a> и <a target='_blank' href="/static/files/cabinet_oferta.pdf"> условиями,
                             предоставляемыми сервисом</a> и подтверждаете, что ознакомлены и согласны с <a target='_blank' href="/static/files/privacy_policy.pdf"> политикой конфиденциальности</a> данного сервиса.</label>
                        </div>
                            

                        <button ref={buttonRef} onClick={submit}>Далее</button>
                        <span>Уже зарегистрированы? <Link to={'/login'}>Войти в систему.</Link> </span>
                    </div>
                    <p ref={error} className='errorText'>{errorTetx}</p>
                </div>
            </div>
        </>
    )
}

export default Registration

