import axios from 'axios'
import React, { useRef, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';

import './checkEmail.css'

const CheckEmail = (args) => {
  const navigate = useNavigate();
  const [code, setCode] = useState('')
  const [error, setError] = useState('')
  const [resendCodeMessage, setResendCodeMessage] = useState('Повторно отправить код.')
  const [searchParams] = useSearchParams()
  const checkEmailDiv = useRef(null)
  const div = useRef(null)
  const fon = useRef(null)

  useEffect(() => {
    if (args.active == true) {
      checkEmailDiv.current.style.display = 'flex'
      div.current.style.display = 'flex'
      fon.current.style.display = 'flex'
    } else {
      checkEmailDiv.current.style.display = 'none'
      div.current.style.display = 'none'
      fon.current.style.display = 'none'
    }
  }, [args.active])
  useEffect(() => {
    if (code.length === 4) {
      axios.get('/api/check-email/', {
        params: {
          code: code,
          email: args.user,
        },
      })
        .then(response => {
          setCode('')
          if (response.data === 'success') {
            setError('')
            if (searchParams.get("referal")) {
              navigate('/cabinet/referral/?referal=' + searchParams.get("referal"))
            } else {
              navigate('/cabinet')
            }
            window.location.reload()

          }
          else if (response.data === 'wrong code') {
            setError('Не правильный код!')
          }
        })

    }
  }, [code])
  // it's really easy, function for resend code in email
  function resendCode() {
    if (resendCodeMessage === 'Повторно отправить код.') {
      setResendCodeMessage('Код повтрорно отправлен.')
      axios.get('/api/resend-code/', {
        params: {
          email: args.user,
        }
      }).then(response => {
        setCode('')
        if (response.data === 'success') {
          setError('')
        } else {
          setTimeout(function () {
            setResendCodeMessage('Повторно отправить код.')
          }, 5000);
        }
      })
    }
  }
  return (
    <>
      <div ref={div} className='formResponceDiv formResponceDivActive'>
        <div ref={fon} className='formResponceFon '></div>

        <div ref={checkEmailDiv} className='checkEmailDiv'>
          <h2>Подтвердите E-mail</h2>
          <p>На ваш E-mail ({args.user}) придет код подверждения. <br /> <i>Проверьте папку «СПАМ»</i></p>
          <span onClick={resendCode}>{resendCodeMessage}</span>
          <input maxLength={5} value={code} onChange={(elem) => setCode(elem.target.value.slice(0, 4))} placeholder='Вводите код' type="number" />
          <p className='errorText'>{error}</p>
        </div>
      </div>
    </>
  )
}

export default CheckEmail