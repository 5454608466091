import axios from 'axios'
import React, { useEffect, useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { UserContext } from '../../../App'
import './createTelegramTask.css'


const CreateTelegramTask = (args) => {

    const user = useContext(UserContext)
    const [taskName, setTaskName] = useState('')
    const [tasks, setTasks] = useState([])
    const [type, setType] = useState('')
    const navigate = useNavigate()


    function createTask() {
        if (args.type === 'mailing') {
            axios.post('/api/telegram_mailing/create', {
                'name': taskName,
            }).then(response => {
                if (response.status == 200) {
                    navigate('/cabinet/telegram_mailing?id=' + response.data)
                }
            })
        }
        else if (args.type === 'inviting') {
            axios.post('/api/telegram_invite/create', {
                'name': taskName,
            }).then(response => {
                if (response.status == 200) {
                    navigate('/cabinet/telegram_inviting?id=' + response.data)
                }
            })
        }
        else if (args.type === 'parser') {
            axios.post('/api/telegram_parser/create', {
                'name': taskName,
            }).then(response => {
                if (response.status == 200) {
                    navigate('/cabinet/telegram_parser?id=' + response.data)
                }
            })
        }
    }
    function deleteTask(id) {
        if (args.type === 'mailing') {
            axios.get('/api/telegram_mailing/' + id + '/delete',).then(response => {
                if (response.status == 200) {
                    setTasks(response.data)
                    setType('telegram_mailing')
                }
            })
        }
        else if (args.type === 'inviting') {
            axios.get('/api/telegram_invite/' + id + '/delete',).then(response => {
                if (response.status == 200) {
                    setTasks(response.data)
                    setType('telegram_inviting')
                }
            })
        }
        else if (args.type === 'parser') {
            axios.get('/api/telegram_parser/' + id + '/delete',).then(response => {
                if (response.status == 200) {
                    setTasks(response.data)
                    setType('telegram_parser')
                }
            })
        }
    }
    useEffect(() => {
        if (args.type === 'mailing') {

            axios.get('/api/telegram_mailing/get')
                .then(response => {
                    if (response.status == 200) {
                        setTasks(response.data)
                        setType('telegram_mailing')
                    }
                })
        }
        else if (args.type === 'inviting') {
            axios.get('/api/telegram_invite/get')
                .then(response => {
                    if (response.status == 200) {
                        setTasks(response.data)
                        setType('telegram_inviting')
                    }
                })
        }
        else if (args.type === 'parser') {
            axios.get('/api/telegram_parser/get')
                .then(response => {
                    if (response.status == 200) {
                        setTasks(response.data)
                        setType('telegram_parser')
                    }
                })
        }

    }, [args.type])
   

    return (
        <>
            {args.active ?
                <>
                    <div onClick={() => args.setMailingCreate(false)} className='formResponceFon formResponceFonActive'></div>
                    {args.type ?
                        <div className='createTelegramTaskDiv'>
                            {user['tarif'] ?
                                <p>Мои задачи</p> :
                                <p>Мои задачи (демо)</p>
                            }
                            <div className='tgMilingTasks'>
                                {tasks.length !== 0 ?
                                    tasks.map(i => {
                                        return (
                                            <div className='task'>
                                                <Link to={'/cabinet/' + type + '?id=' + i.id}>
                                                    <h3><span>Название: </span> {i.name}</h3>
                                                    {i.status === 'setting' ?
                                                        <p className='start'>Настройка</p>
                                                        :
                                                        i.status === 'start' ?
                                                            <p className='setting'>Запуск</p>
                                                            :
                                                            <p className='finish'>Завершена</p>
                                                    }
                                                    <span>{i.createDate}</span>
                                                </Link>
                                                <img onClick={() => deleteTask(i.id)} src="/static/img/cabinet/trash-solid.png" alt="" />
                                            </div>
                                        )
                                    })
                                    :
                                    <h4>Нечего не найдено</h4>
                                }
                            </div>
                            <p>Создать задачу</p>
                            {user['tarif'] ?
                                <div className='tgMilingTasksInput'>
                                    <input onChange={event => setTaskName(event.target.value)} type="text" placeholder='Название задачи' maxLength={20} value={taskName} />
                                    <button onClick={createTask}>Создать</button>
                                </div>
                                :
                                <div className='tgMilingTasksInput tgMilingTasksInputDisabled'>
                                    <input type="text" placeholder='Название задачи' maxLength={20} value={taskName} />
                                    <button >Создать</button>
                                </div>
                            }
                        </div>
                        :
                        <></>
                    }
                </>
                :
                <>

                </>}
        </>
    )
}

export default CreateTelegramTask